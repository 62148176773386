import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModuleType } from '../../../../../types-legacy/lib/types';

@Component({
  selector: 'roma-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss'],
})
export class PlanSelectionComponent {
  @Input() moduleName: string;
  @Input() moduleIcon: string;
  @Input() moduleType: ModuleType;
  @Input() description: string;
  @Input() startingPrice: string;
  @Input() isFullPlan: boolean;
  @Input() currency: string;
  @Input() isPeriodYearly: boolean;
  @Input() plans: Array<{
    id: string;
    name: string;
    price: string;
    pricePerYear: string;
  }>;
  @Input() features: Array<{
    name: string;
    pro?: boolean | string;
    basic?: boolean | string;
  }>;
  @Output() selectPlan = new EventEmitter();

  isCollapsed = window.innerWidth < 768;
  moduleStyles = {
    [ModuleType.CRM]: {
      background: 'rgba(248, 235, 251, 0.60)',
      color: '#9F2BB6',
      priceBackground: '#F8EBFB',
    },
    [ModuleType.SALES]: {
      background: 'rgba(225, 247, 235, 0.60)',
      color: '#066D38',
      priceBackground: '#E1F7EB',
    },
    [ModuleType.FULL]: {
      background: 'rgba(233, 234, 253, 0.60)',
      color: '#2B33A8',
      priceBackground: '#E9EAFD',
    },
    [ModuleType.TIME_TRACKING]: {
      background: 'rgba(255, 241, 217, 0.60)',
      color: '#905E09',
      priceBackground: '#FFF1D9',
    },
  };

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onSelectPlan(plan: any): void {
    this.selectPlan.emit(plan);
  }

  getStyles(): { background: string; color: string } {
    return (
      this.moduleStyles[this.moduleType] || {
        background: 'transparent',
        color: '#000000',
      }
    );
  }

  isBoolean(value: string | boolean): boolean {
    return typeof value === 'boolean';
  }

  getPlanTranslationKey(name: string): string {
    return `pro.planSelection.${name}`;
  }
}
