import { ClockAbsence, ClockHour } from '../../../../types-legacy';
import { CalendarResponse } from '../../../shared/services';

export function formatToCalendar(
  data: Record<string, CalendarResponse[]>
): ClockHour.Calendar[] {
  return Object.entries(data).map(([dateStr, entries]) => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const dateObj = { year, month: month - 1, day };

    const dateDetails: ClockHour.Calendar = {
      date: dateObj,
      ...(entries.find(
        (entry) => 'histories' in entry
      ) as Partial<ClockHour.Calendar>),
      absence: entries.find(
        (entry) => 'dateDetail' in entry
      ) as ClockAbsence.Output,
    };

    return dateDetails;
  });
}
