<div class="full-height">
  <div class="login-form">
    <img class="logo" src="/assets/img/logo-taclia.svg" alt="taclia" />
    <h2>{{ 'login.welcome' | translate }}</h2>
    <p class="sub-title">{{ 'login.registration' | translate }}</p>
    <a href="https://app.taclia.com/">
      <button
        class="btn rounded-btn btn-primary w-100"
        style="width: 120px !important"
      >
        {{ 'login.start' | translate }}
      </button>
    </a>
  </div>
  <div class="login-col text-center d-none d-lg-block">
    <h3 class="text-white">{{ 'login.already' | translate }}</h3>
    <p class="logos-row">
      <img src="/assets/img/signup/logos/abria.svg" />
      <img src="/assets/img/signup/logos/acm.svg" />
      <img src="/assets/img/signup/logos/araya.svg" />
      <img src="/assets/img/signup/logos/comont.svg" />
    </p>
    <p class="logos-row">
      <img src="/assets/img/signup/logos/desatascos-torregrosa.svg" />
      <img src="/assets/img/signup/logos/brillosa.svg" />
      <img src="/assets/img/signup/logos/iberica.svg" />
      <img src="/assets/img/signup/logos/tgb.svg" />
    </p>
    <img
      class="proveedores-img"
      width="62%"
      src="/assets/img/signup/banner-signup.png"
      alt="taclia Proveedores"
    />
  </div>
</div>
<!-- <div class="row">
    <div class="col-12">

    </div>
    <div class="col-12" style="text-align: center">
      <div class="d-flex pb-4" style="justify-content: center">
        <img
          src="/assets/images/logotaclia_2.png"
          alt="logo-taclia"
          style="width: 20%"
        />
      </div>
    </div>

    <div class="col-12" style="justify-content: center;">
        <div class="d-flex pb-4" style="padding: 0px 7px 0px 0px;
        justify-content: center; justify-content: center">

        <h1 class="display-1 pages-title" style="font-weight: 500 ;font-size: 2rem;
            line-height: 3.5rem;" >
            Bienvenido
        </h1>

    </div>

    <div class="d-flex pb-4" style="justify-content: center">


        <p style="font-size: 1.25rem;
        line-height: 1.75rem;">
             Gracias por su registro
        </p>

    </div>



    <div class="col-12" style="text-align: center">
      <a  mat-raised-button color="primary"  href="https://data.taclia.com/">Ir a inicio</a>
    </div>
  </div> -->
