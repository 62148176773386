<div class="row">
  <div class="col-12" style="text-align: center">
    <div class="d-flex pb-4" style="padding: 50px; justify-content: center">
      <img
        src="/assets/images/logotaclia_2.png"
        alt="logo-taclia"
        style="width: 50%"
      />
    </div>

    <h4>{{'login.maintenance' | translate}}</h4>
  </div>
  <div class="col-12" style="text-align: center">
    <button class="reintentar" (click)="retry()">{{'login.retry' | translate}}</button>
  </div>
</div>
