import { environment } from '@web-frontend/environments';

export function googleTagManager(): void {
  const w = window as any;
  const d = document;
  const l = 'dataLayer';
  const i = 'GTM-KB6PVRD';
  const s = 'script';
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src =
    'https://www.googletagmanager.com/gtm.js?id=' +
    i +
    dl +
    `&${environment.gtm.auth}`;
  f.parentNode.insertBefore(j, f);
}
