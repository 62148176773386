import { ActivatedRoute } from '@angular/router';
import {
  CampaignInterface,
  Utm,
} from '../../../../types-legacy/lib/interfaces/campaign.interface';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments';

@Injectable({
  providedIn: 'root',
})
export default class CampaignManager {
  private utm: Utm = null;
  private campaign: CampaignInterface | null = null;

  constructor(private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.subscribe((params) => {
      this.utm = Object.assign({}, params, {
        utm_source: params['utm_source'],
        utm_medium: params['utm_medium'],
        utm_campaign: params['utm_campaign'],
        utm_gclid: params['utm_gclid'],
        url_initial: params['url_initial'],
        url_final: params['url_final'],
        url_external: params['url_external'],
      });
    });
  }

  public async fetchCampaign(): Promise<CampaignInterface | null> {
    const response = await fetch(`${environment.cdn}/json/campaignData.json`);
    const campaignData = await response.json();

    const dataObject: CampaignInterface = await campaignData[
      this.utm.utm_campaign
    ];
    if (dataObject) {
      this.campaign = dataObject;
      return {
        ...dataObject,
        utm_campaign: this.utm.utm_campaign,
      };
    }
    return null;
  }

  public getUtm(): Utm {
    return this.utm;
  }

  public getCampaign(): CampaignInterface | null {
    return this.campaign;
  }
}
