<div class="page-options row filter-container">
  <roma-week-month-switch
    *ngIf="showPeriodSwitch"
    [viewPeriod]="viewPeriod"
    (viewPeriodChange)="changeClockHourView($event)"
  ></roma-week-month-switch>
  <roma-date-paginator
    [viewPeriod]="viewPeriod"
    [defaultDate]="rangeSelected"
    (changeDateRange)="periodChange($event)"
    [dateFormat]="dateFormat"
    [clickOnCurrentPeriod]="clickOnCurrentPeriod"
  ></roma-date-paginator>
</div>
