import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import {
  BudgetCommonData,
  Feature,
  IBudgetsSetting,
  TypeBudget,
} from '@tacliatech/types';

import { AlertService } from '@web-frontend/shared/helpers/alert';
import { BudgetService } from '@web-frontend/shared/services/budgets';

import { Observable, Subscription } from 'rxjs';
import localeEl from '@angular/common/locales/el';
import localePy from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeTa from '@angular/common/locales/ta';
import localeHi from '@angular/common/locales/hi';

import { formatNumber, registerLocaleData } from '@angular/common';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MatMenuTrigger } from '@angular/material/menu';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { SandboxService, StorageService } from '@web-frontend/shared/services';
import { BudgetUtilisService } from '../create-budget/budget-util.service';
import { FormGroup } from '@angular/forms';
import { BudgetCalculationsService } from '../create-budget/budget-calculations.service';
import { isNumber } from 'lodash';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { DomSanitizer } from '@angular/platform-browser';
import { InvoiceService } from '@web-frontend/invoice/invoice.service';

registerLocaleData(localeEl, 'el');
registerLocaleData(localePy, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeTa, 'ta');
registerLocaleData(localeHi, 'hi');

@Component({
  selector: 'roma-budget-previewer',
  templateUrl: './budget-previewer.component.html',
  styleUrls: ['./budget-previewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetPreviewerComponent implements OnInit {
  @Input()
  title = 'budgets.title.preview';
  // falta tipado budgetCommonData
  budgetCommonData: any;

  pdfSrc: string;

  totals: {
    rEt: number;
    rEq: number;
    ivas: number;
    subTotal: number;
    total: number;
    discount: number;
  };

  totalTaxes$: Observable<{ Arec: any[]; Aret: any[]; Aiva: any[] }>;

  loading = true;

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  @ViewChild('previewDoc')
  previewEl: ElementRef<HTMLIFrameElement>;

  isDownloading = false;
  haveUnit: boolean;

  rEq: any[] = [];
  rEt: any[] = [];
  iVa: any[] = [];
  cols = 7;

  public createPDF(): void {
    const DATA = document.getElementById('htmlData');

    this.isDownloading = true;
    this.draw();

    // @ts-ignore
    html2canvas(DATA)
      .then((canvas) => {
        const fileWidth = 208;
        const fileHeight = (canvas.height * fileWidth) / canvas.width;

        const FILEURI = canvas.toDataURL('image/png');
        const PDF = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

        PDF.save('budget.pdf');
      })
      .catch((err) => {})
      .finally(() => {
        this.isDownloading = false;
        this.draw();
      });
  }

  /*   INPUTS
 ..............................................*/

  @Input()
  form: FormGroup;

  @Input()
  budget: BudgetCommonData = new BudgetCommonData();

  @Input()
  settings?: IBudgetsSetting;

  @Input() type?: string;

  @Input() mode?: string;

  @Input() showComponent?: boolean;

  /*   PROPERTIES
 ..............................................*/
  hasFeatureSeeLogo = false;

  private sub$ = new Subscription();
  canEditBasic = true;
  isLoading = true;
  langs = this.budgetService.findAllLangs();

  get lang() {
    const lang = this.langs.find(
      (format) => format.id === this.data.settings.language
    );
    return lang;
  }

  featureRef = Feature;

  showDisclaimer = true;
  resize$ = this.sandBoxService.screenBusChannel$;

  previewDocument = undefined;
  defaultPrefix = '__00__';

  constructor(
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private alertService: AlertService,
    private i18n: TranslateService,
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<BudgetPreviewerComponent>,
    private sandBoxService: SandboxService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private budgetUtilisService: BudgetUtilisService,
    private budgetCalculationsService: BudgetCalculationsService,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    private invoiceService: InvoiceService
  ) {}

  ngOnInit(): void {
    this.resize$.subscribe((res) => console.log(res));
    this.watchPermissions(Feature.Sale.SeeBudgetLogo);
    if (this.mode !== 'EDIT') {
      this.settings = this.data?.settings;
      this.type = this.data?.type;
      this.mode = this.data?.mode;
      this.form = this.data?.form;
      if (this.data?.form) {
        this.budget.header.contact_id = this.data?.form?.value?.budgetHeader?.contact;
        this.budget.header.prefix = this.data?.form?.value?.budgetHeader?.prefix;
        if (this.budget.header.prefix === this.defaultPrefix) {
          this.budget.header.prefix = '0';
        }
        this.budget.header.sequence_id = this.data?.form?.value?.budgetHeader?.sequence_id;
        this.budget.header.numberDoc = this.data?.form?.value?.budgetHeader?.numberDoc;
        this.budget.items = this.data?.form?.value?.budgetCategories;
        const { date, dueDate } = this.budgetUtilisService.formatDates(
          this.data?.form?.controls['budgetHeader'] as FormGroup
        );
        this.budget.header.showPrices = this.data?.form?.value?.budgetFooter?.showPrices;
        this.budget.header.showSignatures = this.data?.form?.value?.budgetFooter?.showSignatures;
        this.budget.header.date = date;
        this.budget.header.dueDate = dueDate;
        this.budget.messages.line1 = this.data?.form?.value?.budgetFooter?.messages;
        this.totalTaxes$ = this.budgetCalculationsService.calculateRetentions(
          this.budget.items
        );
        this.budget.files = this.data.files;
        this.budget.signClient = this.data.signClient;
        this.budget.signCompany = this.data.signCompany;
      } else {
        this.budget = this.data.budget;
      }
    }
    this.getBudgetCommonData();
    this.budget?.items && this.calculateSubtotal();
    this.itemHaveUnit();
    this.draw();
  }

  private watchPermissions(feature: Feature.Purchasable) {
    this.permissionService.permissions$.subscribe((res) => {
      if (res.includes(feature)) {
        this.hasFeatureSeeLogo = true;
      }
    });
  }

  getLang() {
    return this.i18n.currentLang;
  }

  itemHaveUnit() {
    this.haveUnit = this.budget?.items?.some((item) => item.unit !== '');
  }

  calculateSubtotal() {
    this.totals = this.budgetCalculationsService.calculateTotalsRealTime(
      this.budget?.items,
      this.settings
    );

    this.budget.iva_total = this.totals.ivas;
    this.budget.subtotal = this.totals.subTotal;
    this.budget.total = this.totals.total;
    this.budget.discount = this.totals.discount;
  }

  getBudgetCommonData() {
    this.budgetService
      .findBudgetAdd(this.budget?.header?.contact_id, this.type)
      .subscribe(
        (resp) => {
          this.budgetCommonData = resp;
          this.showDocument();

          if (this.budget?.proFeatures?.hasDiscount) {
            this.calculateSubtotal();
          }
          this.loading = false;
          this.totalTaxes$ = this.budgetCalculationsService.calculateRetentions(
            this.budget?.items
          );
          this.draw();
        },
        (err) => {
          this.loading = false;
          this.draw();
        },
        () => {
          this.loading = false;
        }
      );
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  close() {
    this.dialogRef.close();
  }

  translate(param: string) {
    // @ts-ignore
    this.i18n.use(this.lang.simbol);
    const translated = this.i18n.instant(param);
    return this.i18n.instant(param);
  }

  showDocument() {
    this.isLoading = true;
    this.draw();

    const api: any = {
      document: this.budget?.header?.numberDoc,
      type: this.type,
      id: this.budget?._id,
      lang: this.i18n.currentLang,
      fileFormat: 'pdf',
      user: {
        _id: StorageService.UserId,
        company: {
          _id: StorageService.CompanyId,
          name: this.settings?.data_account?.company_name || '',
        },
      },
      ...(!!this.budget?.personalization?.distribution && {
        distribution: this.budget?.personalization?.distribution,
      }),
      ...(!!this.budget?.personalization?.color && {
        color: this.budget?.personalization?.color,
      }),
    };

    if (!api.id) {
      api.budget = this.budget;
      api.budget.typePayments = this.form?.value?.budgetFooter?.payment_method;
      api.dataSend = this.resolveDataSend();
    }

    this.invoiceService
      .findPdf(api)
      .then((res) => {
        const url = URL.createObjectURL(res.body || res);
        this.pdfSrc = url;
      })
      .catch(() => {
        this.toastService.show({
          text: this.i18n.instant('budget.downloadError'),
          type: 'error',
          msDuration: 4000,
        });
      })
      .finally(() => {
        this.isLoading = false;
        this.draw();
      });
  }

  resolveDataSend() {
    const phonesArr = this.parsePhones(this.budgetCommonData?.phonesArr);

    const phones =
      this.budgetCommonData?.phones?.length > 0
        ? this.budgetCommonData?.phones
        : phonesArr;
    const emails = this.budgetCommonData?.email
      ? [this.budgetCommonData?.email]
      : this.budgetCommonData?.emailArr;

    const dataSend = {
      type: this.type,
      logo: this.settings.logo,
      company_name: this.settings.data_account?.company_name,
      fiscalName: this.settings.data_account?.fiscalName,
      tradename: this.settings.data_account?.tradename,
      prefix: this.budget.header?.prefix,
      phone: this.settings.data_account?.phone,
      address: this.settings.billing_addres?.address,
      email: this.settings.data_account?.email,
      final_name: this.budgetCommonData?.name,
      final_lastName: this.budgetCommonData?.lastName,
      final_nif: this.budgetCommonData?.nif,
      final_email: emails,
      final_fiscalName: this.budgetCommonData?.fiscalName,
      final_address: this.budgetCommonData?.address,
      final_address_additional: this.budgetCommonData?.address_additional,
      phones: phones,
      numberDoc: this.budget.header?.numberDoc,
      date: this.budget.header?.date,
      dueDate: this.budget.header?.dueDate,
      items: this.budget?.items,
      subtotal: this.budget?.subtotal,
      iva_total: this.budget?.iva_total,
      total: this.budget?.total,
      showPrices:
        this.type !== TypeBudget.WAL ? true : this.budget.header?.showPrices,
      showSignatures:
        this.type !== TypeBudget.WAL
          ? false
          : this.budget.header?.showSignatures,
      ivap: this.iVa,
      retp: this.rEt,
      reqp: this.rEq,
      line1: this.budget?.messages?.line1,
      textShow: undefined,
      discount: this.budget?.discount,
      hasDiscount: this.budget?.proFeatures?.hasDiscount,
      signClient: this.budget?.signClient,
      signCompany: this.budget?.signCompany,
      fieldsToDisplay: this.data?.fieldsToDisplay,
      files: this.budget?.files,
    };
    return dataSend;
  }

  parsePhones(
    phone: {
      pre: string;
      number: string;
    }[]
  ): string[] {
    if (phone?.length === 0) {
      return [];
    }
    return phone?.map((phone, idx, row) => {
      let p = `${phone.pre} ${phone.number}`;
      if (idx + 1 !== row.length) {
        p += ',';
      }
      return p;
    });
  }
}
