import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import {
  BudgetCommonData,
  Feature,
  FeatureUser,
  HeaderBudget,
  IBudgetCommonData,
  IBudgetsSetting,
  ISequence,
  MessageBudget,
  SequenceTypes,
  STATUS_BILL_PENDING,
  TypeAmplitudeBudget,
  TypeBudget,
} from '@tacliatech/types';
import { DEFAULT_STATUS_BY_BUDGET_TYPE } from '@web-frontend/shared/utils';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CreateBudgetService } from '@web-frontend/shared/components/create-budget/create-budget.service';
import { SelectSequenceComponent } from '@web-frontend/shared/components/select-sequence/select-sequence.component';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { StorageService } from '@web-frontend/shared/services';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { SequenceService } from '@web-frontend/shared/services/sequence';

import { Observable, Observer, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Directive({
  selector: '[romaClickSequence]',
})
export class ClickSequenceDirective implements OnInit {
  @Input()
  type!: TypeBudget;

  @Input()
  typeToConvert!: TypeBudget;

  @Input()
  idDeal!: string;

  @Input()
  idCustomer!: string;

  @Input()
  idBudgetToConvert!: string;

  @Input()
  featureUser: Feature.Purchasable | FeatureUser.Purchasable =
    Feature.SystemPermission.DefaultAllow;
  budgetToConvert!: BudgetCommonData;

  @Input()
  budgetTypeAmplitude: TypeAmplitudeBudget;

  messageDefault: MessageBudget = new MessageBudget();

  @Output() loadingEvent = new EventEmitter<boolean>();

  @Output() changeBudget = new EventEmitter<boolean>();

  @Input() increment = true;

  private sub$ = new Subscription();

  constructor(
    private router: Router,
    private amplitudeService: AmplitudeService,
    private sequenceService: SequenceService,
    private budgetService: BudgetService,
    private dialog: MatDialog,

    private brazeService: BrazeService,
    private permissionService: PermissionService,
    private createBudgetService: CreateBudgetService,
    private analyticsService: AnalyticsService
  ) {
    this.getBudgetsSettings();
  }

  ngOnInit(): void {}

  getBudgetsSettings() {
    this.budgetService
      .findSettingsByCompany(StorageService.CompanyId)
      .subscribe((setts: IBudgetsSetting) => {
        // Set default Message for budgets
        this.messageDefault.line1 = setts.budgetPreferences?.message;
      });
  }

  @HostListener('click', ['$event'])
  click(evt: MouseEvent) {
    if (evt && this.permissionService.permissions.includes(this.featureUser)) {
      this.sendAmplitudeEvent();
      this.prepareSequences();
    }
  }

  private prepareSequences() {
    this.loadingEvent.emit(true);
    this.sub$.add(
      this.sequenceService
        .getAllSequences({
          type: this.getSequenceType(),
        })
        .pipe(finalize(() => {}))
        .subscribe((res) => {
          const COUNT_OF_SEQUENCES = res.results.length;

          if (COUNT_OF_SEQUENCES === 0) {
            this.navigateToBudgetsComponent();
          } else if (COUNT_OF_SEQUENCES === 1 || this.typeToConvert) {
            // @ts-ignore
            this.handleOneSequence(res[0]?._id);
          } else if (COUNT_OF_SEQUENCES > 1) {
            this.handleMoreOneSequences(res.results);
          }
        })
    );
  }

  handleMoreOneSequences(sequences: ISequence[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '420px';
    dialogConfig.height = '300px';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      sequences,
    };

    const dialogRef = this.dialog.open(SelectSequenceComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((sequenceId: string) => {
      if (sequenceId) {
        this.handleOneSequence(sequenceId);
      } else {
        this.loadingEvent.emit(false);
      }
    });
  }

  private handleOneSequence(id: string): void {
    if (this.increment) {
      this.sub$.add(
        this.sequenceService.getSequence({ _id: id }).subscribe((res) => {
          let budget = this.createDefaultParametersoOfBudget();

          // set secuence responde in headres
          budget = this.setHeaderPrefixNumberDoc(budget, res);
          if (this.idBudgetToConvert) {
            // Get Budget,set secuence and redirect to new BILL/PROFORM created
            // @ts-ignore
            this.convertBudgetToBillOrProform(budget);
          } else {
            //create budget/bill/proform and redirect
            this.createBudgetAndRedirect(budget);
          }
        })
      );
    } else {
      let budget = this.createDefaultParametersoOfBudget();

      budget = this.setHeaderWithoutIncrement(budget, id);

      if (this.idBudgetToConvert) {
        // Get Budget,set secuence and redirect to new BILL/PROFORM created
        // @ts-ignore
        this.convertBudgetToBillOrProform(budget);
      } else {
        //create budget/bill/proform and redirect
        this.createBudgetAndRedirect(budget);
      }
    }
  }

  /**
   * The function `findTypeService` returns an observable based on the type of budget provided.
   * @param {TypeBudget} type - The `type` parameter is of type `TypeBudget`, which is an enum that
   * represents the type of budget.
   * @returns The function `findTypeService` returns an `Observable<IBudgetCommonData>`.
   */
  findTypeService(type: TypeBudget): Observable<IBudgetCommonData> {
    const finderService = {
      [TypeBudget.PROFORM]: this.budgetService.findOneProform(
        this.idBudgetToConvert
      ),
      [TypeBudget.BUDGET]: this.budgetService.findOneBudget(
        this.idBudgetToConvert
      ),
      default: this.budgetService.findOneBudget(this.idBudgetToConvert),
    };

    const observable$: Observable<IBudgetCommonData> = finderService[type];

    return observable$;
  }

  /**
   * This function converts a budget by copying data from an existing budget and creating a new budget
   * with updated information.
   * @param {IBudgetCommonData} budget - IBudgetCommonData - an interface representing common data for a
   * budget
   */
  convertBudgetToBillOrProform(budget: IBudgetCommonData) {
    // find budget to Convert
    let event = `${this.budgetTypeAmplitude}_view_convert`;

    switch (this.typeToConvert) {
      case TypeBudget.BILL:
        event += 'ToBill';
        break;
      case TypeBudget.PROFORM:
        event += 'ToProforma';
        break;
      default:
        break;
    }

    this.amplitudeService.sendEvent({ event });

    this.findTypeService(this.type).subscribe((data: IBudgetCommonData) => {
      //Fill Object and copy his properties for new Object
      this.budgetToConvert = data;
      const prefix = budget.header?.prefix;
      const numberDoc = budget.header?.numberDoc;
      const sequence_id = budget.header?.sequence_id;
      const budgetConverted = this.copyBudgetToConvert(
        this.budgetToConvert,
        // @ts-ignore
        prefix,
        numberDoc,
        sequence_id
      );
      this.createBudgetConvertedAndRedirect(budgetConverted);
    });
  }

  /**
   * This function creates a copy of a budget object, updates its status and header properties, and
   * returns the updated copy.
   * @param {BudgetCommonData} budget - The original budget object that needs to be copied and
   * @param {string} prefix - A string that represents the prefix to be added to the budget header's
   * numberDoc.
   * @param {string} numberDoc - A string representing the document number to be assigned to the new
   * budget copy.
   * @returns a copy of the `budgetToConvert` object with some modifications. The `status` property is
   * set to `STATUS_BUDGET_PENDING.id`, and the `numberDoc` and `prefix` properties in the `header`
   * object are updated with the values passed as arguments to the function. The original `_id`
   * property is deleted from the copy. The modified copy is then
   */
  copyBudgetToConvert(
    budget: BudgetCommonData,
    prefix: string,
    numberDoc: string,
    sequence_id: string
  ): BudgetCommonData {
    //create copy from original budget
    const budgetCopy = Object.assign({}, this.budgetToConvert);
    delete budgetCopy['_id'];

    // new state PENDING to new BILL/PROFORM
    budgetCopy.status = DEFAULT_STATUS_BY_BUDGET_TYPE[this.typeToConvert].id;
    budgetCopy.header.numberDoc = numberDoc;
    budgetCopy.header.prefix = prefix;
    budgetCopy.header.sequence_id = sequence_id;

    // If not exists create empty objects messages and addicionalInfo
    if (!budgetCopy?.messages) budgetCopy.messages = {};
    if (!budgetCopy?.addicionalInfo) budgetCopy.addicionalInfo = {};

    // update budgetCopy with headers numberDoc and prefix from secuence
    budget = budgetCopy;

    return budget;
  }

  /**
   * This function sets the header prefix and number document of a budget object, and returns the updated
   * budget object.
   * @param {BudgetCommonData} budget - The `budget` parameter is an object of type `BudgetCommonData`
   * which contains common data for a budget, such as company information, header information, and line
   * items.
   * @param {ISequence} secuence - `secuence` is an object of type `ISequence`, which likely contains
   * information about a sequence or numbering system being used for documents in the budget. The
   * `actualValue` property of this object is used to set the `numberDoc` property of the `budget.header`
   * object, and
   * @returns {BudgetCommonData} The function `setHeaderPrefixNumberDoc` is returning a `BudgetCommonData` object.
   */
  setHeaderPrefixNumberDoc(
    budget: BudgetCommonData,
    secuence: ISequence
  ): BudgetCommonData {
    budget.company = StorageService.CompanyId;
    budget.header.numberDoc = secuence.currentNumber?.toString();
    budget.header.prefix = secuence.prefix;
    budget.header.sequence_id = secuence.id;

    if (this.idDeal) budget.header.idDeal = this.idDeal;

    return budget;
  }

  setHeaderWithoutIncrement(
    budget: BudgetCommonData,
    secuenceId: string
  ): BudgetCommonData {
    budget.company = StorageService.CompanyId;
    budget.header.prefix = secuenceId;
    budget.header.sequence_id = secuenceId;

    if (this.idDeal) budget.header.idDeal = this.idDeal;

    return budget;
  }

  /**
   * The function returns the sequence type based on the budget type.
   * @returns a value of type `SequenceTypes`. The value returned is determined by the value of
   * `this.type`. If `this.type` is equal to `TypeBudget.BILL`, the function returns
   * `SequenceTypes.BILL`. If `this.type` is equal to `TypeBudget.BUDGET`, the function returns
   * `SequenceTypes.BUDGET`. If `this.type` is equal
   */
  private getSequenceType(): SequenceTypes {
    let sequenceType!: SequenceTypes;

    if (this.typeToConvert) {
      if (this.typeToConvert === TypeBudget.BILL) {
        sequenceType = SequenceTypes.BILL;
      }

      if (this.typeToConvert === TypeBudget.PROFORM) {
        sequenceType = SequenceTypes.PROFORM;
      }
    } else {
      if (this.type === TypeBudget.BILL) {
        sequenceType = SequenceTypes.BILL;
      }
      if (this.type === TypeBudget.BUDGET) {
        sequenceType = SequenceTypes.BUDGET;
      }
      if (this.type === TypeBudget.PROFORM) {
        sequenceType = SequenceTypes.PROFORM;
      }
    }

    return sequenceType;
  }

  /*
     // this case is just por creation budget
    if (!this.typeToConvert) {
      if (this.type === TypeBudget.BUDGET) {
        sequenceType = SequenceTypes.BUDGET;
      }
    }

  console.log('this.typeToConvert', this.typeToConvert);

    // those cases aply for creation Bill/Proform and convertion from budget to Bill/Proform
    if (
      this.typeToConvert ? this.typeToConvert : this.type === TypeBudget.BILL
    ) {
      sequenceType = SequenceTypes.BILL;
    }

    if (
      this.typeToConvert ? this.typeToConvert : this.type === TypeBudget.PROFORM
    ) {
      sequenceType = SequenceTypes.PROFORM;
    }   */

  createDefaultParametersoOfBudget(header?: HeaderBudget): BudgetCommonData {
    const budget = new BudgetCommonData();
    budget.company = StorageService.CompanyId;
    if (header) budget.header = header;
    budget.messages = this.messageDefault;
    return budget;
  }

  /**
   * The function navigates to the budgets component
   * by creating a new budget and redirecting to it.
   */
  private navigateToBudgetsComponent() {
    this.findMaxBudgetHeader(this.idDeal).subscribe((header: HeaderBudget) => {
      const budget = this.createDefaultParametersoOfBudget(header);

      if (this.idBudgetToConvert) {
        // Get Budget,set secuence and redirect to new BILL/PROFORM created
        // @ts-ignore
        this.convertBudgetToBillOrProform(budget);
      } else {
        //create budget/bill/proform and redirect
        this.createBudgetAndRedirect(budget);
      }
    });
  }

  /**
   * This function returns an observable that finds the maximum budget header for a given deal ID.
   * @param {string} idDeal - The id of a deal, which is used to set the idDeal property of the
   * HeaderBudget object.
   * @returns An Observable of type HeaderBudget is being returned.
   */
  findMaxBudgetHeader(idDeal: string): Observable<HeaderBudget> {
    return new Observable((observer: Observer<HeaderBudget>) => {
      const budgetHeader: HeaderBudget = new HeaderBudget();
      const type = this.typeToConvert ? this.typeToConvert : this.type;

      this.budgetService.findMax(type).subscribe((data) => {
        budgetHeader.prefix = '0';

        if (idDeal) budgetHeader.idDeal = this.idDeal;

        if (data?.maxNumber == null) budgetHeader.numberDoc = '0';
        else budgetHeader.numberDoc = data.maxNumber?.toString();

        observer.next(budgetHeader);
        observer.complete();
      });
    });
  }

  private createBudgetAndRedirect(budget: BudgetCommonData) {
    this.loadingEvent.emit(true);
    if (this.idCustomer != '') {
      budget.header.contact = this.idCustomer;
      budget.header.contact_id = this.idCustomer;
      let eventTracking = '';
      if (this.type === TypeBudget.BILL) {
        eventTracking = AmplitudeEvents.final_card_bill_start;
      }

      if (this.type === TypeBudget.BUDGET) {
        eventTracking = AmplitudeEvents.final_card_budget_start;
      }
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: eventTracking,
      });
    }

    const obs$: Observable<IBudgetCommonData> =
      this.type === TypeBudget.BUDGET
        ? // @ts-ignore
          this.budgetService.createOne(budget)
        : this.type == TypeBudget.PROFORM
        ? // @ts-ignore
          this.budgetService.createOneProform(budget)
        : // @ts-ignore
          this.budgetService.createOneBill(budget);

    obs$
      .pipe(finalize(() => this.loadingEvent.emit(false)))
      .subscribe((data) => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.maxWidth = '100vw';
        dialogConfig.maxHeight = '100vh';
        dialogConfig.height = '100%';
        dialogConfig.width = '100%';
        dialogConfig.panelClass = 'full-screen-modal';
        dialogConfig.data = {
          type: this.type.toUpperCase(),
          budget: data._id,
        };

        this.createBudgetService
          .open(dialogConfig)
          .pipe(map((res) => !res))
          .subscribe(console.log);
        // this.router.navigate(['/admin/budgets/edit'], {
        //   queryParams: this.getQueryParams({
        //     type: this.type.toUpperCase(),
        //     budget: data._id,
        //   }),
        // });
      });
  }

  /**
   * This function creates a budget of a specific type, redirects to the budget edit page with the newly
   * created budget ID, and emits a change budget event.
   * @param {BudgetCommonData} budget - The `budget` parameter is an object of type `BudgetCommonData`
   * which contains data related to a budget.
   */
  private createBudgetConvertedAndRedirect(budget: BudgetCommonData) {
    this.loadingEvent.emit(true);

    const budgetTypes = {
      // @ts-ignore
      [TypeBudget.BILL]: this.budgetService.createOneBill(budget),
      // @ts-ignore
      [TypeBudget.PROFORM]: this.budgetService.createOneProform(budget),
    };

    const obs$: Observable<IBudgetCommonData> = budgetTypes[this.typeToConvert];

    obs$
      .pipe(finalize(() => this.loadingEvent.emit(false)))
      .subscribe((res) => {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };

        /* Update Status from Budget or proform that I want to Convert */
        this.updateStatusoOfBudget();

        const data = {
          budget: res,
          type: this.typeToConvert,
          mode: TypeOfCRUD.UPDATE,
        };

        this.createBudgetService.open(data).subscribe((d) => {
          const event = d?.event;
          switch (event) {
            case 'refresh':
              this.router.navigate(['/admin/budgets/show'], {
                skipLocationChange: false,
                queryParams: this.getQueryParams({
                  type: this.typeToConvert?.toUpperCase(),
                  budget: res._id,
                  justCreated: true,
                }),
              });
              break;
            default:
              break;
          }
        });
        this.changeBudget.emit(true);
      });
  }

  /**
   * The function `updateStatusBudgetOrProform` updates the status of a budget or proform based on the
   * provided type and budget ID.
   * @param {TypeBudget} type - The `type` parameter is of type `TypeBudget`, which is an enum that
   * represents the type of budget. It can have two possible values: `BUDGET` or `PROFORM`.
   * @param {string} idBudgetToUpdate - The `idBudgetToUpdate` parameter is a string that represents the
   * ID of the budget or proforma that needs to be updated.
   * @returns a function call based on the value of `this.type`. If `this.type` is `TypeBudget.BUDGET`,
   * then `this.budgetService.updateStatus(idBudgetToUpdate, { status: STATUS_BUDGET_CHARGED.id })` is
   * returned. If `this.type` is `TypeBudget.PROFORM`, then `this.budgetService.updateStatusProform(id
   */
  updateStatusBudgetOrProform(
    type: TypeBudget,
    idBudgetToUpdate: string
  ): Observable<IBudgetCommonData> {
    const budgetTypes = {
      [TypeBudget.BUDGET]: this.budgetService.updateStatus(idBudgetToUpdate, {
        status: STATUS_BILL_PENDING.id,
      }),
      [TypeBudget.PROFORM]: this.budgetService.updateStatusProform(
        idBudgetToUpdate,
        { status: STATUS_BILL_PENDING.id }
      ),
    };

    return budgetTypes[this.type];
  }

  private getQueryParams(queryParams: Params) {
    const result = queryParams;

    if (this.idDeal) {
      result.idDeal = this.idDeal;
    }

    return result;
  }

  private sendAmplitudeEvent() {
    try {
      let eventData!: {
        event:
          | 'proform_start'
          | AmplitudeEvents.deal_card_sale_bill_start
          | AmplitudeEvents.deal_card_sale_budget_start;
      };

      let eventBraze!: {
        event: 'proform_create' | 'bill_create' | 'budget_create';
      };

      if (this.type === TypeBudget.PROFORM) {
        eventData = {
          event: 'proform_start',
        };
        eventBraze = {
          event: 'proform_create',
        };
      }
      if (this.type === TypeBudget.BILL) {
        eventData = {
          event: AmplitudeEvents.deal_card_sale_bill_start,
        };
        eventBraze = {
          event: 'bill_create',
        };
      }
      if (this.type === TypeBudget.BUDGET) {
        eventData = {
          event: AmplitudeEvents.deal_card_sale_budget_start,
        };
        eventBraze = {
          event: 'budget_create',
        };
      }

      this.amplitudeService.sendEvent(eventData);
      this.brazeService.sendEvent(eventData);
      this.brazeService.sendEvent(eventBraze);
    } catch (err) {}
  }

  /**
   * The function "updateStatusOfBudget" updates the status of a budget and logs the updated status.
   */
  updateStatusoOfBudget() {
    if (
      this.type === TypeBudget.BUDGET &&
      this.typeToConvert === TypeBudget.PROFORM
    )
      return;

    const obs$: Observable<IBudgetCommonData> = this.updateStatusBudgetOrProform(
      this.type,
      this.idBudgetToConvert
    );

    obs$.subscribe((bdg) => {
      console.log(
        `status budgetUpdated type ${this.type} , Id :  ${this.idBudgetToConvert} `
      );
    });
  }
}
