import { ClockAbsence } from '@tacliatech/types';

export namespace AddClockAbsence {
  export interface Params {
    mode: 'ADD' | 'EDIT';
    defaultStartDate?: Date;
    clockAbsence?: ClockAbsence.Output;
    previewMode?: boolean;
    isAdminAbsences?: boolean;
    userId?: string;
    calendarView?: boolean;
  }

  export interface Result {
    reload: boolean;
    forceClose?: boolean;
  }

  export const ClockAbsenceTypes = [
    {
      value: ClockAbsence.AbsenceType.Vacation,
      title: 'clockAbsence.holidays',
    },
    {
      value: ClockAbsence.AbsenceType.Sickness,
      title: 'clockAbsence.sickness',
    },
    {
      value: ClockAbsence.AbsenceType.MaternityOrPaternity,
      title: 'clockAbsence.maternityOrPaternity',
    },
    {
      value: ClockAbsence.AbsenceType.SicknessOfAFamilyMember,
      title: 'clockAbsence.sicknessOfAFamilyMember',
    },
    {
      value: ClockAbsence.AbsenceType.Other,
      title: 'clockAbsence.others',
    },
  ];
}
