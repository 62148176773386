import { Injectable } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import { COMPANY_ID_KEY } from '@tacliatech/types';

import { Subject } from 'rxjs';

import { StorageService } from '../storage';
import { UserService } from '../users';

import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  private tapSource = new Subject<string>();

  tap$ = this.tapSource.asObservable();

  private registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (
      permStatus.receive === 'prompt' ||
      permStatus.receive === 'prompt-with-rationale'
    ) {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      return;
    }

    await PushNotifications.register();
  };

  constructor(private userService: UserService) {}

  async init() {
    const isAvailable = Capacitor.isPluginAvailable('PushNotifications');

    if (!isAvailable) {
      return;
    }

    PushNotifications.addListener('registration', (token: Token) => {
      if (token.value) {
        // @ts-ignore
        this.userService
          .updateOne(StorageService.UserId, {
            fcmToken: token.value,
            company: StorageService.GetItem(COMPANY_ID_KEY),
          })
          .subscribe();
      }
    });

    PushNotifications.addListener('registrationError', (error: any) => {});

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {}
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        if (notification.actionId === 'tap') {
          this.tapSource.next(
            notification.notification.data?.idClientNotification
          );
        }
      }
    );

    // await this.requestPermissions();
    await this.registerNotifications();
  }

  async close() {
    try {
      const isAvailable = Capacitor.isPluginAvailable('PushNotifications');

      if (!isAvailable) {
        return;
      }

      PushNotifications.removeAllListeners();

      this.userService
        .updateOne(StorageService.UserId, { fcmToken: '' })
        ?.subscribe();
    } catch (err) {
      console.error('ERROR:', err);
    }
  }
}
