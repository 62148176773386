import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsaveGuard } from './unsaved-changes.guard';
import { UnsavedChangesComponent } from './component/unsaved-changes.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UnsavedChangesComponent],
  imports: [CommonModule, TranslateModule.forChild()],
  providers: [UnsaveGuard],
})
export class UnsavedChangesModule {}
