import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalAddComponent } from '../add-custom-property/modal-add/modal-add.component';
import {
  TutorialModule,
  TutorialService,
} from '@web-frontend/shared/services/tutorial';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';

@Component({
  selector: 'roma-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss'],
})
export class ModalVideoComponent implements OnInit, OnDestroy {
  @Input() type = '';
  @Input() url: SafeResourceUrl = '';
  isLoading = true;
  showLoading = true;

  videoId = '';

  private sub$ = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<ModalAddComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public sanitizer: DomSanitizer,
    private tutorialService: TutorialService,
    private cdRef: ChangeDetectorRef,
    private amplitudeService: AmplitudeService
  ) {
    this.loadYTScript();
  }

  ngOnInit(): void {
    this.type = this.data.type;

    this.sub$.add(
      this.tutorialService
        .get(this.type as TutorialModule)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(res.source);
          const reg = new RegExp(
            '^.*(youtu.be/|v/|u/w/|embed/|watch?v=|&v=)([^#&?]*).*'
          );
          if (this.url.toString().includes('youtube')) {
            this.videoId = reg.exec(this.url.toString())[2] || '';
            this.videoId = this.videoId.trim().split(' ')[0];
          }
        })
    );
  }

  ngOnDestroy(): void {
    const iframe_api = document.getElementById('youtube-iframe-api');
    this.sub$.unsubscribe();
    if (iframe_api) {
      iframe_api.remove();
    }
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  cancelEvt() {
    this.dialogRef.close();
  }

  loadYTScript(): void {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://www.youtube.com/iframe_api';
    script.async = true;
    script.id = 'youtube-iframe-api';
    script.defer = true;
    body.appendChild(script);
  }

  playerStatus(e?: { target: any; data: number }): void {
    if (this.type != 'budgets') {
      return;
    }
    const status = e.data;

    switch (status) {
      case 1:
        this.amplitudeService.sendEvent({
          event: `${this.data.budgetTypeAmplitude}_tutorialVideo_play`,
        });
        break;
      default:
        break;
    }
  }
}
