import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ElementRef, EventEmitter, Injectable, Renderer2 } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@web-frontend/environments';
import { StorageService } from '@web-frontend/shared/services';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Feature } from '../../../../types-legacy';

export enum ChargebeePortalSections {
  'SUBSCRIPTION_DETAILS' = 'sub_details',
  'SUBSCRIPTION_CANCELLATION' = 'sub_cancel',
  'EDIT_SUBSCRIPTION' = 'edit_subscription',
  'VIEW_SCHEDULED_CHANGES' = 'scheduled_changes',
  'ACCOUNT_DETAILS' = 'account_details',
  'EDIT_ACCOUNT_DETAILS' = 'portal_edit_account',
  'ADDRESS' = 'portal_address',
  'EDIT_BILLING_ADDRESS' = 'portal_edit_billing_address',
  'EDIT_SHIPPING_ADDRESS' = 'portal_edit_shipping_address',
  'EDIT_SUBSCRIPTION_CUSTOM_FIELDS' = 'portal_edit_subscription_cf',
  'PAYMENT_SOURCES' = 'portal_payment_methods',
  'ADD_PAYMENT_SOURCE' = 'portal_add_payment_method',
  'EDIT_PAYMENT_SOURCE' = 'portal_edit_payment_method',
  'VIEW_PAYMENT_SOURCE' = 'portal_view_payment_method',
  'CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION' = 'portal_choose_payment_method',
  'BILLING_HISTORY' = 'portal_billing_history',
}

@Injectable({
  providedIn: 'root',
})
export class ChargebeeService {
  cbInstance;
  onReload: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private _bottomSheet: MatBottomSheet,
    private analyticsService: AnalyticsService
  ) {}

  injectScript(renderer: Renderer2, elementRef: ElementRef): void {
    const script = renderer.createElement('script');
    script.src = 'https://js.chargebee.com/v2/chargebee.js';

    script.onload = () => {
      window['Chargebee'].init({
        site: environment.chargebeeSite,
        enableGTMTracking: true,
      });

      this.cbInstance = window['Chargebee'].getInstance();
    };

    renderer.appendChild(elementRef.nativeElement, script);
  }

  getNavChangeEmitter(): EventEmitter<boolean> {
    return this.onReload;
  }

  async reloadPermissions(): Promise<void> {
    this.dialog.closeAll();
    this._bottomSheet.dismiss();
    await this.permissionService.init().finally(() => {
      this.onReload.emit(true);
    });
  }

  openCheckout(
    email: string,
    planTypeId?: string
  ): Promise<{ success: boolean }> {
    return new Promise((resolve) => {
      this.cbInstance.logout();
      this.cbInstance.openCheckout({
        hostedPage: () => {
          // Hit your end point that returns hosted page object as response
          // This sample end point will call the below api
          // You can pass hosted page object created as a result of checkout_new, checkout_existing, manage_payment_sources
          // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
          // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_existing_subscription
          // https://apidocs.chargebee.com/docs/api/hosted_pages#manage_payment_sources
          // If you want to use paypal, go cardless and plaid, pass embed parameter as false

          return this.http
            .post(':API_URL/subscription-manager/manage-hosted-page', {
              'customer[id]': StorageService.CompanyId,
              customer: {
                email: email,
              },
              subscription_items: [
                {
                  item_price_id: planTypeId,
                },
              ],
            })
            .toPromise()
            .catch((err: HttpErrorResponse) => {
              console.error('An error occurred:', err.error);
            });
        },
        close: () => {
          resolve({ success: false });
        },
        success: () => {
          const findPermissions = (count = 0) => {
            this.permissionService
              .findAll()
              .toPromise()
              .then(() => {
                if (
                  this.permissionService.permissions.includes(
                    Feature.SystemPermission.AllowAll
                  )
                ) {
                  window.location.reload();
                  resolve({ success: true });
                } else {
                  setTimeout(() => {
                    if (count < 3) {
                      findPermissions(count + 1);
                    } else {
                      window.location.reload();
                      resolve({ success: true });
                    }
                  }, 3000);
                }
              });
          };
          this.permissionService.resetCheckTrialCache();
          this.analyticsService.identifyUser(['amplitude']);
          this.cbInstance.closeAll();
          findPermissions();

          // Hosted page id will be unique token for the checkout that happened
          // You can pass this hosted page id to your backend
          // and then call our retrieve hosted page api to get subscription details
          // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
        },
      });
    });
  }

  openPortal(section?: ChargebeePortalSections): void {
    this.cbInstance.logout();
    let reload = false;
    this.cbInstance.setPortalSession(() => {
      // Hit your end point that returns portal session object as response
      // This sample end point will call the below api
      // https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session
      return this.http
        .post(':API_URL/subscription-manager/create-portal-session', {
          customer: {
            id: StorageService.CompanyId,
          },
        })
        .toPromise();
    });

    const forwardOption = section ? { sectionType: section } : undefined;
    this.cbInstance.createChargebeePortal().open(
      {
        close: async () => {
          this.cbInstance.logout();
          if (reload) {
            this.reloadPermissions();
          }
        },
        paymentSourceAdd: (status) => {
          if (status === 'success') {
            reload = true;
          }
        },
        paymentSourceUpdate: (status) => {
          if (status === 'success') {
            reload = true;
          }
        },
        subscriptionChanged: (status) => {
          if (status === 'success') {
            reload = true;
          }
        },
        subscriptionCancelled: (status) => {
          if (status === 'success') {
            reload = true;
          }
        },
        visit: (value) => {
          if (
            [
              ChargebeePortalSections.SUBSCRIPTION_CANCELLATION,
              ChargebeePortalSections.SUBSCRIPTION_DETAILS,
            ].includes(value)
          ) {
            reload = true;
          }
        },
      },
      forwardOption
    );
  }
}
