<roma-modal-template
  [title]="modalTitle() | translate"
  [showSuccessBtn]="false"
  [showCancelBtn]="false"
  [responsiveFull]="false"
  [isLoading]="isLoading"
  [showCloseBtn]="!isLoading"
  [showDefaultButtons]="false"
  [showHeader]="!isLoading"
  (cancel)="onClose()"
>
  <div *ngIf="step === 1 && !isLoading" class="d-flex flex-column">
    <p class="text">
      {{ 'modalDeleteAccount.step1.text' | translate }}
    </p>
    <button class="roma-button mb-2" (click)="onClickContact()">
      {{ 'modalDeleteAccount.step1.buttonContact' | translate }}
    </button>
    <button class="roma-button-outline-light" (click)="nextStep()">
      {{ 'modalDeleteAccount.step1.deleteAccount' | translate }}
    </button>
  </div>
  <div *ngIf="step === 2 && !isLoading" class="d-flex flex-column">
    <div class="w-100 d-flex justify-content-center pb-3">
      <div class="delete-icon">
        <img src="assets/icons/gl_trash_red.svg" width="40" height="40" />
      </div>
    </div>
    <p class="text">
      {{ 'modalDeleteAccount.step2.text' | translate }}
    </p>
    <div class="d-flex flex-row justify-content-center" style="gap: 12px">
      <button class="roma-button outline" (click)="onClickConserve()">
        {{ 'modalDeleteAccount.step2.buttonConserve' | translate }}
      </button>
      <button
        class="roma-button danger outline outline-gray text-danger"
        (click)="onClickDeleteAccount()"
      >
        {{ 'modalDeleteAccount.step2.buttonDelete' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="isLoading">
    <p class="text-loading">
      {{ 'modalDeleteAccount.loading' | translate }}
    </p>
    <mat-progress-bar
      mode="indeterminate"
      color="#5F69EF"
      style="height: 12px; border-radius: 20px"
    ></mat-progress-bar>
  </div>
</roma-modal-template>
