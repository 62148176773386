import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlansComponent } from './plans.component';
import { BuyModalComponent } from '../buy-modal/buy-modal.component';
import { FeatureFlagsService } from '../../../services/feature-flags/feature-flags.service';
import { StorageService } from '../../../services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagsService
  ) {}

  public openPlansModal(props?: {
    data?: { [key: string]: unknown };
    onClose?: () => void;
  }): void {
    const companyCreatedAt = new Date(
      JSON.parse(StorageService.companyData).created_at
    );
    this.featureFlagService
      .getValue('subscription_plans', false, {
        companyCreatedAt: companyCreatedAt.getTime(),
      })
      .then((isSubscriptionPlansEnabled) => {
        const dialogRef = isSubscriptionPlansEnabled
          ? this.createModalPlans(props?.data)
          : this.createModalPlansLegacy(props?.data);

        if (props?.onClose) {
          dialogRef.afterClosed().subscribe(() => {
            props.onClose();
          });
        }
      });
  }

  private createModalPlansLegacy(data: { [key: string]: unknown }) {
    return this.dialog.open<BuyModalComponent>(BuyModalComponent, {
      data,
      disableClose: true,
    });
  }

  private createModalPlans(data: { [key: string]: unknown }) {
    return this.dialog.open<PlansComponent>(PlansComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'full-screen-dialog',
      disableClose: true,
      data,
    });
  }
}
