<!-- eslint-disable @angular-eslint/template/eqeqeq -->

<!-- Stripe Snackbar -->
<roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>

<!-- Trial Disclaimer -->
<roma-pro-disclaimer-banner
  *ngIf="isLowerThan1450px"
  (emitRefresh)="refresh()"
  [rootModule]="'task'"
>
</roma-pro-disclaimer-banner>
<div class="header" style="width: 100%">
  <div class="header__title">
    <div class="page-header" *ngIf="showTitle">
      <h1 id="activitiesViewTitle" class="page-title">
        {{ customer?.name ? customer?.name : ('activity.title' | translate) }}
        <div class="tooltip-container">
          <img
            width="32px"
            class="help-icon"
            [src]="iconDialog"
            (click)="showDialog()"
          />
          <div
            *ngIf="showToolTip"
            class="title-tooltip-wrapper"
            (click)="toggleTooltip(false)"
          ></div>
          <div
            class="help-tooltip 22"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
            (clicked)="toggleTooltip($event)"
          >
            <div [innerHTML]="'activity.tooltip' | translate"></div>
            <a
              *ngIf="academyLink?.length"
              [href]="academyLink"
              target="_blank"
              >{{ 'activity.tooltipUrl' | translate }}</a
            >
          </div>
        </div>
      </h1>
    </div>
    <div
      class="tutorial"
      *ngIf="getLang() === 'es'"
      (click)="openVideo('activities')"
    >
      <strong>{{ 'tutorial.tutorial' | translate }}</strong>
      <img class="video" src="assets/icons/gl_play-tutorial.svg" />
    </div>
    <button
      *ngIf="!(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
      class="roma-button add-button-3 mobile-hidden"
      style="display: inline-block"
      romaProFeature
      [featureUser]="featureRefUser.Activity.create"
      (clicked)="addActivity()"
    >
      {{ 'general.addButton' | translate }}
      <img class="icon-option" src="assets/icons/gl_plus-btn.svg" />
    </button>
  </div>
</div>

<div id="activitiesFiltersContainer" class="row" *ngIf="showFilter">
  <div class="col">
    <div
      *ngIf="(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
      class="container__filters"
    >
      <!-- mobile type view -->
      <roma-rm-filter-button
        *ngIf="sourceOptions"
        [source]="sourceOptions"
        (changes)="changeFilters($event)"
      ></roma-rm-filter-button>
      <button
        class="mat-raised-button mat-secondary list-select"
        (click)="toggleViewType(true)"
      >
        <ng-container *ngIf="viewTypeActive === 'ROW'">
          <mat-icon svgIcon="table_icon"></mat-icon>
          <span class="icon-text">{{ 'dashboard.list' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="viewTypeActive === 'PIPELINE'">
          <mat-icon svgIcon="pipeline_icon"></mat-icon>
          <span class="icon-text">{{ 'dashboard.board' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="viewTypeActive === 'CALENDAR'">
          <mat-icon svgIcon="activity_calendar_icon"></mat-icon>
          <span class="icon-text">{{ 'dashboard.calendar' | translate }}</span>
        </ng-container>
        <img class="arrow" src="/assets/icons/gl_arrow_down.svg" alt="link" />
      </button>
      <button
        class="mobile-button-add"
        romaProFeature
        [featureUser]="featureRefUser.Activity.create"
        (clicked)="addActivity()"
      >
        <img
          src="/assets/icons/gl_plus-1.svg"
          alt="{{ 'general.add' | translate }}"
        />
      </button>
    </div>
  </div>
</div>

<div class="page-options">
  <div class="col-12" style="padding: 0">
    <div
      class="filter-wrapper mobile-hidden"
      style="width: fit-content; background-color: #f2f6fc; margin-bottom: 0"
    >
      <!-- Calendar button -->
      <button
        *ngIf="showCalendar"
        class="btn btn-align"
        [ngClass]="{ active: viewTypeActive === 'CALENDAR' }"
        (click)="changeViewType('CALENDAR')"
      >
        <mat-icon
          class="align-icon"
          svgIcon="activity_calendar_icon"
        ></mat-icon>
        <span class="align-text">{{ 'dashboard.calendar' | translate }}</span>
      </button>
      <!-- Row button -->
      <button
        class="btn btn-align"
        [ngClass]="{ active: viewTypeActive === 'ROW' }"
        (click)="changeViewType('ROW')"
      >
        <mat-icon class="align-icon" svgIcon="table_icon"></mat-icon>
        <span class="align-text">{{ 'dashboard.list' | translate }}</span>
      </button>
      <!-- Pipeline button -->
      <button
        class="btn"
        [ngClass]="{ active: viewTypeActive === 'PIPELINE' }"
        (click)="
          changeViewType('PIPELINE'); requestActivities({ loadingGlobal: true })
        "
      >
        <mat-icon svgIcon="pipeline_icon"></mat-icon>
        <span class="icon-text">{{ 'dashboard.board' | translate }}</span>
      </button>
    </div>

    <div
      *ngIf="!(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
      class="container__filters"
      class="filter-element"
    >
      <!-- <roma-filter
        [showDatePicker]="true"
        [showStatus]="false"
        [showAuthors]="true"
        [showAssets]="true"
        [showDeals]="true"
        [user]="user"
        [inputPlaceholder]="'activity.placeholder' | translate"
        [dealsPlaceholder]="'activity.create.form.deal' | translate"
        [viewToday]="'activity.viewToday' | translate"
        [viewDelete]="'activity.viewDelete' | translate"
        [customProperties]="customProperties"
        (changeDeal)="changeDeal($event)"
        (changeAuthor)="changeAuthor($event)"
        (changeAssets)="changeAssets($event)"
        (inputChange)="changeInput($event)"
        (addClick)="addActivity()"
        type="activities"
        [user]="user"
        showNotified="true"
        showDeleted="true"
        showToday="true"
        (changeNotified)="changeNotified($event)"
        (changeDeleted)="changeDeleted($event)"
        (changeToday)="changeToday($event)"
        (startDateChange)="changeDate('START', $event)"
        (endDateChange)="changeDate('END', $event)"
        (changeCustomProperties)="changeCustomProperties($event)"
        (saveFilters)="applyFilters($event)"
      >
      </roma-filter> -->
      <roma-rm-filter-core
        style="display: inline-block; margin-right: 12px"
        *ngIf="sourceSearch"
        [source]="sourceSearch"
        (changes)="changeFilters($event)"
      ></roma-rm-filter-core>
      <roma-rm-filter-button
        *ngIf="sourceOptions"
        [source]="sourceOptions"
        (changes)="changeFilters($event)"
      ></roma-rm-filter-button>

      <!-- START Filter for tasks -->
      <roma-rm-filter-button
        *ngIf="sourceOptionsForActivities"
        filterHeight="fit-content"
        filterWidth="fit-content"
        [title]="'activity.viewTasks'"
        [source]="sourceOptionsForActivities"
        (changes)="changeFilters($event)"
        style="margin-left: 0.8rem"
      ></roma-rm-filter-button>
      <!-- END Filter for tasks -->

      <!-- Clean filters -->
      <roma-rm-filter-clear
        *ngIf="sourceOptions || sourceOptionsForActivities"
        [sources]="[sourceSearch, sourceOptions, sourceOptionsForActivities]"
        (changes)="changeFilters($event)"
      ></roma-rm-filter-clear>
    </div>
  </div>
</div>

<div *ngIf="openMobileViewType" class="mobile-viewtype-wrapper">
  <div class="viewtype-list">
    <h4>{{ 'general.view' | translate }}</h4>
    <div class="link-list">
      <div class="list-item" (click)="changeViewType('ROW')">
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 8H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              d="M14 12.5H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              d="M14 3.5H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.list' | translate }}</span>
        <p>{{ 'dashboard.list_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'ROW'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div class="list-item" (click)="changeViewType('PIPELINE')">
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 3.1001C2 2.27167 2.67157 1.6001 3.5 1.6001H12.5C13.3284 1.6001 14 2.27167 14 3.1001V12.9001C14 13.7285 13.3284 14.4001 12.5 14.4001H3.5C2.67157 14.4001 2 13.7285 2 12.9001V3.1001ZM4.56015 4H6.96015V11.2H4.56015V4ZM11.3601 4H8.96014V9.6H11.3601V4Z"
              fill="#9DA9C9"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.board' | translate }}</span>
        <p>{{ 'dashboard.board_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'PIPELINE'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div
        *ngIf="showCalendar"
        class="list-item"
        (click)="changeViewType('CALENDAR')"
      >
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9602 0.800049V3.68005"
              stroke="#9DA9C9"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M5.20001 0.800049V3.68005"
              stroke="#9DA9C9"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.04001 2.23999H13.12C13.9153 2.23999 14.56 2.8847 14.56 3.67999L14.56 5.56006H1.60001V3.67999C1.60001 2.8847 2.24472 2.23999 3.04001 2.23999ZM1.60001 7.56006V12.96C1.60001 13.7553 2.24472 14.4 3.04001 14.4H13.12C13.9153 14.4 14.56 13.7553 14.56 12.96L14.56 7.56006H1.60001Z"
              fill="#9DA9C9"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.calendar' | translate }}</span>
        <p>{{ 'dashboard.calendar_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'CALENDAR'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div class="text-center close-btn-wrapper">
        <img
          (click)="toggleViewType(false)"
          class="close-btn"
          src="/assets/icons/btn_close.svg"
          alt="close"
        />
      </div>
    </div>
  </div>
</div>

<div class="page-options">
  <div
    class="table-total md"
    style="float: left"
    [ngStyle]="{ visibility: viewTypeActive === 'ROW' ? 'visible' : 'hidden' }"
  >
    {{ resultSearch?.total || 0 }} {{ 'general.results' | translate }}
  </div>

  <div class="flex">
    <!-- Download Excel button (on top on table) -->
    <roma-shared-download-btn
      *ngIf="
        !(sandBoxService.screenBusChannel$ | async)?.payload?.mobile &&
        resultSearch?.docs?.length
      "
      [featureExcel]="featureRef.Activity.DownloadExcel"
      [showDownloadExcel]="true"
      [showDownloadPdf]="false"
      (download)="download($event)"
      [ngClass]="{ 'is-hidden': isLoading }"
    ></roma-shared-download-btn>
    <!-- Download Excel button (on top on table) -->
    <button
      *ngIf="showAddBtn"
      class="tutorial"
      style="display: inline-block"
      romaProFeature
      [featureUser]="featureRefUser.Activity.create"
      (clicked)="addActivity()"
    >
      {{ 'general.addButton' | translate }}
      <img class="video" src="assets/icons/gl_plus.svg" />
    </button>
  </div>
</div>

<div
  id="containerTable"
  [ngClass]="{
    'video-tutorial-box':
      !customer?._id &&
      !(searchParamsHasChange$ | async) &&
      !activities?.data?.length,
    containerTable: viewTypeActive != 'PIPELINE',
    kanbanContainer: viewTypeActive === 'PIPELINE'
  }"
  [ngStyle]="{ 'margin-top': tableMarginTop }"
  (scroll)="checkScroll($event)"
>
  <div *ngIf="viewTypeActive === 'ROW'">
    <div *ngIf="isLoading" class="col-12">
      <roma-loading></roma-loading>
    </div>
    <div
      class="no-registers"
      *ngIf="
        customer?._id &&
        viewTypeActive === 'PIPELINE' &&
        kanbanCards.length === 0
      "
    >
      <img src="assets/icons/empty-list-icon.svg" />
      <div class="no-registers__title">{{ 'general.ohNo' | translate }}</div>
      <div class="no-registers__text">
        {{ 'tasks.noFinalTasks' | translate }}
      </div>
      <button
        class="no-registers__button"
        romaProFeature
        [featureUser]="featureRefUser.Activity.create"
        (clicked)="addActivity()"
      >
        {{ 'tasks.add_task' | translate }}
        <img src="assets/icons/gl_plus-btn.svg" />
      </button>
    </div>
    <div
      *ngIf="
        (customer?._id &&
          !isLoading &&
          !(searchParamsHasChange$ | async) &&
          !activities?.data?.length) ||
        (!activities?.data?.length && hiddenNoData && !isLoading)
      "
      class="col-12"
    >
      <div class="no-registers">
        <img src="assets/icons/empty-list-icon.svg" />
        <div class="no-registers__title">{{ 'general.ohNo' | translate }}</div>
        <div class="no-registers__text">
          {{ 'tasks.noFinalTasks' | translate }}
        </div>
        <button
          class="no-registers__button"
          romaProFeature
          [featureUser]="featureRefUser.Activity.create"
          (clicked)="addActivity()"
        >
          {{ 'tasks.add_task' | translate }}
          <img src="assets/icons/gl_plus-btn.svg" />
        </button>
      </div>
    </div>

    <!-- <div
      *ngIf="!isLoading && !hasQuerySearch() && !activities?.data?.length"
      class="col-12"
    >
      <roma-basic-message
        [message]="'activity.notResult' | translate"
      ></roma-basic-message>
    </div> -->
    <div
      *ngIf="
        !isLoading &&
        (searchParamsHasChange$ | async) &&
        !activities?.data?.length
      "
      class="col-12"
    >
      <roma-not-result-found
        (changes)="($event.type === 'CLEAR' ? null : null)"
      ></roma-not-result-found>
    </div>
    <ng-container *ngIf="columns.length">
      <div
        class="col-12"
        [ngClass]="{ 'is-hidden': isLoading || !activities?.data?.length }"
      >
        <roma-list-mobile-activities
          *ngIf="(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
          [data]="activities?.data"
          (actions)="deleteMany($event)"
          (onClickRow)="editActivity($event)"
          style="margin-top: 1rem"
        >
        </roma-list-mobile-activities>
        <roma-table
          *ngIf="!(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
          class="table"
          markAction="true"
          type="activity"
          idModule="ACTIVITY_MODULE"
          [appendSettings]="'YES'"
          [tagNotAvailable]="'activity.notAvailable' | translate"
          [data]="activities"
          [displayedColumns]="columns"
          [customFields]="customProperties"
          [showActions]="true"
          [statusNames]="statusNames"
          (changeSort)="sortChange($event)"
          (wantDelete)="removeActivity($event)"
          (wantEdit)="editActivity($event)"
          (wantMark)="markActivity($event)"
          [appendInfo]="'YES'"
          [moreFields]="moreTableFields"
          (wantRestore)="restoreActivity($event)"
          (checkRowChanges)="deleteMany($event)"
          (changeStatusEvt)="changeActivityStatus($event)"
          (finishTaskEvt)="finishTask($event)"
        >
        </roma-table>
      </div>
    </ng-container>

    <div
      *ngIf="
        (!customer?._id &&
          !isLoading &&
          !(searchParamsHasChange$ | async) &&
          !activities?.data?.length) ||
        (!activities?.data?.length && hiddenNoData && !isLoading)
      "
    >
      <div style="text-align: center; align-self: center">
        <div>
          <div class="align-self-center">
            <h2>
              {{ 'clearState.ac1' | translate }}
            </h2>
            <p>
              {{ 'clearState.ac2' | translate }}
            </p>
            <div class="col-12" style="text-align: center; align-self: center">
              <button
                class="button"
                mat-button
                romaProFeature
                [featureUser]="featureRefUser.Activity.create"
                (clicked)="addActivity()"
              >
                {{ 'activity.createActivity' | translate }}
              </button>
            </div>
          </div>

          <div class="col-12 text-center p-4" *ngIf="getLang() === 'es'">
            <iframe
              *ngIf="showVideoIframe"
              width="640"
              height="360"
              [src]="videoIframeURL"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading && isLoadingPaginate" class="col-12">
      <roma-loading></roma-loading>
    </div>
  </div>

  <roma-activity-calendar
    *ngIf="viewTypeActive === 'CALENDAR'"
    [searchParams]="searchParams"
    (activityClick)="requestOpenActivity($event)"
  ></roma-activity-calendar>
  <roma-kanban
    *ngIf="viewTypeActive === 'PIPELINE'"
    [modalIn]="customer?._id ? true : false"
    [type]="typeOfStatus"
    [active]="viewTypeActive == 'PIPELINE'"
    [columns]="statusNames"
    [cards]="kanbanCards"
    [cardsName]="'kanban.cardsNameTask'"
    [columnsName]="'kanban.columnsNameTask'"
    [showCardId]="false"
    [showFinishCard]="true"
    [showDeleteCard]="true"
    [showCancelCard]="false"
    [showRestoreCard]="true"
    [isLoading]="isLoading"
    [allowCreateColumn]="true"
    [allowDeleteColumn]="true"
    [cardDropPlaceHolderType]="'line'"
    [deleteColumnMessage]="'kanban.deleteTaskMessage'"
    [restoreCardMessage]="'activity.restoreActivity'"
    [placeholderAddCard]="'kanban.placeHolderAddCardTask'"
    [cardCreateText]="'kanban.cardCreateTextTask'"
    [withoutColumnText]="'kanban.withoutColumnTextTask'"
    [deleteCardMessage]="'kanban.deleteCardMessageTask'"
    [tooltipDeleteSystemColumn]="'kanban.tooltipSystemColumnTask'"
    [unfinishToastParms]="unfinishToastParams"
    [finishToastParms]="finishToastParams"
    [deleteToastParms]="deleteToastParams"
    [restoreToastParms]="restoreToastParams"
    [featureUserCreate]="featureRefUser.Activity.create"
    [featureUserUpdate]="featureRefUser.Activity.update"
    [featureUserDelete]="featureRefUser.Activity.delete"
    (onCreateFirstKanban)="eventCreateFirstKanban()"
    (onCardClick)="eventOnCardClick($event)"
    (onCardDblClick)="eventCardDblClick($event)"
    (onAddCard)="eventAddCard($event)"
    (onAddColumn)="eventAddColumn($event)"
    (onChangeColor)="eventChangeColor($event)"
    (onEditColumn)="eventEditColumn($event)"
    (onDeleteColumn)="eventDeleteColumn($event)"
    (onCardColumnChange)="eventCardColumnChange($event)"
    (onRestoreCard)="eventRestoreCard($event)"
    (onFinishCard)="eventFinishCard($event)"
    (onUnfinishCard)="eventUnfinishCard($event)"
    (onEditCard)="eventEditCard($event)"
    (onDeleteCard)="eventDeleteCard($event)"
    (onMoveAllCards)="eventMoveAllCards($event)"
    (onFinishSelectedCards)="eventFinishSelectedCards($event)"
    (onUnfinishSelectedCards)="eventUnfinishSelectedCards($event)"
    (onMoveSelectedCards)="eventMoveSelectedCards($event)"
    (onDeleteSelectedCards)="eventDeleteSelectedCards($event)"
    (onRestoreSelectedCards)="evenRestoreSelectedCards($event)"
    (onUnfinishToastLinkClick)="eventUnfinishToastLinkClick($event)"
    (onRestoreToastLinkClick)="eventRestoreToastLinkClick($event)"
  ></roma-kanban>
</div>
<!-- table pagination -->
<div
  *ngIf="activities?.data?.length && viewTypeActive === 'ROW'"
  class="pagination"
>
  <button
    class="arrow prev"
    [attr.disabled]="resultSearch.page === 1 ? true : null"
    (click)="previousPage()"
  >
    <img src="assets/icons/gl_arrow_left.svg" />
  </button>
  <ng-container *ngFor="let page of totalPages">
    <div
      class="page-item"
      *ngIf="page.page < 6"
      [ngClass]="{ 'current-page': page.page == resultSearch.page }"
      (click)="goToPage(page.page)"
    >
      {{ page.page }}
    </div>
    <div
      class="page-item"
      *ngIf="
        resultSearch.countPages > 5 && page.page == resultSearch.countPages
      "
      [ngClass]="{ 'current-page': page.page == resultSearch.page }"
      (click)="goToPage(resultSearch.countPages)"
    >
      ...
      {{ resultSearch.countPages }}
    </div>
  </ng-container>
  <button
    class="arrow next"
    [attr.disabled]="!resultSearch.hasNextPage ? true : null"
    (click)="nextPage()"
  >
    <img src="assets/icons/gl_arrow_right-pagination.svg" />
  </button>
</div>

<div
  *ngIf="deleteMessage"
  class="row justify-content-center justify-content-lg-start"
>
  <div class="col-12 col-md-11" style="position: relative">
    <div class="delete-message" [ngClass]="{ modal__in: customer?._id }">
      <strong>
        ({{ entriesForDelete.length }})
        {{ 'kanban.selected' | translate }}
      </strong>

      <div class="container-wrapper-delete">
        <ng-container *ngIf="searchParams?.takeDeleted">
          <!-- restore option   -->
          <button
            class="btn-download btn-restore"
            (clicked)="restoreActivities()"
            romaProFeature
            [featureUser]="featureRefUser.Activity.update"
          >
            <img src="assets/icons/gl_trash_restore.svg" />
            {{ 'activity.buttonRestore' | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="!searchParams?.takeDeleted">
          <!-- Mark multiple as finished -->
          <button
            class="btn-download border-7px-rounded"
            [disabled]="isUpdatingMultiple || isMovingMultiple"
            [ngClass]="{ 'opacity-50': isUpdatingMultiple }"
            (clicked)="markMultipleAsFinished()"
            romaProFeature
            [featureUser]="featureRefUser.Activity.update"
          >
            <mat-progress-spinner
              *ngIf="isUpdatingMultiple"
              style="margin-right: 5px"
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="20"
            ></mat-progress-spinner>
            <img
              *ngIf="!isUpdatingMultiple"
              src="assets/icons/kanban/check.svg"
              class="check-icon"
            />
            {{
              (!this.searchParams?.takeNotified
                ? 'activity.markAsDone'
                : 'kanban.buttonMarkAsUnFinished'
              ) | translate
            }}
          </button>
          <!-- Mark multiple as finished -->

          <!-- Move multiple to another status -->
          <button
            *ngIf="!(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
            class="btn-download"
            [disabled]="isUpdatingMultiple || isMovingMultiple"
            [ngClass]="{
              'opacity-50': isMovingMultiple
            }"
            [matMenuTriggerFor]="listOfStatuses"
          >
            <img
              [src]="
                listOfStatuses.menuOpen
                  ? 'assets/icons/kanban/gl_move-blue.svg'
                  : 'assets/icons/kanban/gl_move-black.svg'
              "
            />
            {{ 'kanban.move' | translate }}

            <mat-progress-spinner
              style="margin-left: 7px"
              *ngIf="isMovingMultiple"
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="20"
            ></mat-progress-spinner>
          </button>
          <button
            class="btn-download"
            *ngIf="(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
            (clicked)="showMoveCardMobileMenu = true"
            romaProFeature
            [featureUser]="featureRefUser.Activity.update"
          >
            <img
              class="close-button"
              src="assets/icons/kanban/gl_move-black.svg"
            />
            {{ 'kanban.move' | translate }}
          </button>
          <!-- List of statuses -->
          <mat-menu
            class="panel-choose panel-move"
            #listOfStatuses="matMenu"
            xPosition="before"
          >
            <ng-container *ngFor="let status of statusNames">
              <button
                mat-menu-item
                style="text-align: left"
                romaProFeature
                [featureUser]="featureRefUser.Activity.update"
                (clicked)="moveMultipleToAnotherStatus_TABLE(status)"
              >
                {{ status.name }}
              </button>
            </ng-container>
          </mat-menu>
          <!-- List of statuses -->

          <!-- Move multiple to another status -->
          <!-- <button
            *ngIf="!(sandBoxService.screenBusChannel$ | async)?.payload?.mobile"
            class="btn-download"
            romaProFeature
            [feature]="featureRef.Activity.DownloadExcel"
            (clicked)="downloadExcelSelection()"
          >
            <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
            <img class="download-icon" src="assets/icons/gl_download.svg" />
            {{ 'general.downloadExcel' | translate }}
          </button> -->
          <button
            class="btn"
            (clicked)="openDeleteManyDialog()"
            romaProFeature
            [featureUser]="featureRefUser.Activity.delete"
          >
            <img src="assets/icons/gl_trash_red.svg" class="delete-icon" />
            <ng-container
              *ngIf="
                !(sandBoxService.screenBusChannel$ | async)?.payload?.mobile
              "
            >
              {{ 'general.deleteAlt' | translate }}
            </ng-container>
          </button>
        </ng-container>
        <div class="btn-close" (click)="unselectItems()"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="openMobileViewType" class="mobile-viewtype-wrapper">
  <div class="viewtype-list">
    <h4>{{ 'general.view' | translate }}</h4>
    <div class="link-list">
      <div class="list-item" (click)="changeViewType('ROW')">
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 8H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              d="M14 12.5H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
            <path
              d="M14 3.5H2"
              stroke="#9DA9C9"
              stroke-width="2"
              stroke-linecap="square"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.list' | translate }}</span>
        <p>{{ 'dashboard.list_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'ROW'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div class="list-item" (click)="changeViewType('PIPELINE')">
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 3.1001C2 2.27167 2.67157 1.6001 3.5 1.6001H12.5C13.3284 1.6001 14 2.27167 14 3.1001V12.9001C14 13.7285 13.3284 14.4001 12.5 14.4001H3.5C2.67157 14.4001 2 13.7285 2 12.9001V3.1001ZM4.56015 4H6.96015V11.2H4.56015V4ZM11.3601 4H8.96014V9.6H11.3601V4Z"
              fill="#9DA9C9"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.board' | translate }}</span>
        <p>{{ 'dashboard.board_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'PIPELINE'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div
        *ngIf="showCalendar"
        class="list-item"
        (click)="changeViewType('CALENDAR')"
      >
        <mat-icon class="prev-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9602 0.800049V3.68005"
              stroke="#9DA9C9"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M5.20001 0.800049V3.68005"
              stroke="#9DA9C9"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.04001 2.23999H13.12C13.9153 2.23999 14.56 2.8847 14.56 3.67999L14.56 5.56006H1.60001V3.67999C1.60001 2.8847 2.24472 2.23999 3.04001 2.23999ZM1.60001 7.56006V12.96C1.60001 13.7553 2.24472 14.4 3.04001 14.4H13.12C13.9153 14.4 14.56 13.7553 14.56 12.96L14.56 7.56006H1.60001Z"
              fill="#9DA9C9"
            />
          </svg>
        </mat-icon>
        <span class="icon-text">{{ 'dashboard.calendar' | translate }}</span>
        <p>{{ 'dashboard.calendar_text' | translate }}</p>
        <img
          *ngIf="viewTypeActive === 'CALENDAR'"
          class="arrow"
          src="/assets/icons/ic_check.svg"
          alt="link"
        />
      </div>
      <div class="text-center close-btn-wrapper">
        <img
          (click)="toggleViewType(false)"
          class="close-btn"
          src="/assets/icons/btn_close.svg"
          alt="close"
        />
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="showMoveCardMobileMenu"
  class="mobile-viewtype-wrapper"
  style="position: fixed !important"
>
  <div class="viewtype-list">
    <h4>
      <img
        (click)="showMoveCardMobileMenu = false"
        src="assets/icons/gl_arrow-full-left.svg"
        style="margin-right: 10px"
      />
      {{ 'kanban.moveTo' | translate }}
    </h4>
    <div class="link-list">
      <div class="search-input">
        <img class="left" src="assets/icons/gl_search.svg" />
        <input
          #inputFilterSearch
          class="filter-input"
          type="text"
          [placeholder]="'clockHour.inputPlaceholder' | translate"
          (keyup)="onKeySearch($event)"
        />
      </div>
      <ng-container *ngFor="let status of statusNames">
        <div
          class="list-item"
          (click)="
            moveMultipleToAnotherStatus_TABLE(status);
            showMoveCardMobileMenu = false
          "
          *ngIf="
            _searchValue
              ? status.name.toLowerCase().includes(_searchValue.toLowerCase())
              : true
          "
        >
          <mat-icon class="prev-icon">
            <img
              *ngIf="status.name === selectedCardName"
              width="15px"
              src="/assets/icons/ic_check.svg"
              alt="link"
            />
          </mat-icon>
          <span class="icon-text">
            {{ status.name }}
          </span>
        </div>
      </ng-container>
      <div class="text-center close-btn-wrapper">
        <img
          (click)="showMoveCardMobileMenu = false"
          class="close-btn"
          src="/assets/icons/btn_close.svg"
          alt="close"
        />
      </div>
    </div>
  </div>
</div>
