import { Injectable } from '@angular/core';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { IosPermissionService } from '../ios-permission/ios-permission.service';

export type Source = 'amplitude' | 'braze';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public sources: { [key: string]: Source } = {
    amplitude: 'amplitude',
    braze: 'braze',
  };

  private trackEventActions = {
    amplitude: 'trackEvent',
    braze: 'trackEvent',
  };

  private identifyUserActions = {
    amplitude: 'updateIdentity',
    braze: 'identifyUser',
  };

  private initializeActions = {
    amplitude: 'init',
    braze: 'init',
  };

  constructor(
    private amplitude: AmplitudeService,
    private braze: BrazeService,
    private iosPermission: IosPermissionService
  ) {}

  public initAnalytics(options?: {
    sources?: Source[];
    identify?: boolean;
  }): void {
    const sourcesToSet = options?.sources || Object.values(this.sources);
    sourcesToSet.forEach((source) => {
      const action = this.initializeActions[source];
      this[source]?.[action]();
      if (options?.identify) {
        this.identifyUser([source]);
      }
    });
  }

  public identifyUser(sources?: Source[]): void {
    const sourcesToSet = sources || Object.values(this.sources);
    sourcesToSet.forEach((source) => {
      this[source]?.[this.identifyUserActions[source]]();
    });
  }

  public trackEvent(eventData: {
    eventName: string;
    sources?: Source[];
    eventProperties?: unknown;
  }): void {
    if (!this.iosPermission.canTrackEvents()) return;

    this.executeTrackEvent(
      eventData.sources || Object.values(this.sources),
      eventData.eventName,
      eventData.eventProperties
    );
  }

  public logoutOrReset(): void {
    this.amplitude.logout();
    this.braze.logout();
  }

  private executeTrackEvent(
    sources: Source[],
    eventName: string,
    eventProperties = {}
  ): void {
    sources.forEach((source) => {
      try {
        this[source]?.[this.trackEventActions[source]](
          eventName,
          eventProperties
        );
      } catch (error) {
        console.warn(`Analytics trackEvent failed ${error}`);
      }
    });
  }
}
