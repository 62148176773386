import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

import {
  Company,
  ConvertToKeyValue,
  DynamicPropertyRef,
  Feature,
  ICustomProperty,
  IEquipmentNote,
  IInternalVendor,
  IVertical,
  ValidateAllFormFields,
} from '@tacliatech/types';

import {
  AssetService,
  FeatureService,
  InternalVendorService,
  StorageService,
  UserService,
  VerticalService,
} from '@web-frontend/shared/services';

import {
  EmailCustomValidator,
  FormatEmailValidator,
} from '@web-frontend/shared/validators/email';
import { RemoveEmpty } from '@web-frontend/shared/utils';
import { FilesUploadService } from '@web-frontend/shared/services/file-upload';
import { ModalInfoComponent } from '@web-frontend/shared/components/modal-info';
import { ImagePipe } from '@web-frontend/shared/pipes/image/image.pipe';
import { InfoService } from '@web-frontend/shared/components/info/info.service';
import { FilterItems } from '@web-frontend/shared/components/filter';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';

import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';

@Component({
  selector: 'roma-add-internal-vendor',
  templateUrl: './add-internal-vendor.component.html',
  styleUrls: ['./add-internal-vendor.component.scss'],
})
export class AddInternalVendorComponent implements OnInit, AfterViewInit {
  @ViewChild('verticals')
  verticalSelect: NgSelectComponent;

  @ViewChild('coverage')
  coverageSelect: NgSelectComponent;

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  form = this.fb.group({
    name: new FormControl(null, Validators.required),
    fiscalName: new FormControl(null),
    contactName: new FormControl(null),
    email: new FormControl(null),
    verticals: new FormArray([]),
    coverage: new FormArray([]),
    urgency: new FormControl(null),
    owner: new FormControl(StorageService.CompanyId),
    phone: '',
    phones: [[]],
    hasBeenInvited: new FormControl(false),
    idUsers: [[]],
    idCreatedBy: [StorageService.UserId],
    files: [[]],
    notes: [[]],
    customProperties: [[]],
  });

  markInvitation = false;
  dataModal: IInternalVendor;
  hasPublicProfile = false;
  myphones: any;
  typePropertyRef = DynamicPropertyRef;
  disableSubmit = false;

  verticals$ = this.verticalService.verticals$;

  cities: Array<{ id: string; nm: string }>;
  cityList: Array<{ id: string; nm: string }> = [];
  owners: FilterItems = [];
  users: FilterItems = [];

  loadingCities = false;
  private deleteImagesSubject = new Subject<boolean>();
  deleteImages$ = this.deleteImagesSubject.asObservable();

  private paramsCached: IInternalVendor;
  private preRequestImages = new Set<File>();
  private sub$ = new Subscription();
  isLoading = false;
  customProperties: ICustomProperty[] = [];
  initChildForm = false;
  parentFormSubmit = false;
  invalidEditCustomFields = false;
  idModuleRef = Company.IdModule;
  featureRef = Feature;

  constructor(
    private fb: FormBuilder,
    private i18n: TranslateService,
    private verticalService: VerticalService,
    private infoService: InfoService,
    private internalVendorService: InternalVendorService,
    private changeDetectionRef: ChangeDetectorRef,
    private featureService: FeatureService,
    private dialogRef: MatDialogRef<AddInternalVendorComponent>,
    private assetService: AssetService,
    private filesUploadService: FilesUploadService,
    private modalService: MatDialog,
    private amplitudeService: AmplitudeService,
    private userService: UserService,

    @Inject(MAT_DIALOG_DATA) private data: IInternalVendor,
    private toastService: ToastService,
    private brazeService: BrazeService,
    private dialog: MatDialog
  ) {
    this.myphones = [];
  }

  initialForm: any = {};
  get haveChanges() {
    return Object.entries(this.form.value).some(([key, value]) => {
      if (
        Array.isArray(value) &&
        value.length == 0 &&
        Array.isArray(this.initialForm[key]) &&
        this.initialForm[key].length == 0
      ) {
        return false;
      }
      return value !== this.initialForm[key];
    });
  }

  initialChargeEvent() {
    this.initialForm = { ...this.form.value };
  }

  get controls() {
    return this.form.controls;
  }

  get params() {
    return this.data;
  }

  ngOnInit(): void {
    this.patchParams();
    this.getCities();
    this.resolveOwners();
    this.watchChangeForm();
    this.dialogRef.disableClose = true;
    this.initialChargeEvent();
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  async ngAfterViewInit() {
    await this.resolveEdit();
  }

  private patchParams() {
    this.paramsCached = this.data;
  }

  private resolveOwners() {
    this.sub$.add(
      // this.assetService.findUsersByAssetsShared()
      this.userService.findAllUsersByCompany_SELECT().subscribe((res) => {
        this.owners = ConvertToKeyValue(res, 'name', '_id');
        this.users = ConvertToKeyValue(res, 'name', '_id');
        this.draw();
      })
    );
  }

  changeVerticals(verticals: IVertical[]) {
    const arrayControl = this.form.get('verticals') as FormArray;

    arrayControl.clear();

    for (const vertical of verticals) {
      arrayControl.push(
        new FormControl(vertical?._id ? vertical?._id : vertical)
      );
    }
  }

  changeCoverage(coverages: any[]) {
    const arrayControl = this.form.get('coverage') as FormArray;

    arrayControl.clear();

    for (const cover of coverages) {
      arrayControl.push(new FormControl(cover?.nm ? cover?.nm : cover));
    }
  }

  resolveCoverages(coverages: any[]) {
    const arrayControl = this.form.get('coverage') as FormArray;

    arrayControl.clear();

    for (const cover of coverages) {
      arrayControl.push(new FormControl(cover));
    }
  }

  changeMarkInvitation(checked: boolean, ignoreEmail: string[] = []) {
    const control = this.form.get('email');

    control.clearAsyncValidators();
    control.clearValidators();

    if (checked) {
      control.setValidators([Validators.required, FormatEmailValidator()]);

      control.setAsyncValidators([
        EmailCustomValidator.checkIfIsRepeat(this.featureService, ignoreEmail),
      ]);
    }

    control.updateValueAndValidity();

    this.form.markAllAsTouched();

    this.form.patchValue({
      hasBeenInvited: checked,
    });

    this.draw();
  }

  async submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();

    if (isValid) {
      this.isLoading = true;
      await this.prepareImages();
      const value = this.form.value;
      const obs$ =
        this.type === 'ADD'
          ? this.internalVendorService.create(value)
          : this.internalVendorService.updateOne(
              this.data?._id,
              this.form.value
            );

      let eventData;
      if (this.type === 'ADD') {
        eventData = {
          event: 'vendor_create',
        };
      }
      this.sub$.add(
        obs$.subscribe(
          (res) => {
            this.isLoading = false;
            this.parentFormSubmit = false;
            this.dialogRef.close(res);

            const message =
              this.type === 'ADD'
                ? 'internalVendor.create.success'
                : 'internalVendor.edit.success';

            this.toastService.show({
              text: this.i18n.instant(message),
              type: 'success',
              msDuration: 4000,
            });
            this.amplitudeService.sendEvent(eventData);
            this.brazeService.sendEvent(eventData);
          },
          () => {
            this.parentFormSubmit = false;
            this.isLoading = false;
            this.toastService.show({
              text: this.i18n.instant('activity.error'),
              type: 'error',
              msDuration: 4000,
            });
          }
        )
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  addImages(evt: File) {
    this.preRequestImages.add(evt);

    if (this.type === 'EDIT') {
      this.updateImage();
      this.draw();
    }
  }

  async deleteImages(evt: { type: string; img: string }) {
    const imagesMapped = this.paramsCached?.files?.filter(
      (file) => file !== evt.img
    );

    this.paramsCached = {
      ...this.paramsCached,
      files: imagesMapped,
    };

    this.form.patchValue({
      files: imagesMapped,
    });

    this.draw();

    try {
      const res = await this.internalVendorService
        .updateOne(this.data._id, { files: imagesMapped })
        .toPromise();

      // this.createEquipmentService.emitRefresh(res);

      this.toastService.show({
        text: this.i18n.instant('equipments.update.successDeleteImages'),
        type: 'success',
        msDuration: 4000,
      });

      await this.filesUploadService
        .deleteFile('internal-vendor', evt.img)
        .toPromise();
    } catch (err) {}
  }

  openImages({ type, img }: { type: string; img: string }) {
    const split = img.split('.');
    const ext = split[split.length - 1];

    if (ext !== 'pdf') {
      const modalRef = this.modalService.open(ModalInfoComponent);

      (modalRef.componentInstance as ModalInfoComponent).images = [
        { type, img },
      ];

      (modalRef.componentInstance as ModalInfoComponent).title = 'Vista Previa';
      (modalRef.componentInstance as ModalInfoComponent).type = 'image';
    } else {
      const filterPipe = new ImageFirebasePipe();
      const imagePath = filterPipe.transform(img, type);

      window.open(imagePath, '_blank');
    }
  }

  addingNotes(evt: IEquipmentNote[]) {
    this.form.patchValue({
      notes: [...evt],
    });
  }

  private async updateImage() {
    await this.prepareImages();

    this.draw();

    const value = RemoveEmpty(this.form.value);

    this.sub$.add(
      this.internalVendorService
        .updateOne(this.data?._id, {
          ...value,
        })
        .subscribe(() => {
          this.draw();
        })
    );
  }

  private async prepareImages() {
    if (this.preRequestImages.size) {
      const files = [...this.preRequestImages.values()];

      const res = await this.filesUploadService
        .uploadFiles('internal-vendor', files)
        .toPromise();

      const fileNames = res.map((element) => element.filename);

      const lastNames = this.form.get('files').value;

      this.preRequestImages.clear();
      this.form.patchValue({ files: [...lastNames, ...fileNames] });
      this.draw();
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  private selectVerticals(verticals: IVertical[]) {
    for (const vertical of verticals) {
      this.verticalSelect.select({
        value: vertical._id,
        label: vertical.name,
      });
    }

    this.draw();
  }

  updateImages(images: any) {
    const result = this.filesUploadService.convertImages(images);
    this.form.patchValue({
      files: result,
    });

    if (this.params) {
      this.params.files = result;
    }

    this.draw();
  }

  private selectCoverages(coverages: any[]) {
    for (const coverage of coverages) {
      this.coverageSelect.select({
        value: coverage,
        label: coverage,
      });
    }

    this.draw();
  }

  getCities() {
    this.loadingCities = true;
    this.draw();

    this.assetService
      .getListCity()
      .pipe(
        finalize(() => {
          this.loadingCities = false;
          this.draw();
        })
      )
      .subscribe((resp) => {
        this.cities = resp;
        this.cityList = resp;
      });
  }

  findCity({ target: { value } }) {
    const filteredProducts = this.cities.filter((filtered) =>
      filtered.nm.toLowerCase().includes(value.toLowerCase())
    );

    this.cityList = filteredProducts;
  }

  getImgSource(indexImage: number) {
    try {
      if (this.data?.files?.length && this.data?.files[indexImage]) {
        return [this.data?.files[indexImage]];
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  private async resolveEdit() {
    if (this.data) {
      this.type = 'EDIT';

      this.dataModal = this.data;

      this.form.patchValue({
        ...this.data,
        verticals: [],
      });

      this.markInvitation = this.data?.hasBeenInvited;

      this.changeMarkInvitation(this.markInvitation, [this.data?.email]);
      this.selectVerticals(this.data?.verticals || []);
      this.changeVerticals(this.data?.verticals || []);

      this.selectCoverages(this.data?.coverage || []);
      this.resolveCoverages(this.data?.coverage || []);

      this.draw();

      this.hasPublicProfile = await this.internalVendorService
        .hasPublicProfile({ email: this.data?.email })
        .toPromise();

      this.draw();
    }
  }

  private watchChangeForm() {
    this.sub$.add(this.form.valueChanges.subscribe());
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  changePhones(phones: string[]) {
    this.form.patchValue({
      phones: phones,
    });

    this.draw();
  }

  info(type: string) {
    this.infoService.open(type);
  }

  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setcustomProperties();
  }

  setcustomProperties() {
    let i = 0;
    for (i = 0; i < this.customProperties.length; i++) {
      if (this.customProperties[i].isRequired) {
        this.form.addControl(
          this.customProperties[i]._id,
          new FormControl('', Validators.required)
        );
      }
    }
    this.initChildForm = true;
    this.parentFormSubmit = false;
  }

  fetchPropertyValue(propertyId) {
    this.form.controls[propertyId].setValue(propertyId);
  }

  propertiesRequired(value) {
    this.invalidEditCustomFields = value;
  }
}
