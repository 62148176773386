import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from '@tacliatech/types';
import { PlanService } from '../plans/plans.service';

@Component({
  selector: 'roma-pro-warning-info',
  templateUrl: './pro-warning-info.component.html',
  styleUrls: ['./pro-warning-info.component.scss'],
})
export class ProWarningInfoComponent implements OnInit {
  @Input() iconUrl = 'assets/icons/pro-disclaimer-star.svg';
  @Input() warning = false;
  @Input() message = 'pro.buyModal.tableColumn1';
  @Input() button = 'pro.disclaimer.button';

  constructor(
    private i18n: TranslateService,
    private planService: PlanService
  ) {}

  ngOnInit(): void {}

  getTranslation(value: string): string {
    const message = this.i18n.instant(value) || '';
    return message;
  }

  tryPro(center = true): void {
    const feature = Feature.Deal.DownloadExcel;
    this.planService.openPlansModal({
      data: { feature },
    });
  }
}
