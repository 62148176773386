<div class="unsaved-changes-container">
  <div class="close-button-wrapper">
    <button
      class="close-button"
      aria-label="close-form"
      color="close"
      data-testid="button-form-close"
      (click)="onClickOutside()"
    >
      <img [src]="'assets/icons/gl_close.svg'" />
    </button>
  </div>
  <div class="content-wrapper">
    <div class="icon-container">
      <div class="icon-background"></div>
      <span class="icon icon-circle-help icon-overlay"></span>
    </div>
    <div class="text-wrapper">
      <span class="main-text">
        {{ 'unsavedChangesPrompt.title' | translate }}
      </span>
      <p class="subtext">
        {{ 'unsavedChangesPrompt.text' | translate }}
      </p>
    </div>
  </div>
  <div class="button-group">
    <button
      type="button"
      class="cancel-button"
      data-testid="prompt-confirmation-modal-confirm-modal-cancel"
      id="prompt-confirmation-modal-confirm-modal-cancel"
      (click)="onContinueWithoutSave()"
    >
      <span class="button-text">{{
        'unsavedChangesPrompt.btnCancel' | translate
      }}</span>
    </button>
    <button
      type="button"
      class="confirm-button"
      data-testid="prompt-confirmation-modal-confirm-modal-confirm"
      id="prompt-confirmation-modal-confirm-modal-confirm"
      (click)="onSaveAndContinue()"
    >
      <span class="button-text">
        {{ 'unsavedChangesPrompt.btnConfirm' | translate }}
      </span>
    </button>
  </div>
</div>
