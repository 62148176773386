import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@web-frontend/shared/services';
import { authRoutes } from '../../../core/auth/auth-routing.module';

@Injectable({
  providedIn: 'root',
})
export class ClickLogoutService {
  constructor(
    private router: Router,
    private readonly authService: AuthService
  ) {}

  public logout(
    { redirect }: { redirect: boolean } = { redirect: true }
  ): void {
    this.authService.logout();
    if (redirect) {
      this.router.navigateByUrl(authRoutes.login);
    }
  }
}
