import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MicrofrontendComponent } from '../microfrontend.component';
import { AppName, AppUrl } from '../microfrontend.tokens';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import {
  AnalyticsService,
  Source,
} from '../../shared/services/analytics/analytics.service';
import AmplitudeEvents from '../../../types/amplitude.enum';
import { ModalDeleteAccountComponent } from '../../shared/components/modal-delete-account/modal-delete-account.component';
import { BudgetService } from '../../shared/services/budgets';
import { BudgetLogoService } from '../../shared/services/budget-logo';
import { StorageService } from '../../shared/services';
import { CreateBudgetPaymentMethodService } from '../../shared/components/create-create-budget-payment';
import { Subscription } from 'rxjs';
import { ISequence, PaymentInfoBudget } from '../../../types-legacy';
import { CreateSequenceComponent } from '../../shared/components/create-sequence/create-sequence.component';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { SequenceService } from '../../shared/services/sequence';
import { Router } from '@angular/router';
import { UnsaveGuard } from '../../shared/components/unsaved-changes/unsaved-changes.guard';
import { environment } from '../../../environments';
import { PlatformService } from '../../shared/components/platform-disable-content/platform.service';

export enum EventType {
  goBack = 'goBack',
  sendAnalyticsEvent = 'sendAnalyticsEvent',
  openModalDeleteAccount = 'openModalDeleteAccount',
  openModalUploadLogo = 'openModalUploadLogo',

  newPaymentMethod = 'newPaymentMethod',
  editPaymentMethod = 'editPaymentMethod',
  deletePaymentMethod = 'deletePaymentMethod',
  refreshPaymentMethods = 'refreshPaymentMethods',

  newSerialNumber = 'newSerialNumber',
  editSerialNumber = 'editSerialNumber',
  deleteSerialNumber = 'deleteSerialNumber',
  refreshSerialNumbers = 'refreshSerialNumbers',

  preferencesUpdated = 'preferencesUpdated',
  logoUpdated = 'logoUpdated',

  formChanged = 'formChanged',
}

type EventPayload = { resource: string } | { event: string } | { path: string };

type SendEventPayload = { id: string };

type EventData = {
  type: EventType;
  payload?: EventPayload;
};

type SendEventData = {
  type: EventType;
  payload?: SendEventPayload;
};

@Component({
  selector: 'roma-mf-settings',
  template: `<mf-settings
    [attr.ismodal]="isModal"
    [attr.ismobile]="isMobile"
  ></mf-settings>`,
  styles: [],
})
export class MfSettingsComponent extends MicrofrontendComponent<
  EventData,
  SendEventData
> {
  @Input() appName = 'mf-settings';
  @Input() appUrl: string = localStorage.getItem('mf-settings');
  @Input() isModal = false;

  isMobile = false;

  private sub$ = new Subscription();
  FIREBASE_BASE_URL = environment.firebaseConfig.storageUrl;

  constructor(
    @Inject(AppName) appName: string,
    @Inject(AppUrl) appUrl: string,
    private _httpClient: HttpClient,
    private _el: ElementRef,
    private readonly dialog: MatDialog,
    private readonly analyticsService: AnalyticsService,
    private readonly budgetService: BudgetService,
    private readonly budgetLogoService: BudgetLogoService,
    private readonly createBudgetPaymentMethodService: CreateBudgetPaymentMethodService,
    private readonly sequenceService: SequenceService,
    private readonly router: Router,
    private readonly unsavedChanges: UnsaveGuard,
    private readonly platformService: PlatformService
  ) {
    super(_httpClient, _el);
    this.handleCustomEvent = this.handleCustomEvent.bind(this);
  }

  onInit(): void {
    this.isMobile =
      this.platformService.isAndroid() || this.platformService.isIos();
    this.unsavedChanges.eventName$.next(this.eventName);
  }

  onDestroy(): void {
    this.sub$.unsubscribe();
  }

  handleFormChanged(payload: { isDirty: boolean }): void {
    this.unsavedChanges.unsavedChanges$.next(payload.isDirty);
  }

  handleCustomEvent(event: CustomEvent<EventData>): void {
    const { type, payload } = event.detail;

    const eventsActionsMap = {
      [EventType.goBack]: this.handleGoBack,
      [EventType.sendAnalyticsEvent]: this.handleAnalyticsEvent,
      [EventType.openModalDeleteAccount]: this.handleModalDeleteAccount,
      [EventType.preferencesUpdated]: this.handlePreferencesUpdated,
      [EventType.openModalUploadLogo]: this.handleModalUploadLogo,

      [EventType.newPaymentMethod]: this.handleNewPaymentMethod,
      [EventType.editPaymentMethod]: this.handleEditPaymentMethod,
      [EventType.deletePaymentMethod]: this.handleDeletePaymentMethod,

      [EventType.newSerialNumber]: this.handleNewSerialNumber,
      [EventType.editSerialNumber]: this.handleEditSerialNumber,
      [EventType.deleteSerialNumber]: this.handleDeleteSerialNumber,

      [EventType.formChanged]: this.handleFormChanged,
    };

    const action = eventsActionsMap[type];
    if (action) {
      action.call(this, payload);
    }
  }

  async handleNewSerialNumber(): Promise<void> {
    this.analyticsService.trackEvent({
      eventName: AmplitudeEvents.settings_addSerie_start,
      sources: ['amplitude'],
    });

    //open component add/edit
    const dialog = this.dialog.open(CreateSequenceComponent, {
      data: null,
    });
    dialog.afterClosed().subscribe(() => {
      this.publish({
        type: EventType.refreshSerialNumbers,
      });
    });
  }

  async handleEditSerialNumber(serialNumber: ISequence): Promise<void> {
    const dialog = this.dialog.open(CreateSequenceComponent, {
      data: serialNumber,
    });

    dialog.afterClosed().subscribe(() => {
      this.publish({
        type: EventType.refreshSerialNumbers,
      });
    });
  }

  async handleDeleteSerialNumber(serialNumber: ISequence): Promise<void> {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        confirmLabel: 'general.confirmDelete',
        subtitle: 'deleteModal.body_final',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'EXECUTE') {
        this.sub$.add(
          this.sequenceService
            .deleteSequence(serialNumber.id)
            .subscribe((res) => {
              this.publish({
                type: EventType.refreshSerialNumbers,
              });
            })
        );
      }
    });
  }

  async handleNewPaymentMethod(): Promise<void> {
    this.analyticsService.trackEvent({
      eventName: AmplitudeEvents.settingsAddPayMethodStart,
    });
    this.sub$.add(
      this.createBudgetPaymentMethodService.open().subscribe(() => {
        this.refreshPaymentMethods();
      })
    );
  }

  async handleEditPaymentMethod(payment: PaymentInfoBudget): Promise<void> {
    this.sub$.add(
      this.createBudgetPaymentMethodService
        .open({ data: { type: 'EDIT', data: payment } })
        .subscribe(() => {
          this.refreshPaymentMethods();
        })
    );
  }

  async handleDeletePaymentMethod(payment: PaymentInfoBudget): Promise<void> {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        confirmLabel: 'general.confirmDelete',
        subtitle: 'deleteModal.body_final',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'EXECUTE') {
        this.sub$.add(
          this.budgetService.deletaPaymentMethod(payment._id).subscribe(() => {
            this.refreshPaymentMethods();
          })
        );
      }
    });
  }

  refreshPaymentMethods(): void {
    this.publish({
      type: EventType.refreshPaymentMethods,
    });
  }

  async handleModalUploadLogo(): Promise<void> {
    try {
      let isDone = false;
      const companyId = StorageService.CompanyId;
      const budget = await this.budgetService
        .findSettingsByCompany(companyId)
        .toPromise();

      const { logo, _id } = budget;
      if (logo) {
        isDone = await this.budgetLogoService.openWithImage({
          src: `${this.FIREBASE_BASE_URL}/uploads%2Fbudget-logo%2F${logo}`,
          id: _id,
          rawLogo: logo,
          settings: budget,
        });
      } else {
        isDone = await this.budgetLogoService.openWithoutImage(_id);
      }

      if (isDone) {
        this.publish({ type: EventType.logoUpdated });
      }
    } catch (e) {
      console.error('Error on handleModalUploadLogo', e);
    }
  }

  async handleAnalyticsEvent({
    event,
    sources,
  }: {
    event: string;
    sources: Source[];
  }): Promise<void> {
    this.analyticsService.trackEvent({
      sources: sources,
      eventName: event,
    });
  }

  async handlePreferencesUpdated() {
    return this.budgetService.init();
  }

  handleModalDeleteAccount(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.settingsDeleteAccount,
    });
    this.dialog.open(ModalDeleteAccountComponent, {
      maxWidth: '432px',
    });
  }

  handleGoBack(): void {
    this.router.navigate(['/admin/settings/general']);
  }
}
