import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ClockHourView,
  CurrentDateFormat,
} from '../../../core/admin/clock-hour/clock-hour.type';

@Component({
  selector: 'roma-monthly-weekly-filter',
  templateUrl: './monthly-weekly-filter.component.html',
  styleUrls: ['./monthly-weekly-filter.component.scss'],
})
export class MonthlyWeeklyFilterComponent implements OnInit {
  @Output() changePeriodRange: EventEmitter<{
    startDate: string;
    endDate: string;
    view: ClockHourView;
  }> = new EventEmitter();
  @Input() defaultValues?: {
    from: string;
    to: string;
    view: ClockHourView;
  };
  @Input() showPeriodSwitch = true;
  @Input() dateFormat: CurrentDateFormat = CurrentDateFormat.Short;
  @Input() clickOnCurrentPeriod = false;

  viewPeriod: ClockHourView = ClockHourView.Week;
  rangeSelected: { startDate: string; endDate: string };

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.defaultValues) {
      this.rangeSelected = {
        startDate: this.defaultValues.from,
        endDate: this.defaultValues.to,
      };
      this.viewPeriod = this.defaultValues.view;
      this.draw();
    }
  }

  changeClockHourView(view: ClockHourView) {
    this.viewPeriod = view;
    this.draw();
  }

  periodChange(evt: { startDate: string; endDate: string }) {
    this.changePeriodRange.emit({ ...evt, view: this.viewPeriod });
  }

  draw() {
    this.cd.detectChanges();
  }
}
