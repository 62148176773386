import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MfEmployeeCalendarComponent } from './mf-employee-calendar.component';
import { AppName, AppUrl } from '../microfrontend.tokens';

const microfrontendKey = 'mf-employee-calendar';

@NgModule({
  imports: [CommonModule],
  declarations: [MfEmployeeCalendarComponent],
  exports: [MfEmployeeCalendarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AppName,
      useValue: microfrontendKey,
    },
    {
      provide: AppUrl,
      useFactory: () => {
        return localStorage.getItem(microfrontendKey);
      },
    },
  ],
})
export class MfEmployeeCalendarModule {}
