import { Injectable } from '@angular/core';
import { Feature } from '@tacliatech/types';
import { SandboxService, StorageService } from '@web-frontend/shared/services';
import { filter, take } from 'rxjs/operators';

import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { PlanService } from './plans/plans.service';

@Injectable({
  providedIn: 'root',
})
export class ProFeatureService {
  constructor(
    private sandBoxService: SandboxService,
    private toastService: ToastService,
    private planService: PlanService
  ) {}

  open(feature: Feature.Purchasable) {
    this.sandBoxService.screenBusChannel$
      .pipe(
        filter((res) => !!res),
        take(1)
      )
      .subscribe((res) => {
        this.openWeb(feature);
      });
  }

  openUser(erroText: string) {
    this.toastService.show({
      text: erroText,
      type: 'error',
      msDuration: 5000,
    });
  }

  private openWeb(feature: Feature.Purchasable) {
    this.planService.openPlansModal({ data: { feature } });
  }

  get userLanguage(): string {
    return StorageService.GetItem('USER_LANG');
  }
}
