// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { FreeLimitType } from '@tacliatech/types';

export const HOST = 'app.taclia.com';
const API = {
  type: 'https',
  host: 'api.taclia.com',
  port: 80,
  prefix: 'api',

  get urlPlain() {
    return `${this.type}://${this.host}/`;
  },

  get url() {
    return `${this.type}://${this.host}/${this.prefix}`;
  },

  get socketIoConfig() {
    return {
      url: this.urlPlain,
      options: {},
    };
  },
};
const FIREBASE = {
  apiKey: 'AIzaSyDxG9cTI1ocQB-xDcyxelWr89Xh-3a5FNQ',
  authDomain: 'taclia.firebaseapp.com',
  databaseURL: 'https://taclia.firebaseio.com',
  projectId: 'taclia',
  storageBucket: 'taclia.appspot.com',
  messagingSenderId: '426363966340',
  appId: '1:426363966340:web:d3451f2d7fc645466bc3d1',
  measurementId: 'G-XVQTZ1EFQ2',
  storageUrl:
    'https://firebasestorage.googleapis.com/v0/b/taclia.appspot.com/o',
};

const WEBFRONT = {
  type: 'http',
  host: HOST,
  port: 80,

  get urlPlain() {
    return `${this.type}://${this.host}:${this.port}/`;
  },
};

const OPERACIONES = {
  type: 'http',
  host: HOST,
  port: 81,

  get urlPlain() {
    return `${this.type}://${this.host}:${this.port}/`;
  },
};

const AMPLITUD_SERVICE = {
  key: 'eb2e097ccc85b71ae0c3ccde7ebe5b59',
};

const STRIPE = {
  public_key: 'pk_live_hSa4bEAaQPHW0tBeqyQg0hly',
};

const FREE_LIMITS = {
  [FreeLimitType.AllowedTypes.File]: 2,
  [FreeLimitType.AllowedTypes.CustomProperty]: 2,
};
const BRAZE_ENVIRONMENT = {
  key: '69c7f872-a26c-4e88-be4a-7cddcda241a1',
  url: 'sdk.fra-02.braze.eu',
  enabled: true,
  debug: false,
};
const INTERCOM_ENVIRONMENT = {
  appId: 'fpkrj0w5',
};
const GTM = {
  auth: 'gtm_auth=5XHmTb0FEKqUQwV35nLIkg&gtm_preview=env-1&gtm_cookies_win=x',
};

export const environment = {
  production: true,
  beta: false,
  api: API,
  apiKeyMapWeb: 'AIzaSyBJoW5Yn4hlk3oQRGwr1IVZ9N-xCMwr5wg',
  apiKeyMapAndroid: 'AIzaSyDxG9cTI1ocQB-xDcyxelWr89Xh-3a5FNQ',
  webfront: WEBFRONT,
  operaciones: OPERACIONES,
  firebaseConfig: FIREBASE,
  amplitudeConfig: AMPLITUD_SERVICE,
  stripeConfig: STRIPE,
  chargebeeSite: 'taclia',
  cloudFunctionCreatePartOfServicePDF:
    'https://create-part-of-service-document-production-7n5rxzjjia-ew.a.run.app',
  cloudFunctionCreateSalesPDF:
    'https://europe-west1-taclia.cloudfunctions.net/create-budget-template-productions',
  cloudFunctionCreateMultipleSalesPDF:
    'https://europe-west1-taclia.cloudfunctions.net/create-budget-template-multiples-production',
  cloudFunctionDownloadSaleTicket:
    'https://europe-west1-taclia.cloudfunctions.net/create-ticket-document-prod/',
  MembershipSchema: 'ONE_PRO_PLAN',
  freeLimits: FREE_LIMITS,
  brazeConfig: BRAZE_ENVIRONMENT,
  intercomConfig: INTERCOM_ENVIRONMENT,
  recaptchaV3SiteKey: '6LccWHApAAAAAAEiNNk4iCwfpBxt5f2g_IvfCSw-',
  microfrontendImportsUrl: 'https://mf.taclia.com/mf-get-versions',
  configCatKey: 'BkjcCKDgQUmFkB6ecgE2Ug/5XU12snCQ0CRYAm0oeuSpA',
  cdn: 'https://cdn.taclia.com',
  gtm: GTM,
  dsnSentry:
    'https://4b25bc8245edd19b704f7a65a1aed71a@o4507605194440704.ingest.de.sentry.io/4507922180276304',
  documentsBucket: 'https://documents.taclia.com',
  aiAssistantUrl: 'https://ai.taclia.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
