import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BDG_PREFERENCES,
  Feature,
  FeatureUser,
  Permission,
  ClockAbsence,
} from '@tacliatech/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BudgetService } from '../budgets';
import { StorageService } from '../storage';
import { UserService } from '../users';

export interface TrialStatus {
  proPlanActivated: boolean;
  remainingDays: number;
}

export interface CheckTrialResponse {
  remainingDays: number;
  proPlanActivated: boolean;
  trial?: {
    startAt: number;
    endAt: number;
  };
  pro?: {
    startAt: number;
    endAt: number;
  };
}

interface CheckTrialCache {
  lastCheck: Date;
  response?: CheckTrialResponse;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public permissions: string[] = [];
  public currencies: any;
  public moneySymbol: string;

  private checkTrialCache: CheckTrialCache;
  private permissionStorage = new BehaviorSubject<Permission.List>([]);

  public permissions$ = this.permissionStorage
    .asObservable()
    .pipe(map((res) => res.map((el) => el.feature)));

  private trialStatusStorage = new BehaviorSubject<TrialStatus>({
    proPlanActivated: null,
    remainingDays: -1,
  });
  public trialStatus$ = this.trialStatusStorage.asObservable();

  public hasFeatureFn = (
    feature: Feature.Purchasable | FeatureUser.Purchasable
  ): Observable<boolean> =>
    this.permissions$.pipe(
      map(
        (res) =>
          res.includes(feature) ||
          res.includes(Feature.SystemPermission.AllowAll)
      )
    );

  public hasFeaturesFn = (
    features: Feature.Purchasable[]
  ): Observable<boolean> =>
    this.permissions$.pipe(
      map(
        (res) =>
          res.includes(Feature.SystemPermission.AllowAll) ||
          features.some((feature) => res.includes(feature))
      )
    );

  public getFeaturePermissionValues = (
    features: FeatureUser.Purchasable[]
  ): Observable<{ [key: string]: boolean }> =>
    this.permissions$.pipe(
      map((res) =>
        features.reduce((acc, feature) => {
          acc[feature] = res.includes(feature);
          return acc;
        }, {} as { [key: string]: boolean })
      )
    );

  constructor(
    private http: HttpClient,
    private budgetService: BudgetService,
    private userService: UserService
  ) {}

  async init(): Promise<void> {
    this.getMoneySymbol();
    await this.findAll().toPromise();
  }

  public setPermissions(permissions: Permission.List): void {
    this.permissionStorage.next([
      { feature: Feature.SystemPermission.DefaultAllow },
      ...permissions,
    ]);
  }

  public setTrialStatus(trialStatus: TrialStatus): void {
    this.trialStatusStorage.next(trialStatus);
  }
  findAll(): Observable<Permission.List> {
    return this.http.get<Permission.List>(':API_URL/permissions').pipe(
      tap((res) => {
        const defaultPermission = [
          { feature: Feature.SystemPermission.DefaultAllow },
        ];
        const permissions =
          Array.isArray(res) && res.length
            ? [...defaultPermission, ...res]
            : defaultPermission;
        this.permissions = permissions.map((item) => item.feature);
        this.permissionStorage.next(permissions);
      })
    );
  }

  public allowedFeature(
    permission: Feature.Purchasable | FeatureUser.Purchasable
  ): boolean {
    if (
      permission === FeatureUser.Bill.show &&
      this.userService.validateCountryMX()
    ) {
      return false;
    }
    return (
      this.permissions.includes(permission) ||
      this.permissions.includes(Feature.SystemPermission.AllowAll)
    );
  }

  absencePermissionsByStatus(
    showReject: boolean,
    showApproval: boolean,
    showEdit: boolean,
    showDelete: boolean,
    userIsAdmin: boolean,
    absenceDate: string,
    isTeamViewAbsences: boolean
  ): {
    [key: string]: {
      showReject: boolean;
      showApproval: boolean;
      showEdit: boolean;
      showDelete: boolean;
    };
  } {
    const absenceHasNotOcurred = absenceDate > new Date().toISOString();

    return {
      [ClockAbsence.Status.Pending]: {
        showReject: showReject && (isTeamViewAbsences || absenceHasNotOcurred),
        showApproval:
          showApproval && (isTeamViewAbsences || absenceHasNotOcurred),
        showEdit: userIsAdmin || (showEdit && absenceHasNotOcurred),
        showDelete: userIsAdmin || (showDelete && absenceHasNotOcurred),
      },
      [ClockAbsence.Status.Approved]: {
        showReject: false,
        showApproval: false,
        showEdit: false,
        showDelete: userIsAdmin,
      },
      [ClockAbsence.Status.Rejected]: {
        showReject: false,
        showApproval: false,
        showEdit: false,
        showDelete: userIsAdmin,
      },
    };
  }

  async getTeamViewAbsencePermissions(
    userIsAdmin: boolean,
    absenceDate: string
  ): Promise<{
    [key: string]: {
      showReject: boolean;
      showApproval: boolean;
      showEdit: boolean;
      showDelete: boolean;
    };
  }> {
    let response = {};
    this.getFeaturePermissionValues([
      FeatureUser.ClockHourAbsenceCompany.approve,
      FeatureUser.ClockHourAbsenceCompany.show,
    ]).subscribe((canUser) => {
      response = this.absencePermissionsByStatus(
        canUser[FeatureUser.ClockHourAbsenceCompany.approve],
        canUser[FeatureUser.ClockHourAbsenceCompany.approve],
        userIsAdmin,
        userIsAdmin,
        userIsAdmin,
        absenceDate,
        true
      );
    });
    return response;
  }
  async getMyViewAbsencePermissions(
    userIsAdmin: boolean,
    absenceDate: string
  ): Promise<{
    [key: string]: {
      showReject: boolean;
      showApproval: boolean;
      showEdit: boolean;
      showDelete: boolean;
    };
  }> {
    let response = {};
    this.getFeaturePermissionValues([
      FeatureUser.ClockHourAbsence.update,
      FeatureUser.ClockHourAbsence.delete,
    ]).subscribe((canUser) => {
      response = this.absencePermissionsByStatus(
        userIsAdmin,
        userIsAdmin,
        canUser[FeatureUser.ClockHourAbsence.update],
        canUser[FeatureUser.ClockHourAbsence.delete],
        userIsAdmin,
        absenceDate,
        false
      );
    });
    return response;
  }

  resetCheckTrialCache(): void {
    this.checkTrialCache = null;
    this.checkTrialRequest = null;
  }

  private checkTrialRequest: Promise<CheckTrialResponse> | null = null;
  async checkTrialSubscription(
    companyId: string,
    rootModule: string,
    checkChargebee = false
  ): Promise<CheckTrialResponse> {
    if (
      this.checkTrialCache?.lastCheck >
        new Date(new Date().getTime() - 20 * 60000) &&
      !checkChargebee
    ) {
      return this.checkTrialCache.response;
    }

    if (this.checkTrialRequest) {
      return this.checkTrialRequest;
    }

    this.checkTrialRequest = (async () => {
      const url = `:API_URL/subscription-manager/subscriptions/check-trial/${companyId}?rootModule=${rootModule}&checkChargebee=${checkChargebee}`;

      try {
        const response = await this.http
          .get<CheckTrialResponse>(url)
          .toPromise();
        this.checkTrialCache = {
          lastCheck: new Date(),
          response,
        };
        return response;
      } finally {
        this.checkTrialRequest = null;
      }
    })();

    return this.checkTrialRequest;
  }

  getMoneySymbol(updated = null): void {
    this.currencies = this.budgetService.findAllCurrencys();

    if (!updated) {
      this.budgetService
        .findSettingsByCompany(StorageService.CompanyId)
        .subscribe((res) => {
          if (!res) {
            this.moneySymbol = this.currencies[0].value;
          } else {
            StorageService.SetItem(
              BDG_PREFERENCES,
              JSON.stringify(res?.budgetPreferences)
            );

            const currency = this.currencies.filter(
              (fil) => fil.id === res.budgetPreferences.currency
            );

            if (currency.length === 1) {
              this.moneySymbol = currency[0].value;
            }
          }
        });
    } else {
      const currency = this.currencies.filter(
        // @ts-ignore
        (fil) => fil.id === updated.budgetPreferences.currency
      );

      if (currency.length === 1) {
        this.moneySymbol = currency[0].value;
      }

      StorageService.SetItem(
        'budgetPreferences',
        // @ts-ignore
        JSON.stringify(updated?.budgetPreferences)
      );
    }
  }

  async getCurrency(): Promise<{ id: string; text: string; value: string }> {
    this.currencies = this.budgetService.findAllCurrencys();
    const settings = await this.budgetService
      .findSettingsByCompany(StorageService.CompanyId)
      .toPromise();

    if (!settings) {
      return this.currencies[0];
    } else {
      StorageService.SetItem(
        BDG_PREFERENCES,
        JSON.stringify(settings?.budgetPreferences)
      );

      const currency = this.currencies.filter(
        (fil) => fil.id === settings.budgetPreferences.currency
      );

      if (currency.length == 0) {
        return this.currencies[0];
      }
      return currency[0];
    }
  }
}
