import { InvoiceType } from '../../../app/invoice/types/invoice-type.type';
import { DEFAULT_DATA_ACCOUNT } from '../constants';
import { BusinessType } from '../enum';
import {
  IAdditionalInfoBudget,
  IBIllBudget,
  IBillingAddress,
  IBudgetCommonData,
  IBudgetPreferences,
  IBudgetsProFeatures,
  IBudgetsSetting,
  IBudgetT,
  IDataAccount,
  IItemBudget,
  IMessageBudget,
  IPaymentInfoBudget,
  IProform,
  ISettingInvoice,
} from '../interfaces';

export enum TypeAmplitudeBudget {
  BUDGET = 'budget',
  PROFORM = 'proform',
  BILL = 'bill',
  WAL = 'waybill',
}

export enum TypeBudget {
  BUDGET = 'BDG',
  PROFORM = 'PROFM',
  BILL = 'BILL',
  WAL = 'WAL',
  BDG = 'BDG',
}

export enum TypeTax {
  IVA = 'IVA',
  RET = 'RETENTION',
  REC = 'REC',
  IEPS = 'IEPS',
}

export enum TypePayment {
  TRANSFER = 'TRANSFER',
  CASH = 'CASH',
  PAYPAL = 'PAYPAL',
}

export enum TypeItemBudget {
  CATEGORY = 'category',
  WAYBILL = 'waybill',
}

export class Tax {
  _id?: string;
  name?: string;
  translate?: string;
  value?: number;
  type?: TypeTax;
}

export class Payment {
  _id?: string;
  name?: string;
  textShow?: string;
}

export class HeaderBudget {
  idDeal?: string;
  contact?: string;
  contact_id?: string;
  final?: any;
  numberDoc: string;
  prefix?: string;
  sequence_id?: string;
  date?: string;
  dueDate?: string;
  showPrices?: boolean;
  showSignatures?: boolean;
  constructor() {
    this.numberDoc = '0';
    this.prefix = 'XXX';
  }
}

export class ItemBudget implements IItemBudget {
  constructor(obj?) {
    if (obj) {
      obj && Object.assign(this, obj);
    }
  }

  id?: string;
  idAux?: number;
  idConcept?: string;
  concept?: string;
  description?: string;
  quantity?: number;
  price?: number;
  subtotal?: number;
  total?: number;
  taxes?: Tax[];
  discount?: number;
  unit?: string;
  type?: string;
  productKey?: string;
}

export class MessageBudget implements IMessageBudget {
  line1?: string;
  line2?: string;
  line3?: string;
}

export class TotalBudget {
  total?: number;
  subtotal?: number;
  ivatotal?: number;
  discount?: number;
}

export class PaymentInfoBudget implements IPaymentInfoBudget {
  _id?: string;
  name: string;
  textShow: string;
  icon?: string;
  idOwner?: string;
}

export class AdditionalInfoBudget implements IAdditionalInfoBudget {
  category_id?: string;
  category_description?: string;
  project_id?: string;
  project_description?: string;
}

export class BudgetsProFeatures implements IBudgetsProFeatures {
  constructor() {
    this.hasDiscount = false;
  }
  hasDiscount?: boolean;
}

export class SettingInvoice implements ISettingInvoice {
  prefixNumber?: string; //deprecated by sequences
  starterNumber?: number; //deprecated by sequences
  rateDefault?: string;
  taxDefault?: any;
  typeTaxDefault?: any;
  templateEmail?: string;
  langDefault?: string;
  defaultTaxesList?: Tax[];
}

export class BudgetCommonData implements IBudgetCommonData {
  issueAt?: string;
  id?: string;
  constructor() {
    this.header = new HeaderBudget();
    this.items = [];
    this.messages = new MessageBudget();
    this.addicionalInfo = new AdditionalInfoBudget();
    this.typePayments = [];
    this.total = 0;
    this.iva_total = 0;
    this.subtotal = 0;
    this.draft = false;
    this.deleted = false;
    this.company = null;
    this.proFeatures = new BudgetsProFeatures();
    this.discount = 0;
    this.files = [];
    this.invoiceType = null;
    this.purposeUse = null;
    this.personalization = {};
  }

  _id?: string;
  header: HeaderBudget;
  items: ItemBudget[] = [];
  messages: MessageBudget;
  addicionalInfo: AdditionalInfoBudget;
  typePayments: PaymentInfoBudget[];
  iva_total: number;
  subtotal: number;
  total: number;
  status: number;
  draft?: boolean;
  deleted: boolean;
  company: string;
  proFeatures?: BudgetsProFeatures;
  discount?: number;
  paymentLink?: {
    id: string;
    url: string;
  };
  signCompany?: string;
  signClient?: string;
  createdAt?: Date;
  files?: any[];
  invoiceType?: InvoiceType;
  purposeUse?: string;
  personalization?: {
    distribution?: string;
    color?: string;
  };
}

export class BudgetT extends BudgetCommonData implements IBudgetT {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  super() {}
}

export class Proform extends BudgetCommonData implements IProform {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  super() {}
}

export class BillBudget extends BudgetCommonData implements IBIllBudget {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  super() {}
}

export class BudgetsSetting implements IBudgetsSetting {
  constructor(dataAccount: DataAccount = DEFAULT_DATA_ACCOUNT) {
    this.data_account = new DataAccount(dataAccount);
    this.billing_addres = new BillingAddress();
    this.budgetPreferences = new BudgetPreferences();
    this.invoiceSettings = new SettingInvoice();
    this.logo = null;
  }

  _id?: string;
  data_account: DataAccount;
  billing_addres: BillingAddress;
  budgetPreferences: BudgetPreferences;
  invoiceSettings: SettingInvoice;
  logo: string;
  idOwner: string;
}

export class DataAccount implements IDataAccount {
  constructor(dataAccount: DataAccount = DEFAULT_DATA_ACCOUNT) {
    this.company_name = dataAccount.company_name;
    this.tradename = dataAccount.tradename;
    this.email = dataAccount.email;
    this.phone = dataAccount.phone;
    this.fiscalName = dataAccount.fiscalName;
    this.business_type = dataAccount.business_type;
    this.fiscalRegime = dataAccount.fiscalRegime;
  }

  company_name: string;
  tradename: string;
  email: string;
  phone: string;
  fiscalName: string;
  business_type?: BusinessType | string;
  fiscalRegime?: string;
}

export class BillingAddress implements IBillingAddress {
  constructor() {
    this.address = '';
    this.state = '';
    this.postalcode = '';
    this.country = '';
  }
  address: string;
  city: string;
  postalcode: string;
  state: string;
  country: string;
}

export class BudgetPreferences implements IBudgetPreferences {
  constructor() {
    this.currency = 1;
    this.decimals = 2;
    this.language = 1;
    this.zone = 'Europe/Madrid';
    this.number_format = 1;
    this.date_format = 'dd/MM/yyyy';
    this.day = 1;
    this.message = '';
  }
  currency: number;
  decimals: number;
  language: number;
  zone: string;
  number_format: number;
  date_format: string;
  message: string;
  day: number;
}
