/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as braze from '@braze/web-sdk';
import { StorageService } from '@web-frontend/shared/services';
import { CompanyService } from '@web-frontend/shared/services/company';
import { environment } from '@web-frontend/environments';
import { BrazeEvent } from '@web-frontend/shared/services/braze/braze-event.model';
import { CheckTrialResponse } from '@web-frontend/shared/services/permissions';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { HttpClient } from '@angular/common/http';
import {
  BrazeSubscriptionPlan,
  BrazeUserAttributes,
  COMPANY_ID_KEY,
  Prices,
} from '@tacliatech/types';
import { IosPermissionService } from '../ios-permission/ios-permission.service';

@Injectable({
  providedIn: 'root',
})
export class BrazeService {
  private readonly enabled = environment.brazeConfig.enabled;

  private userData: any;
  private companyData: any;
  private userSubscription: CheckTrialResponse;
  private brazeUser: any;

  constructor(
    private companyService: CompanyService,
    private readonly permissionService: PermissionService,
    private readonly http: HttpClient,
    private iosPermissions: IosPermissionService
  ) {}

  public init(): void {
    braze.initialize(environment.brazeConfig.key, {
      baseUrl: environment.brazeConfig.url,
      enableLogging: environment.brazeConfig.debug,
      allowUserSuppliedJavascript: true,
    });

    braze.automaticallyShowInAppMessages();
  }
  private brazeEnabled(): boolean {
    return this.enabled && this.iosPermissions.canTrackEvents();
  }
  public identifyUser(): void {
    if (!this.brazeEnabled()) return;

    this.userData = JSON.parse(StorageService.GetItem('userData'));
    this.companyData = JSON.parse(StorageService.GetItem('companyData'));

    const companyPrices = StorageService.GetItem('companyPrices');

    const prices: Array<Prices> =
      typeof companyPrices === 'string' && companyPrices !== 'undefined'
        ? JSON.parse(companyPrices)
        : [];
    if (this.userData && this.companyData) {
      Promise.all([
        this.http
          .get(
            `:API_URL/braze/user/?userId=${this.userData.id}&email=${this.userData.email}`
          )
          .toPromise(),
        this.permissionService.checkTrialSubscription(
          this.companyData._id,
          'taclia',
          true
        ),
        this.companyService
          .findOne(localStorage.getItem(COMPANY_ID_KEY))
          .toPromise(),
      ])
        .then(([brazeUser, userSubscription]) => {
          this.brazeUser = brazeUser;
          this.userSubscription = userSubscription;

          const userDataToIdentify = {
            userId: this.userData.id,
            firstName: this.userData.name,
            email: this.userData.email,
            phone: this.userData.phone,
            userRole: this.userData.role,
            emailSubscribe: 'opted_in',
            pushSubscribe: 'opted_in',
            companyCreatedAt: this.companyData.created_at,
            companyName: this.companyData.name,
            featuresActive: this.companyData.modules,
            subscriptionPlan: this.getSubscriptionPlan(),
            startPayingSubscriptionAt: this.userSubscription.pro?.startAt,
            finishPayingSubscriptionAt: this.userSubscription.pro?.endAt,
            startTrialSubscriptionAt: this.userSubscription.trial?.startAt,
            finishTrialSubscriptionAt: this.userSubscription.trial?.endAt,
            companyUtmCampaign: this.companyData.company_utm_campaign,
            stChoice: this.companyData.stChoice,
            language: StorageService.GetItem('USER_LANG'),
            companyCountry: this.companyData.locationCountry,
            billing_period:
              prices.find(
                (price) => price.itemPriceId === this.companyData.plans[0]
              )?.periodUnit || 'month',
            monthlyPrice:
              prices.find((price) => price.periodUnit === 'month')?.price ||
              1400,
            yearlyPrice:
              prices.find((price) => price.periodUnit === 'year')?.price ||
              16262,
          };
          this.handleUser(userDataToIdentify);
        })
        .catch((err) => {
          console.log('Error while identifying user', err);
        });
    }
  }

  public logout(): void {
    braze.destroy();
  }
  private getSubscriptionPlan(): BrazeSubscriptionPlan {
    if (this.userSubscription.remainingDays > 0) {
      return BrazeSubscriptionPlan.TRIAL;
    } else if (this.userSubscription.proPlanActivated) {
      return BrazeSubscriptionPlan.PRO;
    }
    return BrazeSubscriptionPlan.FREE;
  }

  private handleUser(data: BrazeUserAttributes): void {
    braze.changeUser(data.userId);
    braze.openSession();

    if (!this.brazeUser) {
      this.createUser(data);
    } else {
      this.applyDeltaUpdates(data);
    }
  }
  private createUser(data: BrazeUserAttributes): void {
    braze.getUser().addAlias(data.userId, 'UserId');
    braze.getUser().addAlias(data.email, 'Email');

    braze.getUser().setFirstName(data.firstName);
    braze.getUser().setEmail(data.email);
    braze.getUser().setPhoneNumber(data.phone);
    braze
      .getUser()
      .setCustomUserAttribute('company_created_at', data.companyCreatedAt);
    braze.getUser().setCustomUserAttribute('company_name', data.companyName);
    braze
      .getUser()
      .setCustomUserAttribute('email_subscribe', data.emailSubscribe);
    braze
      .getUser()
      .setCustomUserAttribute('push_subscribe', data.pushSubscribe);
    braze.getUser().setCustomUserAttribute('user_role', data.userRole);
    braze
      .getUser()
      .setCustomUserAttribute('subscription_plan', data.subscriptionPlan);

    braze
      .getUser()
      .setCustomUserAttribute('first_choice', this.companyData.stChoice);
    braze
      .getUser()
      .setCustomUserAttribute('features_active', data.featuresActive);
    braze
      .getUser()
      .setCustomUserAttribute(
        'startPayingSubscriptionAt',
        data.startPayingSubscriptionAt
          ? new Date(data.startPayingSubscriptionAt)
          : undefined
      );
    braze
      .getUser()
      .setCustomUserAttribute(
        'finishPayingSubscriptionAt',
        data.finishPayingSubscriptionAt
          ? new Date(data.finishPayingSubscriptionAt)
          : undefined
      );
    braze
      .getUser()
      .setCustomUserAttribute(
        'startTrialSubscriptionAt',
        data.startTrialSubscriptionAt
          ? new Date(data.startTrialSubscriptionAt)
          : undefined
      );
    braze
      .getUser()
      .setCustomUserAttribute(
        'finishTrialSubscriptionAt',
        data.finishTrialSubscriptionAt
          ? new Date(data.finishTrialSubscriptionAt)
          : undefined
      );
    braze
      .getUser()
      .setCustomUserAttribute(
        'device_type',
        window.innerWidth > 800 ? 'desktop' : 'mobile'
      );
    braze
      .getUser()
      .setCustomUserAttribute(
        'company_utm_campaign',
        this.companyData?.company_utm_campaign
      );

    braze.getUser().setLanguage(data.language);

    braze.getUser().setCustomUserAttribute('language', data.language);
    braze
      .getUser()
      .setCustomUserAttribute('company_country', data.companyCountry);
    braze
      .getUser()
      .setCustomUserAttribute('billing_period', data.billing_period);

    braze.getUser().setCustomUserAttribute('monthly_price', data.monthlyPrice);
    braze.getUser().setCustomUserAttribute('yearly_price', data.yearlyPrice);
  }
  private applyDeltaUpdates(data: BrazeUserAttributes): void {
    braze.getUser().addAlias(data.userId, 'UserId');
    braze.getUser().addAlias(data.email, 'Email');

    if (data.firstName !== this.brazeUser.firstName) {
      braze.getUser().setFirstName(data.firstName);
    }

    if (data.email !== this.brazeUser.email) {
      braze.getUser().setEmail(data.email);
    }

    if (data.phone !== this.brazeUser.phone) {
      braze.getUser().setPhoneNumber(data.phone);
    }

    if (data.companyCreatedAt !== this.brazeUser.companyCreatedAt) {
      braze
        .getUser()
        .setCustomUserAttribute('company_created_at', data.companyCreatedAt);
    }

    if (data.companyName !== this.brazeUser.companyName) {
      braze.getUser().setCustomUserAttribute('company_name', data.companyName);
    }
    if (data.stChoice !== this.brazeUser.stChoice && data.stChoice !== null) {
      braze.getUser().setCustomUserAttribute('first_choice', data.stChoice);
    }
    if (data.emailSubscribe !== this.brazeUser.emailSubscribe) {
      braze
        .getUser()
        .setCustomUserAttribute('email_subscribe', data.emailSubscribe);
    }

    if (data.pushSubscribe !== this.brazeUser.pushSubscribe) {
      braze
        .getUser()
        .setCustomUserAttribute('push_subscribe', data.pushSubscribe);
    }

    if (data.userRole !== this.brazeUser.userRole) {
      braze.getUser().setCustomUserAttribute('user_role', data.userRole);
    }

    if (data.subscriptionPlan !== this.brazeUser.subscriptionPlan) {
      braze
        .getUser()
        .setCustomUserAttribute('subscription_plan', data.subscriptionPlan);
    }

    if (data.featuresActive !== this.brazeUser.featuresActive) {
      braze
        .getUser()
        .setCustomUserAttribute('features_active', data.featuresActive);
    }

    if (
      data.startPayingSubscriptionAt !==
      this.brazeUser.startPayingSubscriptionAt
    ) {
      braze
        .getUser()
        .setCustomUserAttribute(
          'date_start_paying_subscription',
          data.startPayingSubscriptionAt
            ? new Date(data.startPayingSubscriptionAt)
            : undefined
        );
    }

    if (
      data.finishPayingSubscriptionAt !==
      this.brazeUser.finishPayingSubscriptionAt
    ) {
      braze
        .getUser()
        .setCustomUserAttribute(
          'date_finish_paying_subscription',
          data.finishPayingSubscriptionAt
            ? new Date(data.finishPayingSubscriptionAt)
            : undefined
        );
    }

    if (
      data.startTrialSubscriptionAt !== this.brazeUser.startTrialSubscriptionAt
    ) {
      braze
        .getUser()
        .setCustomUserAttribute(
          'date_start_trial_subscription',
          data.startTrialSubscriptionAt
            ? new Date(data.startTrialSubscriptionAt)
            : undefined
        );
    }

    if (
      data.finishTrialSubscriptionAt !==
      this.brazeUser.finishTrialSubscriptionAt
    ) {
      braze
        .getUser()
        .setCustomUserAttribute(
          'date_finish_trial_subscription',
          data.finishTrialSubscriptionAt
            ? new Date(data.finishTrialSubscriptionAt)
            : undefined
        );
    }
    if (data.companyUtmCampaign !== this.brazeUser.companyUtmCampaign) {
      braze
        .getUser()
        .setCustomUserAttribute(
          'company_utm_campaign',
          this.companyData.company_utm_campaign
        );
    }

    if (data.language !== this.brazeUser.language) {
      braze.getUser().setLanguage(data.language);
      braze.getUser().setCustomUserAttribute('language', data.language);
    }

    if (data.companyCountry !== this.brazeUser.companyCountry) {
      braze
        .getUser()
        .setCustomUserAttribute('company_country', data.companyCountry);
    }

    if (data.billing_period !== this.brazeUser.billing_period) {
      braze
        .getUser()
        .setCustomUserAttribute('billing_period', data.billing_period);
    }

    if (data.monthlyPrice !== this.brazeUser.monthly_price) {
      braze
        .getUser()
        .setCustomUserAttribute('monthly_price', data.monthlyPrice);
    }
    if (data.yearlyPrice !== this.brazeUser.yearly_price) {
      braze.getUser().setCustomUserAttribute('yearly_price', data.yearlyPrice);
    }
  }

  public setCustomAttributes(data: { name: string; value: any }): void {
    braze.getUser().setCustomUserAttribute(data.name, data.value);
  }

  public trackEvent(eventName: string, eventProperties = {}): void {
    if (!this.brazeEnabled()) {
      return;
    }
    const date = new Date();
    if (braze.getUser()) {
      braze.logCustomEvent(eventName, {
        date_create: date.toISOString(),
      });
    } else if (this.userData) {
      braze.changeUser(this.userData.id);
      braze.logCustomEvent(eventName, {
        date_create: date.toISOString(),
      });
    }
  }

  public sendEvent(data: BrazeEvent): void {
    if (!this.brazeEnabled()) {
      return;
    }
    const date = new Date();
    if (braze.getUser()) {
      braze.logCustomEvent(data.event, {
        date_create: date.toISOString(),
      });
    } else {
      if (this.userData) {
        braze.changeUser(this.userData.id);
        braze.logCustomEvent(data.event, {
          date_create: date.toISOString(),
        });
      }
    }
  }
}
