import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company, COMPANY_ID_KEY } from '@tacliatech/types';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinct, filter, map, tap } from 'rxjs/operators';
import { AuthService } from '../auth';
import { StorageService } from '../storage';
import { PermissionService } from '../permissions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CompanyModuleService {
  private modulesStorage = new BehaviorSubject<Company.Module[]>([]);

  modules$ = this.modulesStorage
    .asObservable()
    .pipe(filter((res) => Boolean(res)));

  activeModules$ = this.modules$.pipe(
    map((res) => res.filter((el) => el.active))
  );

  idActiveModules$ = this.activeModules$.pipe(
    map((res) => res.map((el) => el.id))
  );

  sidebarElements$ = this.modules$.pipe(
    map((res) => res.filter((el) => el.active)),
    map((res) => res.sort((a, b) => a.index - b.index)),
    distinct()
  );

  private watchUserSub!: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  get idActiveModules(): Company.IdModule[] {
    return this.modulesStorage.value
      .filter((module) => module.active)
      .map((module) => module.id);
  }

  get modulesAllowed(): Company.IdModule[] {
    return this.modulesStorage.value
      .filter((module) => module.canAccess)
      .map((module) => module.id);
  }

  setAccessibleModules(modules: Company.Module[]): Company.Module[] {
    return modules.map((module) => {
      const permissions = Company.RoutesPermissionsMap[module.id];
      if (permissions) {
        const canAccess = permissions.filter((p) =>
          this.permissionService.permissions.includes(p.feature)
        );

        Object.assign(module, {
          canAccess: module.active && canAccess.length > 0,
          path: canAccess?.[0]?.url,
        });
      }
      return module;
    });
  }

  init(): void {
    if (!this.watchUserSub) {
      this.watchUserSub = new Subscription();
    } else {
      this.watchUserSub.unsubscribe();
    }

    this.watchUserSub = this.authService.user$
      .pipe(filter((res) => Boolean(res)))
      .subscribe((el) => {
        this.modulesStorage.next(this.setAccessibleModules(el.company.modules));
      });
  }

  isModuleActive(id: Company.IdModule): Observable<boolean> {
    return this.idActiveModules$?.pipe(map((res) => res.includes(id)));
  }

  getRoutesActives(): Company.Module[] {
    const modules = this.modulesStorage
      .getValue()
      .filter((module) => module.active)
      .sort((a, b) => a.index - b.index);

    return modules;
  }

  getIdRoutesActives(): Company.IdModule[] {
    return this.getRoutesActives().map((el) => el.id);
  }

  goFirstPermissionActive(activesModules: Company.Module[]): void {
    this.permissionService.permissions$
      .subscribe((permissions) => {
        let goTo = '';
        activesModules.forEach((activeModule) => {
          const match = Company.RoutesPermissionsMap[
            activeModule.id
          ]?.find((route) => permissions.includes(route.feature))?.url;
          if (match && !goTo) {
            goTo = match;
          }
        });
        this.router.navigateByUrl(goTo || 'admin/company-modules');
      })
      .unsubscribe();
  }

  goFirstRouteActive(): void {
    const activesModules = this.modulesStorage
      .getValue()
      .filter((module) => module.active)
      .sort((a, b) => a.index - b.index);
    this.goFirstPermissionActive(activesModules);
  }

  takeModules(amount: number): Observable<Company.Module[]> {
    return this.sidebarElements$.pipe(map((res) => res.slice(0, amount)));
  }

  updateCompanyModule(
    data: Company.PutUpdateParams
  ): Observable<Company.Output> {
    return this.http
      .put<Company.Output>(
        `:API_URL/companies/${StorageService.GetItem(
          COMPANY_ID_KEY
        )}/update-company-module`,
        data
      )
      .pipe(tap(this.updateUser));
  }

  updateUser = (res: Company.Output): void => {
    const user = { ...this.authService.user, company: res };
    this.authService.updateUserCache = user;
    this.modulesStorage.next(this.setAccessibleModules(res.modules));
  };

  updateCompanyModuleSector(
    data: Company.PutUpdatStateModules
  ): Observable<Company.Output> {
    return this.http
      .put<Company.Output>(
        `:API_URL/companies/${StorageService.GetItem(
          COMPANY_ID_KEY
        )}/update-modules-in-bulk`,
        data
      )
      .pipe(tap(this.updateUser));
  }
}
