import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppName, AppUrl } from '../microfrontend.tokens';
import { MfSettingsComponent } from './mf-settings.component';
import { UnsavedChangesModule } from '../../shared/components/unsaved-changes/unsaved-changes.module';

const microfrontendKey = 'mf-settings';

@NgModule({
  imports: [CommonModule, UnsavedChangesModule],
  declarations: [MfSettingsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AppName,
      useValue: microfrontendKey,
    },
    {
      provide: AppUrl,
      useFactory: () => {
        return localStorage.getItem(microfrontendKey);
      },
    },
  ],
  exports: [MfSettingsComponent],
})
export class MfSettingsModule {}
