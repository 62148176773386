import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'roma-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  retry() {
    this.router.navigateByUrl('/admin/company-modules');
  }
}
