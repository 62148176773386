import { Injectable } from '@angular/core';
import { RecaptchaModule } from './recaptcha.module';
import { environment } from '@web-frontend/environments';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let grecaptcha: any;

@Injectable({
  providedIn: RecaptchaModule,
})
export class RecaptchaService {
  public execute(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(`${environment.recaptchaV3SiteKey}`, { action })
          .then(
            (token: string) => {
              resolve(token);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error: any) => {
              reject(error);
            }
          );
      });
    });
  }
}
