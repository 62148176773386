/* eslint-disable @typescript-eslint/no-namespace */

export interface PlanType {
  id: string;
  name: string;
  item_family_id: string;
  item_id: string;
  status: string;
  external_name: string;
  pricing_model: string;
  price: number;
  period: number;
  currency_code: string;
  period_unit: string;
  free_quantity: number;
  channel: string;
  resource_version: any;
  updated_at: number;
  created_at: number;
  is_taxable: boolean;
  item_type: string;
  show_description_in_invoices: boolean;
  show_description_in_quotes: boolean;
  object: string;
  monthly: number;
  default?: boolean;
  billing_cycles?: number;
  trial_period?: number;
  trial_period_unit: string;
  discountPercentage?: number;
  description?: string;
}

export interface Plan {
  planTypes: PlanType[];
  _id: string;
  id: string;
  name: string;
  external_name: string;
  status: string;
  resource_version: any;
  item_family_id: string;
  type: string;
  is_shippable: boolean;
  is_giftable: boolean;
  enabled_for_checkout: boolean;
  enabled_in_portal: boolean;
  item_applicability: string;
  metered: boolean;
  channel: string;
  object: string;
  __v: number;
}

export enum ModuleType {
  CRM = 'CRM',
  SALES = 'SALES',
  FULL = 'FULL-PRO',
  TIME_TRACKING = 'TIME-TRACKING',
}
