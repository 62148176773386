import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@web-frontend/shared/services';
import {
  CompanyModuleService,
  CompanyService,
} from '../../../shared/services/company';

@Component({
  selector: 'roma-login',
  templateUrl: './impersonate.component.html',
})
export class ImpersonateComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly companyModuleService: CompanyModuleService,
    private readonly companyService: CompanyService,
    private readonly router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    const accessToken = this.route.snapshot.queryParamMap.get('accessToken');
    await this.authService.impersonate(accessToken);
    this.companyService
      .getCompanyInfo()
      .toPromise()
      .then((res) => {
        this.companyModuleService.updateUser(res);
        this.router.navigate(['/admin/settings/general']);
      });
  }
}
