import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authRoutes } from '../auth-routing.module';

@Component({
  selector: 'roma-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['../login/login.component.scss', './welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  resolveRedirect() {
    this.router.navigateByUrl(authRoutes.login);
  }
}
