<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div>
  <div class="col-12">
    <div *ngIf="type === 'deal'" style="padding: 0">
      <div
        class="mobile-table-card"
        *ngFor="let deal of data.filteredData ? data.filteredData : data"
      >
        <div (click)="editClick($event, deal)">
          <h4>{{ deal?.name }}</h4>
          <img
            class="more-actions"
            [matMenuTriggerFor]="menu"
            src="/assets/icons/gl_more-horizontal.svg"
            romaClickStopPropagation
          />
          <mat-menu class="actions-mobile" #menu="matMenu">
            <button
              *ngIf="!deal?.cancelled"
              mat-menu-item
              romaClickDeleteDeal
              title="Cancelar"
              [item]="deal"
              [type]="type"
              style="cursor: pointer; color: #ff474e"
              romaProFeature
              [featureUser]="featureRefUser.Deal.delete"
              (wantDeleteDeal)="deleteClickDeal($event)"
            >
              {{ 'general.cancelDeal' | translate }}
            </button>
            <button
              class="blue"
              *ngIf="deal?.cancelled"
              mat-menu-item
              romaClickRestore
              title="Restaurar"
              [item]="deal"
              [type]="type"
              style="cursor: pointer"
              romaProFeature
              [featureUser]="featureRefUser.Deal.delete"
              (wantRestore)="restoreClick($event)"
            >
              {{ 'general.restoreButton' | translate }}
            </button>
          </mat-menu>

          <p class="client">
            <span>{{ 'table.colum.customer' | translate }}</span>
            {{
              deal?.final
                ? getFinalName(deal.final)
                : (tagNotAvailable | translate)
            }}
          </p>
          <p class="taskerLine">
            <img
              [src]="
                getUserImgByName(
                  deal?.taskerTeam?.length > 0
                    ? deal?.taskerTeam[0]?.name
                    : null
                )
              "
              style="float: left; margin-right: 10px; width: 20px; height: 20px"
            />
            <span class="left-info">
              <!-- <p>Taker: {{ deal?.taskerTeam?.length }}</p>-->
              <ng-container *ngIf="deal?.taskerTeam?.length <= 2; else showAll">
                <ng-container *ngIf="!deal?.taskerTeam.length">{{
                  'general.notAssigned' | translate
                }}</ng-container>
                <ng-container
                  class="example-box"
                  cdkDrag
                  *ngFor="let user of deal?.taskerTeam"
                >
                  {{ user.name }}
                </ng-container>
              </ng-container>
              <ng-template #showAll>
                <mat-chip
                  class="example-box"
                  cdkDrag
                  (click)="showModalUser(deal?.taskerTeam)"
                >
                  {{ 'table.colum.asset_see_all' | translate
                  }}{{
                    deal?.taskerTeam.length
                      ? '+' + (deal?.taskerTeam.length - 2)
                      : ('general.notAssigned' | translate)
                  }}
                </mat-chip>
              </ng-template>
            </span>
            <span class="right-info">
              <ng-container *ngIf="deal?.initDate">
                <img src="/assets/icons/gl_calendar_fill.svg" />
                {{ deal?.initDate ? (deal?.initDate | date: 'dd/MM') : '-' }}
                <ng-container *ngIf="!isEmptyHour(deal.initDate, item, '')">
                  <img src="/assets/icons/gl_clock_fill.svg" />
                  {{ deal?.initDate ? (deal?.initDate | date: 'H:mm') : '-' }}
                </ng-container>
              </ng-container>
            </span>
          </p>
          <span
            class="status-wrapper"
            [ngClass]="{
              'in-process': deal?.status?.color === 'IN_PROCESS',
              new: deal?.status?.color === 'BUDGETED',
              ended: deal?.status?.color === 'FINALIZED'
            }"
          >
            <roma-rm-badge
              [statusName]="
                deal?.status?.translate
                  ? (deal?.status?.translate | translate)
                  : deal?.status?.name
              "
              [color]="getStatusColor(deal?.status?.name)"
            ></roma-rm-badge>
          </span>
        </div>
      </div>
    </div>

    <div
      class="table-container"
      [ngClass]="{ 'mobile-hidden': type === 'deal' }"
      style="position: relative; padding: 0"
    >
      <mat-table
        #table
        mat-table
        matSort
        [dataSource]="data"
        (matSortChange)="sortData($event)"
        [matSortActive]="auxSort.active ? auxSort.active : null"
        [matSortDirection]="auxSort.direction ? auxSort.direction : null"
      >
        <ng-container [matColumnDef]="columnPrefix + 'ref'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'Ref' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="
                !isMobileDeviceApplication &&
                showOpenIcon &&
                columns[0] === 'ref'
              "
              class="material-icons open-in-new"
              title="Abrir en nueva pestaña"
              [item]="item"
              [type]="type"
              (click)="openNewTabClick($event, item._id)"
            >
              open_in_new
            </span>
            {{ item?.ref || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'productCategory'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'hiring.type.category' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.product ? item.product?.name : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'final'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'finals.final' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.final
                ? getFinalName(item.final)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'finalObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'finals.final' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.finalObj
                ? getFinalName(item.finalObj)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'salesFinal'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'finals.final' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.final?.title === '' || item?.final?.title == null
                ? (tagNotAvailable | translate)
                : item.final?.title
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- Checkbox Column -->
        <ng-container [matColumnDef]="'romadefaultcolumnselect'">
          <mat-header-cell
            mat-header-cell
            *matHeaderCellDef
            class="cdk-column-select"
            (click)="toggleAllRows(); $event?.stopPropagation()"
          >
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell
            mat-cell
            *matCellDef="let row"
            class="cdk-column-select"
            (click)="$event?.stopPropagation()"
          >
            <mat-checkbox
              (click)="selectRow(row); $event?.stopPropagation()"
              (change)="$event ? selectRow(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="columnPrefix + 'userName'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            <ng-container
              *ngIf="type !== 'clockHoursAbsence' && type !== 'adminClockHours'"
            >
              {{ 'finals.final' | translate }}
            </ng-container>
            <ng-container
              *ngIf="type === 'clockHoursAbsence' || type === 'adminClockHours'"
            >
              {{ 'table.colum.name' | translate }}
            </ng-container>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <img
              class="avatar"
              [src]="
                getUserImgByName(
                  item?.owner?.name,
                  'assets/icons/table-user-icon.svg'
                )
              "
            />
            {{ item?.owner.name }}
          </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="columnPrefix + 'dateTime'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.date' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.dateTime
                ? (item?.dateTime | dateParse: 'AvoidLocaleDate')
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <ng-container [matColumnDef]="columnPrefix + 'expiration'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.expiration' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.dateTime
                ? (item?.dateTime | dateParse: 'AvoidLocaleDate')
                : '--'
            }}
            {{ item.hour ? '/ ' + item.hour + 'hr' : null }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <ng-container [matColumnDef]="columnPrefix + 'supervisor'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.supervisor' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item" mat-cell class="ownerObj">
            <ng-container *ngIf="item.supervisorObj">
              <div
                *ngIf="
                  isArray(item?.supervisorObj) && item.supervisorObj.length > 0
                "
              >
                <img
                  [src]="
                    getUserImgByName(
                      item?.supervisorObj.length > 0
                        ? item?.supervisorObj[0].name
                        : null,
                      'assets/icons/table-user-icon.svg'
                    )
                  "
                  style="
                    margin-right: 4px;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                  "
                />
                {{
                  item.supervisorObj.length > 1
                    ? '(+' + item.supervisorObj.length + ')'
                    : item.supervisorObj[0].name
                }}
                <ng-container *ngIf="item.supervisorObj.length > 1">
                  <div
                    class="owners-link"
                    (click)="
                      $event.stopPropagation();
                      openUsersDialog(
                        item.supervisorObj,
                        'table.colum.supervisor'
                      )
                    "
                  >
                    {{ 'table.colum.supervisor' | translate }}
                  </div>
                </ng-container>
              </div>
              <div *ngIf="!isArray(item?.supervisorObj)">
                <img src="/assets/icons/table-user-icon.svg" />
                {{ item.supervisorObj?.name }}
              </div>
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'salesNumber'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'sales.table.colum.sales_number' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.number ? '#' + item.number : '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'paymentDate'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.dateHour' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <ng-container *ngIf="!item?.paymentDate">
              {{ tagNotAvailable | translate }}
            </ng-container>

            <ng-container
              *ngIf="
                item?.paymentDate && isEmptyHour(item.paymentDate, item, '')
              "
            >
              {{
                item?.paymentDate
                  ? (item?.paymentDate | dateParse: '12Hours')
                  : (tagNotAvailable | translate)
              }}
            </ng-container>

            <!--service date -->
            <ng-container
              *ngIf="
                item?.paymentDate && !isEmptyHour(item.paymentDate, item, '')
              "
            >
              {{
                item?.paymentDate
                  ? (item?.paymentDate | dateParse: '12Hours')
                  : (tagNotAvailable | translate)
              }}
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'project'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'general.project' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.project ? item?.project?.name : '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'budget'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'general.budget' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.budget
                ? '#' +
                  item?.budget?.header.numberDoc +
                  ' / ' +
                  getFormatedCustomerNumber(item?.budget?.total) +
                  getCurrencySimbol()
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'bills'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'general.bill' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.bill
                ? '#' +
                  item?.bill?.header.numberDoc +
                  ' / ' +
                  getFormatedCustomerNumber(item?.bill?.total) +
                  getCurrencySimbol()
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'number'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'expense.create.form.number' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.number || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'position'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'person.create.form.position' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <span *ngIf="type !== 'adminClockHours'">
              {{ item?.position || (tagNotAvailable | translate) }}
            </span>
            <span *ngIf="type === 'adminClockHours'">
              {{ item?.owner?.position || (tagNotAvailable | translate) }}
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'concept'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'expense.create.form.concept' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.concept || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'internalReference'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'expense.create.form.internalReference' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.internalReference || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'typeRate'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'settings.setting_type' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.type
                ? ('catalogue.' + item?.type | translate)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'category'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'catalogue.category' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.category
                ? item?.category.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'categoryObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'catalogue.category' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.categoryObj
                ? item?.categoryObj.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'sellPrice'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'catalogue.sellPrice' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.sellPrice || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'buyPrice'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'catalogue.buyPrice' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.buyPrice || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'typeExpense'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'expense.create.form.type' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.type
                ? (getTypeExpense(item?.type) | translate)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'statusExpense'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.status' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.status
                ? (getStatusExpense(item?.status) | translate)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'subtotal'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'expense.create.form.subtotal' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.subtotal || (tagNotAvailable | translate) | currencyFormat
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{
              subtotalCount || (tagNotAvailable | translate) | currencyFormat
            }}
          </mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'total'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'expense.create.form.total' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.total || (tagNotAvailable | translate) | currencyFormat }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ totalCount || (tagNotAvailable | translate) | currencyFormat }}
          </mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'expense_category'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.expense_category' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.categoryObj
                ? item?.categoryObj?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'expenseCategoryObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.expense_category' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.categoryObj
                ? item?.categoryObj?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'brand'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'equipments.create.brand' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.brand ? item.brand : (tagNotAvailable | translate) }}
          </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'client'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'projects.customer' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.client
                ? getFinalName(item?.client)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'revisionDate'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'equipments.create.revisionDate' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.revisionDate
                ? (item?.revisionDate | dateParse)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <ng-container [matColumnDef]="columnPrefix + 'customerName'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.customer' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.description
                ? item.description
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'qr'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'QR' | translate }}
            <mat-icon color="primary" class="icon-info" (click)="openQrLegend()"
              >info</mat-icon
            >
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <roma-qr [url]="qrParserUrl(item)"></roma-qr>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'customerSector'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.sector' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.company_type
                ? item.company_type
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'customerType'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.type' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.customerType
                ? item.customerType
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'dueDate'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'expense.create.form.dueDate' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [ngClass]="{ 'is-expired': isExpired(item?.dueDate) }"
          >
            {{
              item?.dueDate
                ? (item?.dueDate | dateParse)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <ng-container [matColumnDef]="columnPrefix + '_id'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            Ref
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="showOpenIcon && columns[0] === '_id'"
              class="material-icons open-in-new"
              title="Abrir en nueva pestaña"
              [item]="item"
              [type]="type"
              (click)="openNewTabClick($event, item._id)"
            >
              open_in_new
            </span>
            {{ item._id | split }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'customer'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.customer' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.company?.name
                ? item?.company?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'idFinal'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.customer' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.idFinal?.name
                ? item?.idFinal?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'customer_id'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.customer' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.customer_id?.id
                ? item?.customer_id?.id
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'city'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.city' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.city ? item.city : (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'name'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.title' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [title]="item?.name ? item?.name : (tagNotAvailable | translate)"
          >
            <img
              src="assets/icons/gl_external-link.svg"
              *ngIf="
                showOpenIcon && (columns[0] === 'name' || columns[1] === 'name')
              "
              class="material-icons open-in-new"
              title="Abrir en nueva pestaña"
              [item]="item"
              [type]="type"
              (click)="openNewTabClick($event, item._id)"
            />
            {{
              item?.name
                ? (item.name | translate | split: 55:false)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'vat'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.vat' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [title]="item?.vat ? item?.vat : (tagNotAvailable | translate)"
          >
            {{
              item?.vat
                ? (item.vat | split: 55:false)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'only_name'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.name' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.name || (tagNotAvailable | translate) }}
            {{ item?.lastName || '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'name_person'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.name' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="showOpenIcon && columns[0] === 'name_person'"
              class="material-icons open-in-new"
              title="Abrir en nueva pestaña"
              [item]="item"
              [type]="type"
              (click)="openNewTabClick($event, item._id)"
            >
              open_in_new
            </span>
            <img class="avatar" [src]="item.img | imageFirebase: 'user'" />

            {{ item?.name ? item?.name : (tagNotAvailable | translate) }}
            {{ item?.lastName ? item?.lastName : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'img_asset'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <img
              *ngIf="item.files?.length"
              class="avatar"
              [src]="item.files[0] | imageFirebase: 'address'"
            />
            <img
              *ngIf="!item.files?.length"
              class="avatar"
              [src]="'' | imageFirebase: 'address'"
            />
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'name_asset'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.name' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <!-- <img *ngIf="item.files?.length" class="avatar" [src]="item.files[0] | image: 'address'" />
            <img *ngIf="!item.files?.length" class="avatar" [src]="'' | image: 'address'" /> -->
            <span
              *ngIf="showOpenIcon && columns[0] === 'name_asset'"
              class="material-icons open-in-new"
              title="Abrir en nueva pestaña"
              [item]="item"
              [type]="type"
              (click)="openNewTabClick($event, item._id)"
            >
              open_in_new
            </span>
            {{ item?.name ? item?.name : (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'name_project'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.name' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="showOpenIcon && columns[0] === 'name_project'"
              class="material-icons open-in-new"
              title="Abrir en nueva pestaña"
              [item]="item"
              [type]="type"
              (click)="openNewTabClick($event, item._id)"
            >
              open_in_new
            </span>
            {{ item?.name ? item?.name : (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'initDate'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ dateColumnTitle | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <ng-container *ngIf="type !== 'clockHoursAbsence'">
              <ng-container *ngIf="!item?.dates?.initTimestamp">
                {{ tagNotAvailable | translate }}
              </ng-container>
              <ng-container *ngIf="item?.dates?.initTimestamp">
                {{
                  item?.dates?.initTimestamp
                    ? item?.isAllDay
                      ? (item?.dates?.initTimestamp | date: 'dd/MM/YYYY')
                      : (item?.dates?.initTimestamp | date: 'dd/MM/YYYY - H:mm')
                    : (tagNotAvailable | translate)
                }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="type === 'clockHoursAbsence'">
              {{
                item?.dateDetail.startDate
                  ? (item?.dateDetail.startDate | dateParse: 'AvoidLocaleDate')
                  : (tagNotAvailable | translate)
              }}
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'endDate'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.end' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <ng-container *ngIf="type === 'clockHoursAbsence'">
              {{
                item?.dateDetail.endDate
                  ? (item?.dateDetail.endDate | dateParse: 'AvoidLocaleDate')
                  : (tagNotAvailable | translate)
              }}
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'dateCreated'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.created_date' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.dateCreated
                ? (item?.dateCreated | dateParse)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'times'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.slots' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.times"
              [title]="'Detalles de horarios'"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'phones'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.phones' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              *ngIf="type != 'finals'"
              [items]="item?.phones"
              [title]="'general.phones' | translate"
            >
            </app-chips-resume>
            <app-chips-resume
              *ngIf="type === 'finals'"
              [items]="item?.phonesArr?.length ? item?.phonesArr : item?.phones"
              [title]="'general.phones' | translate"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'typeClient'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.typeClient' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.type
                ? (getNameTypeClient(item) | translate)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'catchmentChannel'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.catchmentChannel' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.catchmentChannel
                ? (getNameCatchmentChannel(item) | translate)
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'schedules'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.slots' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.times"
              [title]="'Detalles de horarios'"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'deal'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.deal' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.deal?.name ? item?.deal?.name : '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'dealObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.deal' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.dealObj?.name ? item?.dealObj?.name : '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'type'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.typeAbsence' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <ng-container
              *ngIf="type === 'clockHoursAbsence'"
              [ngSwitch]="item?.type"
            >
              <ng-container *ngSwitchCase="'0'">
                {{ 'table.content.holidays' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'1'">
                {{ 'table.content.sickness' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'2'">
                {{ 'table.content.maternity' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'3'">
                {{ 'table.content.familySickness' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'4'">
                {{ 'table.content.others' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ tagNotAvailable | translate }}
              </ng-container>
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'status'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.status' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <ng-container *ngIf="type === 'activity'">
              <ng-container *ngIf="item?.statusObj?.name">
                <roma-rm-badge
                  [statusName]="item?.statusObj?.name"
                  [color]="item?.statusObj?.color"
                ></roma-rm-badge>
              </ng-container>
              <ng-container *ngIf="!item?.statusObj?.name"> -- </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                type !== 'clockHoursAbsence' &&
                type !== 'adminClockHours' &&
                type !== 'clockHours' &&
                type !== 'clockHoursDetail'
              "
            >
              <roma-rm-badge
                [statusName]="
                  item?.status?.translate
                    ? (item?.status?.translate | translate)
                    : item?.status?.name
                "
                [color]="getStatusColor(item?.status)"
              ></roma-rm-badge>
            </ng-container>
            <ng-container
              *ngIf="
                type === 'adminClockHours' ||
                type === 'clockHours' ||
                type === 'clockHoursDetail'
              "
            >
              <ng-container [ngSwitch]="item?.status">
                <span
                  *ngSwitchCase="clockHourStatusRef.Pending"
                  class="status-wrapper warn"
                >
                  {{ 'table.content.pending' | translate }}
                </span>
                <span
                  *ngSwitchCase="clockHourStatusRef.Approved"
                  class="status-wrapper green"
                >
                  {{ 'table.content.approved' | translate }}
                </span>
                <span
                  *ngSwitchCase="clockHourStatusRef.Rejected"
                  class="status-wrapper red"
                >
                  {{ 'table.content.rejected' | translate }}
                </span>
                <ng-container *ngSwitchDefault>
                  <ng-container
                    *ngIf="type === 'adminClockHours'; else emptyStatus"
                  >
                    {{ tagNotAvailable | translate }}
                  </ng-container>
                  <ng-template #emptyStatus> -- </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="type === 'clockHoursAbsence'"
              [ngSwitch]="item?.status"
            >
              <span *ngSwitchCase="'0'" class="status-wrapper warn">
                {{ 'table.content.pending' | translate }}
              </span>
              <span *ngSwitchCase="'1'" class="status-wrapper red">
                {{ 'table.content.rejected' | translate }}
              </span>
              <span *ngSwitchCase="'2'" class="status-wrapper green">
                {{ 'table.content.approved' | translate }}
              </span>
              <span *ngSwitchCase="'3'" class="status-wrapper blue">
                {{ 'table.content.others' | translate }}
              </span>
              <ng-container *ngSwitchDefault>
                {{ tagNotAvailable | translate }}
              </ng-container>
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'vendor'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.vendors' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item" mat-cell class="vendorObj">
            <ng-container
              *ngIf="
                item?.type === dealsTypeRef.OpenMarket && item?.taskerOpenMarket
              "
            >
              <img
                [src]="
                  getUserImgByName(
                    item?.taskerTeam?.length > 0
                      ? item?.taskerTeam[0].name
                      : null,
                    'assets/icons/table-user-icon.svg'
                  )
                "
                style="margin-right: 4px; height: 20px; width: 20px"
              />
              {{
                item?.taskerOpenMarket?.company?.name
                  ? item?.taskerOpenMarket?.company?.name
                  : (tagNotAvailable | translate)
              }}
            </ng-container>

            <!-- service assigned -->
            <ng-container
              *ngIf="
                item?.type === dealsTypeRef.Team ||
                (item?.type === dealsTypeRef.OpenMarket &&
                  !item?.taskerOpenMarket)
              "
            >
              <ng-container *ngIf="item?.taskerTeam">
                <div
                  *ngIf="
                    isArray(item?.taskerTeam) && item.taskerTeam.length > 0
                  "
                >
                  <ng-container
                    *ngFor="let task of item.taskerTeam; let i = index"
                  >
                    <ng-container *ngIf="i < 2">
                      <img
                        [src]="
                          getUserImgByName(
                            item?.taskerTeam ? item?.taskerTeam[i].name : null,
                            'assets/icons/table-user-icon.svg'
                          )
                        "
                        style="
                          margin-right: 4px;
                          height: 20px;
                          width: 20px;
                          border-radius: 50%;
                        "
                      />
                    </ng-container>
                  </ng-container>
                  {{
                    item.taskerTeam.length > 1
                      ? '(+' + item.taskerTeam.length + ')'
                      : item.taskerTeam[0].name
                  }}
                  <ng-container *ngIf="item.taskerTeam.length > 1">
                    <div
                      class="owners-link"
                      (click)="
                        $event.stopPropagation();
                        openUsersDialog(item.taskerTeam, 'task.owners')
                      "
                    >
                      {{ 'task.owners' | translate }}
                    </div>
                  </ng-container>
                </div>
                <div *ngIf="!isArray(item?.taskerTeam)">
                  <img src="/assets/icons/table-user-icon.svg" />
                  {{ item.taskerTeam?.name }}
                </div>

                <div
                  *ngIf="
                    isArray(item?.taskerTeam) && item.taskerTeam.length === 0
                  "
                >
                  --
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="item?.type === dealsTypeRef.Vendor">
              <img
                [src]="
                  getUserImgByName(
                    item?.taskerTeam?.length > 0
                      ? item?.taskerTeam[0].name
                      : null,
                    'assets/icons/table-user-icon.svg'
                  )
                "
                style="margin-right: 4px; height: 20px; width: 20px"
              />
              {{
                item?.taskerVendor?.name
                  ? item?.taskerVendor?.name
                  : (tagNotAvailable | translate)
              }}
            </ng-container>
          </mat-cell>

          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'expense_vendor'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.vendor_expense' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.vendorObj?.name
                ? item?.vendorObj?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'expenseVendorObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.vendor_expense' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.vendorObj?.name
                ? item?.vendorObj?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'expense_project'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.expense_project' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.projectObj?.name ? item?.projectObj?.name : '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'expenseProjectObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.expense_project' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.projectObj?.name
                ? item?.projectObj?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'urgency'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.urgency' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="item.urgency"
              title="El item es Urgente"
              class="material-icons urgency"
            >
              error
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'chat'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            Chat
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'title'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ titleTag | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.title ? item.title : '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'units'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.units' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item.units }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'description'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.description' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            class="clamp-2"
            #tooltip="matTooltip"
            [matTooltip]="
              item.description && item.description.length > 300
                ? (item.description | slice: 0:300) + '...'
                : item.description
            "
            [matTooltipPosition]="position.value"
          >
            {{ item?.description ? item.description : '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'asset_id'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.asset_id' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ hasKeys(item.asset_id) ? item.asset_id.name : 'Sin assets' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container
          [matColumnDef]="columnPrefix + 'asset'"
          *ngIf="type !== 'activity'"
        >
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.asset' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item.asset?.name ? item.asset.name : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container
          [matColumnDef]="columnPrefix + 'asset'"
          *ngIf="type === 'activity'"
        >
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.asset' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item.asset ? item.asset.name : (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'assets'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.asset' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              title="Direcciones"
              [items]="mapAssets(item?.assets)"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'assetsObj'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.asset' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              title="Direcciones"
              [items]="mapAssets(item?.assetsObj)"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'user_assets'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.asset' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <mat-chip-list>
              <ng-container
                *ngIf="getUserAssets(item).length <= 2; else showAll"
              >
                <mat-chip
                  class="example-box"
                  cdkDrag
                  *ngFor="let asset of getUserAssets(item)"
                >
                  {{ asset.name }}
                </mat-chip>
              </ng-container>
              <ng-template #showAll>
                <mat-chip
                  class="example-box"
                  cdkDrag
                  (click)="showModalAssets(item)"
                >
                  {{ 'table.colum.asset_see_all' | translate }} ({{
                    getUserAssets(item).length
                  }})
                </mat-chip>
              </ng-template>
            </mat-chip-list>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'vendor_id'">
          <mat-header-cell *matHeaderCellDef mat-header-cell>
            {{ 'table.colum.vendor' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.vendor_id ? item.vendor_id?.name : 'Sin Vendor' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'ownerObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.in_charge' | translate }}
          </mat-header-cell>

          <!-- Tasks manager -->
          <mat-cell *matCellDef="let item" mat-cell class="ownerObj">
            <ng-container *ngIf="item.ownerObj">
              <div *ngIf="isArray(item?.ownerObj) && item.ownerObj.length > 0">
                <ng-container *ngFor="let task of item.ownerObj; let i = index">
                  <ng-container *ngIf="i < 2">
                    <img
                      [src]="
                        getUserImgByName(
                          item?.ownerObj ? item?.ownerObj[i].name : null,
                          'assets/icons/table-user-icon.svg'
                        )
                      "
                      style="
                        margin-right: 4px;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                      "
                    />
                  </ng-container>
                </ng-container>
                {{
                  item.ownerObj.length > 1
                    ? '(+' + item.ownerObj.length + ')'
                    : item.ownerObj[0].name
                }}
                <ng-container *ngIf="item.ownerObj.length > 1">
                  <div
                    class="owners-link"
                    (click)="
                      $event.stopPropagation();
                      openUsersDialog(item.ownerObj, 'task.owners')
                    "
                  >
                    {{ 'task.owners' | translate }}
                  </div>
                </ng-container>
              </div>
              <div *ngIf="!isArray(item?.ownerObj)">
                <img src="/assets/icons/table-user-icon.svg" />
                {{ item.ownerObj?.name }}
              </div>
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container
          [matColumnDef]="columnPrefix + 'owner'"
          *ngIf="type !== 'activity'"
        >
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.in_charge' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item.owner?.name ? item.owner.name : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container
          [matColumnDef]="columnPrefix + 'owner'"
          *ngIf="type === 'activity'"
        >
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.vendor' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item.owner?.name ? item.owner.name : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'createdBy'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.created_by' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.createdBy?.name
                ? item?.createdBy.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- Service Reporter -->
        <ng-container [matColumnDef]="columnPrefix + 'reporter'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.reporter' | translate }}
          </mat-header-cell>

          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <img
              [src]="
                getUserImgByName(
                  item?.reporterObj ? item?.reporterObj.name : null,
                  'assets/icons/table-user-icon.svg'
                )
              "
              style="margin-right: 4px; height: 20px; width: 20px"
            />
            {{
              item?.reporterObj?.name
                ? item?.reporterObj?.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'manager_id'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.manager' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{ asset?.ownerObj ? asset?.ownerObj?.name : 'No disponible' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'tax_id'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.tax_id' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let billing"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              billing?.tax_id ? billing?.tax_id : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'company_id'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.company' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let billing"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              billing?.company_id
                ? billing?.company_id
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'billing_preferences'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.billing_preferences' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let billing"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              billing?.billing_preferences
                ? billing?.billing_preferences
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'billing_address'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.billing_address' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let billing"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              billing?.billing_address
                ? billing?.billing_address
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'email'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.email' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <ng-container *ngIf="type != 'finals'">{{
              item?.email || (tagNotAvailable | translate)
            }}</ng-container>
            <app-chips-resume
              *ngIf="type === 'finals'"
              [type]="'info'"
              [items]="
                item?.emailArr?.length
                  ? item?.emailArr
                  : item?.email
                  ? [item?.email]
                  : null
              "
              [title]="'general.emailsTag' | translate"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'contactName'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.contactName' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.contactName || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'billingProfile'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.billing_profile' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.billingProfile
                ? item.billingProfile.name
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'online'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.online' | translate }}
            <span class="material-icons pointer info" (click)="info('info2')">
              info
            </span>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="
                !item?.online || (!item?.linkedUser && !item?.linkedVendor)
              "
            >
              No
            </span>
            <span
              *ngIf="item?.online && (item?.linkedUser || item?.linkedVendor)"
              class="profile"
            >
              <!-- (click)="watchClick($event, item)" -->
              {{ 'table.colum.watchOnline' | translate }}
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'address'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.address' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{ asset?.address || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'addressAsset'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.address' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.asset?.name ||
                item?.assetObj?.name ||
                (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'assetObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.address' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.assetObj?.name || '--' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'verticals'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.verticals' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.verticals ? mapVerticals(item?.verticals) : []"
              [title]="'Verticals'"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'cities'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.cities' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.coverage ? item?.coverage : []"
              [title]="'Provincias'"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'usersProject'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.users' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.users ? mapUsersProject(item?.users) : []"
              [title]="'Usuarios'"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'usersObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.users' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.usersObj ? mapUsersProject(item?.usersObj) : []"
              [title]="'Usuarios'"
            >
            </app-chips-resume>
          </mat-cell>

          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'usersRates'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.users' | translate }}
          </mat-header-cell>

          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <ng-container *ngIf="item?.usersObj?.length <= 2; else showAll">
              <mat-chip-list>
                <mat-chip
                  class="example-box"
                  cdkDrag
                  *ngFor="let user of item?.usersObj"
                >
                  {{ user.name }}
                </mat-chip>
              </mat-chip-list>
            </ng-container>
            <ng-template #showAll>
              <mat-chip-list> </mat-chip-list>
              <mat-chip
                class="example-box"
                cdkDrag
                (click)="showModalUser(item?.usersObj)"
              >
                {{ 'table.colum.asset_see_all' | translate }} ({{
                  item?.usersObj.length
                    ? '+' + item?.usersObj.length
                    : (tagNotAvailable | translate)
                }})
              </mat-chip>
            </ng-template>
          </mat-cell>

          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'users'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.users' | translate }}
          </mat-header-cell>

          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.users ? mapUsers(item?.users) : []"
              [title]="'Usuarios'"
            >
            </app-chips-resume>
          </mat-cell>

          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'ownersObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.users' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="
                item?.ownersObj
                  ? mapOwnersFromExpenseEndpoint(item?.ownersObj)
                  : []
              "
              [title]="'Usuarios'"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'finals'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.final' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="item?.finals ? mapFinals(item?.finals) : []"
              [title]="'table.colum.final' | translate"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'finalsObj'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.final' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <app-chips-resume
              [items]="
                item?.finalsObj ? mapFinalsObjNameSurname(item?.finalsObj) : []
              "
              [title]="'table.colum.final' | translate"
            >
            </app-chips-resume>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'phone'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.phone' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            <ng-container *ngIf="type !== 'person'">
              {{ asset?.phone || (tagNotAvailable | translate) }}
            </ng-container>
            <ng-container *ngIf="type === 'person'">
              {{ asset?.phone || '' }}
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'phoneNumber'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.phone' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{ getPhoneNumber(asset) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'biddings_count'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.biddings_count' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <mat-chip-list>
              <mat-chip>
                {{ item?.bidding_count || (tagNotAvailable | translate) }}
              </mat-chip>
            </mat-chip-list>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container
          *ngFor="let property of customProperties; let i = index"
          [matColumnDef]="property.name"
        >
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ property?.name }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            <!-- {{ item?.customProperties | json }} -->
            <ng-container *ngIf="hasProperty(item, property._id)">
              <!-- type number -->
              <ng-container
                *ngIf="
                  !isArray(getCustomPropertyValue(item, property._id)) &&
                  isNumber(getCustomPropertyValue(item, property._id)) &&
                  property?.type == 'NUMBER'
                "
              >
                {{
                  checkCustomValue(getCustomPropertyValue(item, property._id))
                }}
              </ng-container>

              <!-- type date -->
              <ng-container
                *ngIf="
                  isDate(getCustomPropertyValue(item, property._id)) &&
                  property?.type == 'DATE'
                "
              >
                {{ getCustomPropertyValue(item, property._id) | dateParse }}
              </ng-container>

              <!-- type boolean -->
              <ng-container
                *ngIf="
                  isBoolean(getCustomPropertyValue(item, property._id)) &&
                  property?.type == 'BOOLEAN'
                "
              >
                {{
                  getCustomPropertyValue(item, property._id) === 'true'
                    ? ('general.yes' | translate)
                    : 'No'
                }}
              </ng-container>

              <!-- type select -->
              <ng-container
                *ngIf="
                  isArray(getCustomPropertyValue(item, property._id)) &&
                  property?.type == 'SELECT'
                "
              >
                <app-chips-resume
                  [items]="getCustomPropertyValue(item, property._id)"
                  [title]="property?.name"
                >
                </app-chips-resume>
              </ng-container>

              <!-- type string -->
              <ng-container *ngIf="property?.type == 'STRING'">
                {{ getCustomPropertyValue(item, property._id) }}
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!hasProperty(item, property._id)">
              --
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'bill'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.bill' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{ asset?.bill || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'billing_profile'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.billing_profile' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{ asset?.billing_profile || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'reference'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.reference' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{ asset?.reference || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'checklist'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'Checklist' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.title || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'startDate'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'Start Date' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.startDate
                ? (item?.startDate?.replace('Z', '') | date: 'medium')
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'price'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.price' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{ asset?.price || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'date'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.date' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            <ng-container
              *ngIf="
                type === 'clockHours' ||
                type === 'adminClockHours' ||
                type === 'clockHoursDetail'
              "
            >
              <div class="clock-hour-dates">
                <span
                  [ngClass]="{
                    'alt-badge': today === getDate(asset?.date)
                  }"
                  [ngStyle]="
                    today === getDate(asset?.date) && {
                      'background-color': '#F2F9FF',
                      'margin-left': '-16px'
                    }
                  "
                >
                  {{ asset?.date ? (getDate(asset?.date) | titlecase) : '-' }}
                  <span class="today" *ngIf="today === getDate(asset?.date)">{{
                    'clockHour.today' | translate
                  }}</span>
                </span>
                <div
                  class="absence-badge"
                  *ngIf="asset.absence"
                  [ngSwitch]="asset.absence.type"
                >
                  <span>
                    <svg
                      [ngClass]="getClassForAbsenceType(asset.absence.type)"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.44754 2.57099C4.18719 2.31065 3.76508 2.31065 3.50473 2.57099L2.1714 3.90433C1.91105 4.16468 1.91105 4.58679 2.1714 4.84714C2.43175 5.10749 2.85386 5.10749 3.11421 4.84714L4.44754 3.5138C4.70789 3.25345 4.70789 2.83134 4.44754 2.57099Z"
                        fill="#8861FA"
                      />
                      <path
                        d="M13.7809 2.57099C13.5205 2.31065 13.0984 2.31065 12.8381 2.57099C12.5777 2.83134 12.5777 3.25345 12.8381 3.5138L14.1714 4.84714C14.4317 5.10749 14.8539 5.10749 15.1142 4.84714C15.3746 4.58679 15.3746 4.16468 15.1142 3.90433L13.7809 2.57099Z"
                        fill="#8861FA"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.6428 3.0424C5.32909 3.0424 2.6428 5.72869 2.6428 9.0424C2.6428 12.3561 5.32909 15.0424 8.6428 15.0424C11.9565 15.0424 14.6428 12.3561 14.6428 9.0424C14.6428 5.72869 11.9565 3.0424 8.6428 3.0424ZM7.11421 6.57099C6.85386 6.31064 6.43175 6.31064 6.1714 6.57099C5.91105 6.83134 5.91105 7.25345 6.1714 7.5138L7.69999 9.0424L6.1714 10.571C5.91105 10.8313 5.91105 11.2535 6.1714 11.5138C6.43175 11.7742 6.85386 11.7742 7.11421 11.5138L8.6428 9.98521L10.1714 11.5138C10.4317 11.7742 10.8539 11.7742 11.1142 11.5138C11.3746 11.2535 11.3746 10.8313 11.1142 10.571L9.58561 9.0424L11.1142 7.5138C11.3746 7.25345 11.3746 6.83134 11.1142 6.57099C10.8539 6.31064 10.4317 6.31064 10.1714 6.57099L8.6428 8.09959L7.11421 6.57099Z"
                        fill="#8861FA"
                      />
                    </svg>
                  </span>
                  <span class="absence-text" *ngSwitchCase="'0'">{{
                    'table.content.holidays' | translate
                  }}</span>
                  <span class="absence-text" *ngSwitchCase="'1'">{{
                    'table.content.sickness' | translate
                  }}</span>
                  <span class="absence-text" *ngSwitchCase="'2'">{{
                    'table.content.maternity' | translate
                  }}</span>
                  <span class="absence-text" *ngSwitchCase="'3'">{{
                    'table.content.familySickness' | translate
                  }}</span>
                  <span class="absence-text" *ngSwitchCase="'4'">{{
                    'table.content.otherAbsence' | translate
                  }}</span>
                  <span class="absence-text" *ngSwitchDefault>{{
                    tagNotAvailable | translate
                  }}</span>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                type !== 'clockHours' &&
                type !== 'adminClockHours' &&
                type !== 'clockHoursAbsence' &&
                type !== 'clockHoursDetail'
              "
            >
              ? (getDate(asset?.date) | dateParse) : (tagNotAvailable |
              translate)
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'startHour'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.startDate' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            {{
              asset?.startDate
                ? asset?.startDate?.hour + ':' + asset?.startDate?.minute
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'endHour'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.endDate' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            END
            {{
              asset?.endDate
                ? asset?.endDate?.hour + ':' + asset?.endDate?.minute
                : (tagNotAvailable | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'entrance'">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            mat-header-cell
            [ngClass]="{ 'admin-entrance': type === 'adminClockHours' }"
          >
            {{ 'table.colum.entrance' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
            style="display: block; position: relative"
            [ngClass]="{ 'admin-entrance': type === 'adminClockHours' }"
          >
            <ng-container
              *ngIf="
                (type === 'clockHours' || type === 'clockHoursDetail') &&
                asset?.histories?.length > 0
              "
            >
              <div *ngFor="let history of asset.histories" style="width: 100%">
                <span class="alt-badge">{{
                  history?.startDate || '--:--'
                }}</span>
                <span class="alt-badge">{{ history?.endDate || '--:--' }}</span>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                (type === 'clockHours' || type === 'clockHoursDetail') &&
                !asset?.histories?.length
              "
            >
              <div style="width: 100%">
                <span class="alt-badge">--:--</span>
                <span class="alt-badge">--:--</span>
              </div>
            </ng-container>

            <ng-container *ngIf="type === 'adminClockHours'">
              <div style="line-height: 48px">
                {{ asset?.histories?.length }}
              </div>
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'entrees'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'general.in' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            <p [innerHTML]="getEntrances(asset)"></p>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'note'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'general.notes' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            <ng-container *ngIf="asset?.notes?.length > 0">
              {{ asset.notes[0]?.text || asset.notes[0] }}
            </ng-container>

            <ng-container *ngIf="asset?.note">
              {{ asset.note }}
            </ng-container>

            <ng-container *ngIf="!asset?.notes?.length && !asset?.note">
              --
            </ng-container>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'totalDays'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.totalDays' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.totalDays || (tagNotAvailable | translate) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'duration'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.duration' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
            style="font-weight: 600"
          >
            {{
              asset?.histories?.length
                ? getClockHoursDuration(asset)
                : '00hr 00m'
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'durationAverage'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.duration' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
            style="font-weight: 600"
          >
            {{ parseAverageTimeWorked(asset) }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container *ngIf="attachInfo" matColumnDef="romadefaultcolumninfo">
          <mat-header-cell *matHeaderCellDef mat-header-cell class="column-xs">
            <span class="material-icons pointer info" (click)="info('info')">
              info
            </span>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            class="column-xs"
            [matTooltip]="lineTooltip(item)"
          >
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'role'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.role' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let asset"
            mat-cell
            [matTooltip]="lineTooltip(asset)"
          >
            <mat-chip-list>
              <mat-chip *ngIf="asset?.role == 'ADMIN_ROLE'; else isUser">
                {{ 'general.user_admin' | translate | titlecase }}
              </mat-chip>
              <ng-template #isUser>
                <mat-chip>
                  {{ 'general.user' | translate | titlecase }}
                </mat-chip>
              </ng-template>
            </mat-chip-list>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'typeDeal'">
          <mat-header-cell *matHeaderCellDef mat-sort-header mat-header-cell>
            {{ 'table.colum.type' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            mat-cell
            [matTooltip]="lineTooltip(item)"
          >
            {{
              item?.type === dealsTypeRef.OpenMarket
                ? ('deals.openMarket' | translate)
                : item?.type === dealsTypeRef.Vendor
                ? ('deals.ownVendor' | translate)
                : ('deals.internalTeam' | translate)
            }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'taskActions'">
          <mat-header-cell *matHeaderCellDef class="column-xs">
            {{ 'table.colum.actions' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let item"
            class="column-xs"
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="!item?.checked"
              class="material-icons"
              title="Marcar como realizada"
              (click)="markClick($event, item)"
            >
              done
            </span>

            <span *ngIf="item?.checked" class="done"> Realizada </span>
            <span
              romaClickDelete
              romaProFeature
              [featureUser]="featureUsedDeleteByType"
              [item]="item"
              (wantDelete)="deleteClick($event)"
            >
              <span
                class="material-icons"
                [title]="'table.colum.delete' | translate"
                [item]="item"
                [type]="type"
              >
                delete
              </span>
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'hour'">
          <mat-header-cell *matHeaderCellDef class="column-xs">
            {{ 'table.colum.hour' | translate }}
          </mat-header-cell>

          <mat-cell
            *matCellDef="let item"
            class="column-xs"
            [matTooltip]="lineTooltip(item)"
          >
            {{ item?.hour || '--' }}
          </mat-cell>

          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="columnPrefix + 'hasBeenNotified'">
          <mat-header-cell *matHeaderCellDef class="column-xs">
            {{ 'table.colum.notified' | translate }}
          </mat-header-cell>

          <mat-cell
            *matCellDef="let item"
            class="column-xs"
            [matTooltip]="lineTooltip(item)"
          >
            <span
              *ngIf="markAction && !item?.hasBeenNotified"
              class="material-icons"
              title="Marcar como realizada"
              (click)="markClick($event, item)"
            >
              done
            </span>

            <span *ngIf="markAction && item?.hasBeenNotified" class="done">
              Realizada
            </span>
          </mat-cell>

          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container [matColumnDef]="'romadefaultcolumnnotified'">
          <mat-header-cell *matHeaderCellDef style="min-width: 100px">
            {{ 'table.colum.notified' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let item" style="min-width: 100px">
            <span
              *ngIf="markAction && !item?.hasBeenNotified"
              class="material-icons"
              title="Marcar como realizada"
              (click)="markClick($event, item)"
            >
              done
            </span>

            <span *ngIf="markAction && item?.hasBeenNotified" class="done">
              Realizada
            </span>
          </mat-cell>

          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="romadefaultcolumnactions">
          <mat-header-cell
            *matHeaderCellDef
            class="column-xs"
            [ngClass]="{
              'lg-actions':
                type === 'clockHours' ||
                type === 'clockHoursDetail' ||
                type === 'adminClockHours' ||
                type === 'clockHoursAbsence'
            }"
          >
            {{ 'table.colum.actions' | translate }}
            <div
              class="table-custom-properties-col px-3 before"
              *ngIf="showCustomProperties && (attachSettings || attachInfo)"
            >
              <!-- Info icon -->
              <span
                *ngIf="attachInfo"
                class="material-icons pointer info icon-info-with-add-custom-column"
                (click)="info('info')"
              >
                info
              </span>
              <!-- Info icon -->
              <div
                *ngIf="
                  attachSettings &&
                  (moreFields?.length > 0 || customFields?.length > 0)
                "
                [matMenuTriggerFor]="menu"
                matTooltip="{{ 'general.tooltipAddFields' | translate }}"
                matTooltipPosition="below"
                matTooltipClass="custom_add_tooltip"
                class="add-columns-btn ml-30px"
                [ngClass]="{ 'add-columns-btn-active': tooltipAddMore }"
                (click)="menuClick()"
              >
                <!-- <span
            class="material-icons pointer info"
            (click)="$event.stopPropagation(); info('info')"
          >
            info
          </span> -->
                <img src="assets/icons/gl_plus-gray.svg" class="img-plus" />
                {{ 'general.more' | translate }}
              </div>
            </div>
          </mat-header-cell>

          <mat-cell
            *matCellDef="let item; let i = index"
            class="column-xs"
            [matTooltip]="lineTooltip(item)"
            [ngClass]="{
              'lg-actions':
                type === 'clockHours' ||
                type === 'clockHoursDetail' ||
                type === 'adminClockHours'
            }"
          >
            <ng-container
              *ngIf="
                type !== 'deal' &&
                type !== 'person' &&
                type !== 'clockHours' &&
                type !== 'adminClockHours' &&
                type !== 'clockHoursAbsence' &&
                type !== 'clockHoursDetail' &&
                type !== 'activities' &&
                type !== 'sales' &&
                type !== 'activity' &&
                !item.deleted
              "
            >
              <img
                class="more-actions"
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                romaClickStopPropagation
                src="/assets/icons/gl_more-vertical.svg"
              />
              <mat-menu
                class="custom-template-actions actions-menu"
                #menu="matMenu"
                xPosition="before"
              >
                <button
                  class="delete"
                  mat-menu-item
                  romaClickDelete
                  [item]="item"
                  romaProFeature
                  [featureUser]="featureUsedDeleteByType"
                  (wantDelete)="deleteClick($event, i)"
                >
                  {{ 'general.deleteAlt' | translate }}
                </button>
              </mat-menu>
            </ng-container>
            <ng-container *ngIf="type === 'clockHours'">
              <button
                class="action-button add-button"
                [disabled]="!compareCurrentDay(item?.date)"
                *ngIf="!item?.histories?.length && !item?._id"
              >
                {{ 'table.colum.register' | translate }}
              </button>

              <button
                *ngIf="item?._id"
                romaClickDelete
                class="action-button delete-button"
                title="Eliminar"
                [item]="item"
                [type]="type"
                romaProFeature
                subtitle="deleteModal.body_final"
                [featureUser]="featureRefUser.ClockHour.delete"
                (wantDelete)="deleteClick($event)"
              >
                <img
                  class="trash"
                  src="/assets/icons/gl_trash.svg"
                  alt="trash"
                />
                {{ 'table.colum.delete' | translate }}
              </button>
            </ng-container>
            <ng-container
              *ngIf="
                type === 'clockHoursAbsence' ||
                type === 'adminClockHours' ||
                type === 'clockHoursDetail'
              "
            >
              <img
                romaClickStopPropagation
                [matMenuTriggerFor]="menu"
                class="more-actions"
                src="/assets/icons/gl_more-vertical.svg"
                *ngIf="item.showEdit || item.showDelete"
              />
              <img
                romaClickStopPropagation
                class="more-actions"
                src="/assets/icons/gl_more-vertical.svg"
                *ngIf="!item.showEdit && !item.showDelete"
              />
              <mat-menu
                class="custom-template-actions actions-menu"
                #menu="matMenu"
                xPosition="before"
              >
                <button
                  *ngIf="item.showEdit"
                  class="edit"
                  mat-menu-item
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  [featureUser]="
                    type === 'clockHoursAbsence'
                      ? featureRefUser.ClockHourAbsence.update
                      : featureRefUser.ClockHour.update
                  "
                  (clicked)="editMenuClick($event, item)"
                  [disabled]="
                    type === 'clockHoursDetail' &&
                    !compareCurrentDay(item?.date)
                  "
                >
                  {{ 'general.edit' | translate }}
                </button>
                <button
                  *ngIf="item.showDelete"
                  class="delete"
                  romaClickDelete
                  mat-menu-item
                  title="Eliminar"
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  subtitle="deleteModal.body_final"
                  [featureUser]="
                    type === 'clockHoursAbsence'
                      ? featureRefUser.ClockHourAbsence.delete
                      : featureRefUser.ClockHour.delete
                  "
                  (wantDelete)="deleteClick($event)"
                  [disabled]="
                    type === 'clockHoursDetail' &&
                    !compareCurrentDay(item?.date)
                  "
                >
                  {{ 'general.deleteAlt' | translate }}
                </button>
              </mat-menu>
            </ng-container>

            <ng-container *ngIf="type === 'person' && showVerticalMenu">
              <img
                class="more-actions"
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                romaClickStopPropagation
                src="/assets/icons/gl_more-vertical.svg"
              />
              <mat-menu
                class="custom-template-actions actions-menu"
                #menu="matMenu"
                xPosition="before"
              >
                <button
                  *ngIf="
                    type === 'person' && item._id !== user._id && !item.deleted;
                    else deleteAdmin
                  "
                  class="delete"
                  romaClickDelete
                  mat-menu-item
                  [title]="'table.colum.delete' | translate"
                  [item]="item"
                  romaProFeature
                  [featureUser]="featureRefUser.User.delete"
                  (wantDelete)="deleteClick($event)"
                >
                  {{ 'general.deleteAlt' | translate }}
                </button>
              </mat-menu>

              <ng-template #deleteAdmin>
                <button
                  class="delete"
                  style="opacity: 0.5"
                  disabled="true"
                  mat-menu-item
                  romaProFeature
                >
                  {{ 'general.deleteAlt' | translate }}
                </button>

                <!--

                <div class="left-info" style="padding: 6px; text-align: center;">
                   {{ 'billing.admNoDelete' | translate }}
                </div>


                <button
                  class="delete"
                  romaClickDelete
                  mat-menu-item
                  romaProFeature
                  [featureUser]="featureRefUser.User.delete"
                  [isAdmin]="true"
                >
                  {{ 'general.deleteAlt' | translate }}
                </button>
                -->
              </ng-template>
            </ng-container>

            <span
              *ngIf="
                (type === 'assets' ||
                  type === 'finals' ||
                  type === 'projects' ||
                  type === 'equipments' ||
                  type === 'person' ||
                  type === 'expenses') &&
                item.deleted
              "
              class="material-icons"
              title="Restaurar"
              [item]="item"
              [type]="type"
              romaProFeature
              [featureUser]="featureUsedUpdateByType"
              romaClickRestore
              (wantRestore)="restoreClick($event)"
            >
              cached
            </span>

            <ng-container *ngIf="type === 'deal'">
              <button
                romaClickStopPropagation
                class="btn-download btn-restore more-action"
                romaProFeature
                [featureUser]="featureUsedUpdateByType"
                (clicked)="restoreCancelDeal(item)"
                *ngIf="item.cancelled"
              >
                <img src="assets/icons/gl_trash_restore.svg" />
                {{ 'general.restoreButton' | translate }}
              </button>
              <img
                *ngIf="
                  checkDealDeleteCondition(item) || (isAdmin && !item.cancelled)
                "
                class="more-actions"
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                romaClickStopPropagation
                src="/assets/icons/gl_more-vertical.svg"
              />
              <mat-menu
                class="custom-template-actions actions-deals actions-menu left-info"
                #menu="matMenu"
                xPosition="before"
              >
                <button
                  *ngIf="!item.isFinished"
                  style="text-align: left"
                  mat-menu-item
                  title="Marcar como finalizado"
                  [item]="item"
                  [type]="type"
                  (clicked)="finishDeal(item)"
                  romaProFeature
                  [featureUser]="featureRefUser.Deal.update"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.4799 3.92748C12.8015 4.19503 12.8416 4.66827 12.5695 4.98447L6.97631 11.4845C6.83608 11.6474 6.63188 11.7439 6.41487 11.7497C6.19787 11.7556 5.98862 11.6702 5.83956 11.515L2.95821 8.51498C2.66898 8.21384 2.68278 7.73916 2.98902 7.45476C3.29526 7.17035 3.77798 7.18391 4.06721 7.48505L6.36284 9.87521L11.405 4.01556C11.6771 3.69935 12.1584 3.65992 12.4799 3.92748Z"
                      fill="#6A79A0"
                    />
                  </svg>

                  {{ 'hiring.type.finishService' | translate }}
                </button>
                <button
                  *ngIf="item.isFinished"
                  style="text-align: left"
                  mat-menu-item
                  title="Marcar como no finalizado"
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  [featureUser]="featureRefUser.Deal.update"
                  (clicked)="restoreFinishDeal(item)"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.4799 3.92748C12.8015 4.19503 12.8416 4.66827 12.5695 4.98447L6.97631 11.4845C6.83608 11.6474 6.63188 11.7439 6.41487 11.7497C6.19787 11.7556 5.98862 11.6702 5.83956 11.515L2.95821 8.51498C2.66898 8.21384 2.68278 7.73916 2.98902 7.45476C3.29526 7.17035 3.77798 7.18391 4.06721 7.48505L6.36284 9.87521L11.405 4.01556C11.6771 3.69935 12.1584 3.65992 12.4799 3.92748Z"
                      fill="#6A79A0"
                    />
                  </svg>
                  {{ 'hiring.type.restoreService' | translate }}
                </button>
                <button
                  style="text-align: left"
                  mat-menu-item
                  title="Editar"
                  [item]="item"
                  [type]="type"
                  (clicked)="editClick($event, item)"
                  romaProFeature
                  [featureUser]="featureRefUser.Deal.update"
                >
                  <img src="assets/icons/kanban/edit.svg" class="icon-menu" />
                  {{ 'general.edit' | translate }}
                </button>
                <button
                  style="text-align: left"
                  mat-menu-item
                  title="Mover a estado"
                  [item]="item"
                  [type]="type"
                  [matMenuTriggerFor]="listOfStatuses"
                >
                  <img src="assets/icons/kanban/move.svg" class="icon-menu" />
                  {{ 'kanban.moveToState' | translate }}
                </button>

                <mat-menu
                  class="panel-choose panel-move"
                  #listOfStatuses="matMenu"
                  xPosition="before"
                >
                  <ng-container *ngFor="let stat of statusData">
                    <button
                      *ngIf="stat.name != item?.status?.name"
                      mat-menu-item
                      style="text-align: left"
                      romaProFeature
                      [featureUser]="featureRefUser.Deal.update"
                      (clicked)="changeStateDeal(item, stat)"
                    >
                      {{ stat.name }}
                    </button>
                  </ng-container>
                </mat-menu>

                <button
                  style="text-align: left"
                  mat-menu-item
                  title="Cancelar"
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  [featureUser]="featureRefUser.Deal.update"
                  (clicked)="
                    deleteClickDealNoModal(item?._id); trigger.closeMenu()
                  "
                >
                  <img src="assets/icons/kanban/cancel.svg" class="icon-menu" />
                  {{ 'general.cancelDeal' | translate }}
                </button>
                <button
                  class="delete"
                  style="text-align: left"
                  mat-menu-item
                  title="Eliminar"
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  [featureUser]="featureRefUser.Deal.delete"
                  (clicked)="opdenModalDeleteDeal(item)"
                >
                  <img src="assets/icons/kanban/delete.svg" class="icon-menu" />
                  {{ 'general.deleteAlt' | translate }}
                </button>
              </mat-menu>
              <ng-container *ngIf="checkDealsApproveConditions(item)">
                <span
                  romaClickAproveDeal
                  class="material-icons icon-aprove"
                  title="Aprobar Servicio"
                  [item]="item"
                  [type]="type"
                  [approve]="true"
                  (wantAproveDeal)="approveDealClic($event)"
                >
                  done_all
                </span>
                <span
                  romaClickAproveDeal
                  class="material-icons"
                  title="Rechazar Servicio"
                  [item]="item"
                  [type]="type"
                  [approve]="false"
                  (wantAproveDeal)="approveDealClic($event)"
                >
                  close
                </span>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="type === 'sales'">
              <img
                class="more-actions"
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                romaClickStopPropagation
                src="/assets/icons/gl_more-vertical.svg"
              />
              <mat-menu
                class="custom-template-actions actions-deals actions-menu left-info"
                #menu="matMenu"
                xPosition="before"
              >
                <button
                  style="text-align: left"
                  mat-menu-item
                  title="Abrir"
                  [item]="item"
                  [type]="type"
                  (click)="openSale(item)"
                >
                  <img
                    src="assets/icons/gl_eye-on-gray.svg"
                    class="icon-menu"
                  />
                  {{ 'general.open' | translate }}
                </button>

                <button
                  mat-menu-item
                  (click)="printSale(item)"
                  class="printing-option"
                >
                  <img [src]="'assets/icons/gl_print.svg'" class="icon-menu" />
                  {{ 'general.print' | translate }}
                  <mat-progress-spinner
                    *ngIf="isPrinting"
                    class="loading-spinner"
                    mode="indeterminate"
                    [diameter]="16"
                  ></mat-progress-spinner>
                </button>

                <button
                  style="display: flex; align-items: center; gap: 3px"
                  mat-menu-item
                  [title]="'fileUpload.download' | translate"
                  [item]="item"
                  [type]="type"
                  (click)="downloadSale(item)"
                >
                  <img
                    *ngIf="!downloadSpinner || ticketSelected !== item._id"
                    src="assets/icons/gl_download-gray.svg"
                    class="icon-menu"
                  />
                  <mat-progress-spinner
                    *ngIf="downloadSpinner && ticketSelected === item._id"
                    class="loading-spinner"
                    style="margin-right: 10px; height: 25px"
                    mode="indeterminate"
                    [diameter]="20"
                  ></mat-progress-spinner>
                  {{ 'fileUpload.download' | translate }}
                </button>
              </mat-menu>
            </ng-container>

            <ng-container *ngIf="type === 'activity' || type === 'activities'">
              <ng-container *ngIf="item?.deleted">
                <button
                  class="btn-download btn-restore"
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  [featureUser]="featureUsedUpdateByType"
                  romaClickRestore
                  (wantRestore)="restoreClick($event)"
                >
                  <img src="assets/icons/gl_trash_restore.svg" />
                  {{ 'activity.buttonRestore' | translate }}
                </button>
              </ng-container>
              <ng-container *ngIf="!item?.deleted">
                <img
                  class="more-actions"
                  #menuTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="menu"
                  romaClickStopPropagation
                  src="/assets/icons/gl_more-vertical.svg"
                />
              </ng-container>

              <mat-menu
                class="custom-template-actions actions-menu font-300"
                #menu="matMenu"
                xPosition="before"
              >
                <button
                  style="text-align: left"
                  mat-menu-item
                  [title]="'general.edit' | translate"
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  [featureUser]="featureRefUser.Activity.update"
                  (clicked)="editClick($event, item)"
                >
                  <img src="assets/icons/kanban/edit.svg" class="edit" />
                  {{ 'general.edit' | translate }}
                </button>
                <button
                  mat-menu-item
                  [title]="
                    item?.hasBeenNotified
                      ? ('activity.markAsUnfinished' | translate)
                      : ('activity.markAsFinished' | translate)
                  "
                  romaProFeature
                  [featureUser]="featureRefUser.Activity.update"
                  (clicked)="finishTask(item)"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="margin-right: 5px"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.4799 3.92748C12.8015 4.19503 12.8416 4.66827 12.5695 4.98447L6.97631 11.4845C6.83608 11.6474 6.63188 11.7439 6.41487 11.7497C6.19787 11.7556 5.98862 11.6702 5.83956 11.515L2.95821 8.51498C2.66898 8.21384 2.68278 7.73916 2.98902 7.45476C3.29526 7.17035 3.77798 7.18391 4.06721 7.48505L6.36284 9.87521L11.405 4.01556C11.6771 3.69935 12.1584 3.65992 12.4799 3.92748Z"
                      fill="#6A79A0"
                    />
                  </svg>
                  {{
                    item?.hasBeenNotified
                      ? ('kanban.buttonMarkAsUnFinished' | translate)
                      : ('kanban.buttonMarkAsFinished' | translate)
                  }}
                </button>
                <button
                  mat-menu-item
                  [title]="'kanban.moveToState' | translate"
                  [matMenuTriggerFor]="menuStateChange"
                  *ngIf="statusNames?.length > 0"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="margin-right: 10px"
                  >
                    <path
                      d="M6.00008 1.3335L10.6667 6.00016L6.00008 10.6668M1.33341 6.00016H10.0001H1.33341Z"
                      stroke="#6A79A0"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {{ 'kanban.moveToState' | translate }}
                </button>
                <button
                  class="delete"
                  mat-menu-item
                  romaClickDelete
                  [title]="'table.colum.delete' | translate"
                  [item]="item"
                  [type]="type"
                  romaProFeature
                  [featureUser]="featureRefUser.Activity.delete"
                  (wantDelete)="deleteClick($event)"
                >
                  <!-- <mat-icon>delete</mat-icon> -->
                  <img class="trash" src="assets/icons/gl_trash_red.svg" />
                  {{ 'general.deleteAlt' | translate }}
                </button>
              </mat-menu>

              <mat-menu
                class="custom-template-actions actions-menu font-300"
                #menuStateChange="matMenu"
                xPosition="before"
              >
                <button
                  *ngIf="item?.statusObj?._id"
                  mat-menu-item
                  [title]="'kanban.withoutColumnTextTask' | translate"
                  romaProFeature
                  [featureUser]="featureRefUser.Activity.update"
                  (clicked)="
                    changeStatus(item, {
                      _id: 'UNASSIGNED',
                      name: null,
                      color: null
                    })
                  "
                >
                  {{ 'kanban.withoutColumnTextTask' | translate }}
                </button>
                <ng-container *ngFor="let status of statusNames">
                  <button
                    *ngIf="item?.statusObj?._id !== status?._id"
                    mat-menu-item
                    [title]="'kanban.buttonMarkAsFinished' | translate"
                    romaProFeature
                    [featureUser]="featureRefUser.Activity.update"
                    (clicked)="changeStatus(item, status)"
                  >
                    {{ status?.name }}
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
          </mat-cell>

          <ng-container>
            <mat-cell *matCellDef="let item">
              <span class="material-icons" title="Restaurar">
                published_with_changes
              </span>
            </mat-cell>
          </ng-container>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="columns; sticky: true"
          class="alt-header"
        ></mat-header-row>

        <mat-row
          *matRowDef="let row; index as rowIndex; columns: columns"
          (click)="checkEditRow($event, row)"
          [ngClass]="{ 'deleted-selected-line': rowIndex === selectedLine }"
        >
        </mat-row>
        <ng-container *ngIf="showTotal">
          <mat-footer-row
            *matFooterRowDef="columns"
            style="font-weight: 500"
          ></mat-footer-row>
        </ng-container>
      </mat-table>
    </div>
  </div>
</div>

<mat-menu class="custom-table-menu" #menu="matMenu" (closed)="onMenuClosed()">
  <mat-list role="list">
    <mat-list-item *ngIf="moreFields?.length">
      <strong>
        {{ 'tasks.more_fields' | translate }}
      </strong>
    </mat-list-item>

    <mat-list-item *ngFor="let field of moreFields">
      <mat-checkbox
        [checked]="isCheckMoreField(field.key)"
        (click)="clickMoreFields(field)"
      >
        {{ field.title }}
      </mat-checkbox>
    </mat-list-item>

    <mat-list-item *ngIf="customFields?.length">
      <strong>{{ 'customProperties.customField' | translate }}</strong>
    </mat-list-item>
    <mat-list-item
      *ngFor="let field of customFields; let i = index"
      role="listitem"
      [attr.data-index]="i"
    >
      <div
        *ngIf="!isLoadingFreeLimits"
        romaProFeature
        [feature]="
          freeLimit?.customProperties?.includes(field._id)
            ? featureRef.SystemPermission.DefaultAllow
            : featureRef.Deal.CustomPropertiesTable
        "
        [options]="{
          className: 'roma-label warning static-right-center'
        }"
        class="wrapper-pro-feature"
        (clicked)="clickCustomField($event, field)"
      >
        <!-- this is because in firefox checked does not work correctly -->
        <mat-checkbox *ngIf="isCheckProperty(field)" [checked]="true">
          {{ field.name }}
        </mat-checkbox>
        <mat-checkbox *ngIf="!isCheckProperty(field)" [checked]="false">
          {{ field.name }}
        </mat-checkbox>
        &nbsp;
        <div class="roma-label warning icon-star-pro-feature">
          <div class="icon">
            <img src="assets/icons/star.svg" />
          </div>
          PRO
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</mat-menu>
