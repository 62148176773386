import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum UnsavedChangesActions {
  onClickOutside = 'onClickOutside',
  onContinueWithoutSave = 'onContinueWithoutSave',
  onSaveAndContinue = 'onSaveAndContinue',
}

@Component({
  selector: 'roma-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss'],
})
export class UnsavedChangesComponent {
  constructor(public dialogRef: MatDialogRef<UnsavedChangesComponent>) {}

  onClickOutside(): void {
    this.dialogRef.close(UnsavedChangesActions.onClickOutside);
  }

  onContinueWithoutSave(): void {
    this.dialogRef.close(UnsavedChangesActions.onContinueWithoutSave);
  }

  onSaveAndContinue(): void {
    this.dialogRef.close(UnsavedChangesActions.onSaveAndContinue);
  }
}
