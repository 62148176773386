<div class="login-container">
  <div class="login-form">
    <div class="logo">
      <img src="../../../../assets/images/logotaclia_2.png" alt="Taclia logo" class="logo-image">
    </div>
    <h2 class="title">{{ isCreate ? ('reset_password.create' | translate) : ('reset_password.reset' | translate) }}</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="password">{{'reset_password.label_1' | translate }}</label>
        <input type="password" id="password" formControlName="password" required>
      </div>
      <div class="form-group">
        <label for="confirmPassword">{{'reset_password.label_2' | translate }}</label>
        <input type="password" id="confirmPassword" formControlName="passwordConfirm" required>
      </div>
      <div class="button-group">
        <button type="submit" class="btn-primary" [disabled]="!form.valid">{{'reset_password.confirm' | translate }}</button>
        <a [routerLink]="loginUrl" class="btn-cancel">{{'reset_password.cancel' | translate }}</a>
      </div>
    </form>
  </div>
</div>
