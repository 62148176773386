import { ClockAbsence } from '@tacliatech/types';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { UserSearchService } from '@web-frontend/shared/services';
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const dummyDataPreview = {
  docs: [
    {
      _id: '6392580bd8d8cf38f576a4f3',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 3,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-08',
        endDate: '2022-12-08',
        startDateUTC: '2022-12-08T00:00:00.000Z',
        endDateUTC: '2022-12-08T00:00:00.000Z',
      },
      totalDays: 1,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '63925814d8d8cf38f576a502',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 0,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-09',
        endDate: '2022-12-09',
        startDateUTC: '2022-12-09T00:00:00.000Z',
        endDateUTC: '2022-12-09T00:00:00.000Z',
      },
      totalDays: 1,
      status: 0,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392581ed8d8cf38f576a511',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 2,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-11',
        endDate: '2022-12-21',
        startDateUTC: '2022-12-21T00:00:00.000Z',
        endDateUTC: '2022-12-21T00:00:00.000Z',
      },
      totalDays: 11,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392583ed8d8cf38f576a520',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 4,
      typeDetail: { type: 2, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-23',
        endDate: '2022-12-27',
        startDateUTC: '2022-12-08T00:00:00.000Z',
        endDateUTC: '2022-12-27T00:00:00.000Z',
      },
      totalDays: 5,
      status: 1,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 4,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 4,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 4,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 4,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 4,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 3,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 1,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 4,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 2,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
    {
      _id: '6392584ad8d8cf38f576a52f',
      approved: false,
      deleted: false,
      dateCreated: '2022-12-08T04:04:22.013Z',
      type: 1,
      typeDetail: { type: 1, halfDayType: '', files: [] },
      dateDetail: {
        startDate: '2022-12-01',
        endDate: '2022-12-01',
        startDateUTC: '2022-12-01T00:00:00.000Z',
        endDateUTC: '2022-12-01T00:00:00.000Z',
      },
      totalDays: 1,
      status: 1,
      note: '',
      idCreatedBy: '63864449e38dd9f8d57db6f2',
      idOwner: '63864449e38dd9f8d57db6f2',
      idCompany: '63864448e38dd9f8d57db6f0',
      owner: {
        _id: '63864449e38dd9f8d57db6f2',
        createdBy: null,
        updatedBy: '63864449e38dd9f8d57db6f2',
        extras: { showPipelineModal: true, showInfoHiring: true },
        deleted: false,
        role: ['ADMIN_ROLE'],
        name: 'José Angel Rodríguez',
        phone: '21983019',
        email: 'jrodriguez@taclia.com',
        mode: 'customer',
        created_at: '2022-11-29T17:41:29.000Z',
        __v: 0,
        company: '63864448e38dd9f8d57db6f0',
        img: '239a1882-6fb1-41be-bf48-b24be04ca749.jpg?alt=media',
      },
    },
  ],
  total: 5,
  page: 1,
  limit: 50,
  countPages: 1,
  hasNextPage: false,
};

export const flightRequestUsers$ = (userSearchService: UserSearchService) =>
  userSearchService.search({ applyPaginate: false }).pipe(
    map((res) => {
      return res.docs
        .map((res) => {
          return {
            title: res.name.toString().toLocaleLowerCase(),
            value: res._id,
            id: res._id,
          };
        })
        .sort((a, b) => (a?.title > b?.title ? 1 : -1));
    })
  );

export const AbsenceStatus = [
  {
    title: 'table.content.pending',
    value: `${ClockAbsence.Status.Pending}`,
    id: `${ClockAbsence.Status.Pending}`,
  },
  {
    title: 'table.content.rejected',
    value: `${ClockAbsence.Status.Rejected}`,
    id: `${ClockAbsence.Status.Rejected}`,
  },
  {
    title: 'table.content.approved',
    value: `${ClockAbsence.Status.Approved}`,
    id: `${ClockAbsence.Status.Approved}`,
  },
  {
    title: 'table.content.others',
    value: `${ClockAbsence.Status.Other}`,
    id: `${ClockAbsence.Status.Other}`,
  },
];

export const AbsenceTypes = [
  {
    title: 'table.content.holidays',
    value: `${ClockAbsence.AbsenceType.Vacation}`,
    id: `${ClockAbsence.AbsenceType.Vacation}`,
  },
  {
    title: 'table.content.sickness',
    value: `${ClockAbsence.AbsenceType.Sickness}`,
    id: `${ClockAbsence.AbsenceType.Sickness}`,
  },
  {
    title: 'table.content.maternity',
    value: `${ClockAbsence.AbsenceType.MaternityOrPaternity}`,
    id: `${ClockAbsence.AbsenceType.MaternityOrPaternity}`,
  },
  {
    title: 'table.content.familySickness',
    value: `${ClockAbsence.AbsenceType.SicknessOfAFamilyMember}`,
    id: `${ClockAbsence.AbsenceType.SicknessOfAFamilyMember}`,
  },
  {
    title: 'table.content.others',
    value: `${ClockAbsence.AbsenceType.Other}`,
    id: `${ClockAbsence.AbsenceType.Other}`,
  },
];

/**
 *
 * @param data export const SOURCE_FILTER = (data: {
  userSearchService: UserSearchService;
  showOwners: boolean;
}) => {
  return new RmFilter.Filter([
    new RmFilter.Input({
      type: 'SEARCH',
      keyRequest: 'keyword',
      content: {
        label: '',
        placeholder: 'clockAbsence.inputPlaceholder',
      },
    }),
    new RmFilter.Divider(),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'idOwners[]',
      source$: flightRequestUsers$(data.userSearchService),
      show$: of(data.showOwners),
      content: {
        label: '',
        placeholder: 'general.users',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'status[]',
      source$: from([AbsenceStatus]),
      content: {
        label: '',
        placeholder: 'general.status',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'types[]',
      source$: from([AbsenceTypes]),
      content: {
        label: '',
        placeholder: 'general.typeAbsence',
      },
    }),
  ]);
};
 * @returns
 */

export const SOURCE_FILTER = (data: {
  userSearchService: UserSearchService;
  showOwners: boolean;
}) => {
  return new RmFilter.Filter([
    new RmFilter.Input({
      type: 'SEARCH',
      keyRequest: 'keyword',
      content: {
        label: '',
        placeholder: 'clockAbsence.inputPlaceholder',
      },
    }),
  ]);
};

export const SOURCE_USER_FILTER = (data: {
  userSearchService: UserSearchService;
  showOwners: boolean;
}) => {
  return new RmFilter.Filter([
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'idOwners[]',
      source$: flightRequestUsers$(data.userSearchService),
      show$: of(data.showOwners),
      content: {
        label: '',
        placeholder: 'general.users',
      },
    }),
  ]);
};

export const SOURCE_USER_MOBILE_FILTER = (data: {
  userSearchService: UserSearchService;
  showOwners: boolean;
}) => {
  return new RmFilter.Filter([
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'idOwners[]',
      source$: flightRequestUsers$(data.userSearchService),
      show$: of(data.showOwners),
      content: {
        label: 'general.users',
        placeholder: 'general.users',
      },
    }),
  ]);
};

export const SOURCE_SECONDARY_WEB_FILTER = (data: {
  userSearchService: UserSearchService;
  showOwners: boolean;
}) => {
  return new RmFilter.Filter([
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'idOwners[]',
      source$: flightRequestUsers$(data.userSearchService),
      show$: of(data.showOwners),
      content: {
        label: '',
        placeholder: 'general.users',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'status[]',
      source$: from([AbsenceStatus]),
      content: {
        label: '',
        placeholder: 'general.status',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'types[]',
      source$: from([AbsenceTypes]),
      content: {
        label: '',
        placeholder: 'general.typeAbsence',
      },
    }),
  ]);
};

export const SOURCE_MOBILE = (data: {
  userSearchService: UserSearchService;
  showOwners: boolean;
}) => {
  return new RmFilter.Filter([
    new RmFilter.Input({
      type: 'SEARCH',
      keyRequest: 'keyword',
      content: {
        label: 'clockAbsence.inputPlaceholder',
        placeholder: 'clockAbsence.inputPlaceholder',
      },
    }),
    new RmFilter.Select({
      type: 'MULTIPLE',
      keyRequest: 'idOwners[]',
      source$: flightRequestUsers$(data.userSearchService),
      show$: of(data.showOwners),
      content: {
        label: 'general.users',
        placeholder: 'general.users',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'status[]',
      source$: from([AbsenceStatus]),
      content: {
        label: 'general.status',
        placeholder: 'general.status',
      },
    }),
    new RmFilter.Select({
      type: 'SINGLE',
      keyRequest: 'types[]',
      source$: from([AbsenceTypes]),
      content: {
        label: 'general.typeAbsence',
        placeholder: 'general.typeAbsence',
      },
    }),
    new RmFilter.RangeDate({
      keyRequest: ['from', 'to'],
      content: {
        label: '',
        startPlaceholder: '',
        endPlaceholder: '',
      },
    }),
  ]);
};
