<div class="card__clock__hour__left">
  <img src="assets/icons/clock-circle.svg" alt="" />
</div>
<div class="card__clock__hour__content">
  <div class="card__clock__hour__content__title">
    {{ content.title }}

    <div *ngIf="content.addTitleLabel" class="roma-label dark">
      {{ 'clockHour.today' | translate }}
    </div>
  </div>
  <div class="card__clock__hour__content__description">
    {{ content.description }}
  </div>
  <div
    class="absence-badge"
    *ngIf="content.absence"
    [ngSwitch]="content.absence.type"
  >
    <span>
      <svg
        [ngClass]="getClassForAbsenceType(content.absence.type)"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.44754 2.57099C4.18719 2.31065 3.76508 2.31065 3.50473 2.57099L2.1714 3.90433C1.91105 4.16468 1.91105 4.58679 2.1714 4.84714C2.43175 5.10749 2.85386 5.10749 3.11421 4.84714L4.44754 3.5138C4.70789 3.25345 4.70789 2.83134 4.44754 2.57099Z"
          fill="#8861FA"
        />
        <path
          d="M13.7809 2.57099C13.5205 2.31065 13.0984 2.31065 12.8381 2.57099C12.5777 2.83134 12.5777 3.25345 12.8381 3.5138L14.1714 4.84714C14.4317 5.10749 14.8539 5.10749 15.1142 4.84714C15.3746 4.58679 15.3746 4.16468 15.1142 3.90433L13.7809 2.57099Z"
          fill="#8861FA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.6428 3.0424C5.32909 3.0424 2.6428 5.72869 2.6428 9.0424C2.6428 12.3561 5.32909 15.0424 8.6428 15.0424C11.9565 15.0424 14.6428 12.3561 14.6428 9.0424C14.6428 5.72869 11.9565 3.0424 8.6428 3.0424ZM7.11421 6.57099C6.85386 6.31064 6.43175 6.31064 6.1714 6.57099C5.91105 6.83134 5.91105 7.25345 6.1714 7.5138L7.69999 9.0424L6.1714 10.571C5.91105 10.8313 5.91105 11.2535 6.1714 11.5138C6.43175 11.7742 6.85386 11.7742 7.11421 11.5138L8.6428 9.98521L10.1714 11.5138C10.4317 11.7742 10.8539 11.7742 11.1142 11.5138C11.3746 11.2535 11.3746 10.8313 11.1142 10.571L9.58561 9.0424L11.1142 7.5138C11.3746 7.25345 11.3746 6.83134 11.1142 6.57099C10.8539 6.31064 10.4317 6.31064 10.1714 6.57099L8.6428 8.09959L7.11421 6.57099Z"
          fill="#8861FA"
        />
      </svg>
    </span>
    <span class="absence-text" *ngSwitchCase="'0'">
      {{ 'table.content.holidays' | translate }}
    </span>
    <span class="absence-text" *ngSwitchCase="'1'">
      {{ 'table.content.sickness' | translate }}
    </span>
    <span class="absence-text" *ngSwitchCase="'2'">
      {{ 'table.content.maternity' | translate }}
    </span>
    <span class="absence-text" *ngSwitchCase="'3'">
      {{ 'table.content.familySickness' | translate }}
    </span>
    <span class="absence-text" *ngSwitchCase="'4'">
      {{ 'table.content.otherAbsence' | translate }}
    </span>
    <span class="absence-text" *ngSwitchDefault>
      {{ tagNotAvailable | translate }}
    </span>
  </div>
</div>
<div class="card__clock__hour__right">
  <button
    *ngIf="!content?.hour"
    class="roma-button outline"
    [disabled]="disabled"
    (click)="makeAction($event, 'SIGN')"
  >
    {{ 'table.colum.register' | translate }}
  </button>

  <ng-container *ngIf="content?.hour">
    <div
      *ngIf="content.status === 'APPROVED'"
      class="card__clock__hour__right__icon"
    >
      <img src="assets/icons/gl_check_white.svg" alt="" />
    </div>
    <div
      *ngIf="content.status === 'REJECTED'"
      class="card__clock__hour__right__icon-2"
    >
      <img src="assets/icons/gl_error.svg" alt="" />
    </div>
    <div class="card__clock__hour__right__text">
      {{ content.hour }}
    </div>

    <img
      romaClickStopPropagation
      [matMenuTriggerFor]="menu"
      class="more-actions"
      src="/assets/icons/gl_more-vertical.svg"
      (click)="$event.stopPropagation()"
    />
    <mat-menu
      class="custom-template-actions actions-menu"
      #menu="matMenu"
      xPosition="before"
    >
      <button
        class="delete"
        romaClickDelete
        mat-menu-item
        subtitle="deleteModal.body_final"
        romaProFeature
        [featureUser]="featureRefUser.ClockHour.delete"
        (wantDelete)="makeAction('DELETE')"
      >
        {{ 'general.deleteAlt' | translate }}
      </button>
    </mat-menu>
  </ng-container>
</div>
