import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { CompanyModuleService } from '@web-frontend/shared/services/company';

@Injectable()
export class WithoutSessionGuard implements CanActivate {
  constructor(
    private router: Router,
    private companyModuleService: CompanyModuleService
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot) {
    if (localStorage.getItem('TOKEN')) {
      const type = route.queryParamMap.get('type');
      const id = route.queryParamMap.get('id');
      if (type === 'EQUIPMENT' && id) {
        this.router.navigateByUrl(`/admin/pof?view=equipments&id=${id}`);
      } else {
        if (!localStorage.getItem('register')) {
          this.companyModuleService.goFirstRouteActive();
        }
      }
    }
    return true;
  }
}
