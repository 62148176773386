import { ClockHour } from 'src/types-legacy/lib/packages/clock-hour/clock-hour.types';

export const formatClockDate = (date: ClockHour.ClockDate | string): string => {
  if (typeof date === 'string') {
    return date;
  }

  const { year, month, day } = date;
  return `${year}-${(month + 1)
    .toString()
    .padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};
