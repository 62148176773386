import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  App,
  CUSTOMER_DATA,
  ICustomer,
  IUser,
  UpdateAutomaticBudgetDto,
} from '@tacliatech/types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GenericService } from '../generic';
import { StorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends GenericService<ICustomer> {
  private sub$ = new Subscription();

  private customersCache = new BehaviorSubject<App.ResourceCached<ICustomer[]>>(
    ['INIT', []]
  );

  customers$ = this.customersCache.asObservable();

  constructor(http: HttpClient) {
    super(http, 'customer');
  }

  init() {
    this.customersCache.next(['LOADING', []]);

    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.customersCache.next(['LOADED', res]);
      })
    );
  }

  public get customers() {
    return this.customersCache.value[1];
  }

  findAll() {
    return this.http.get<ICustomer[]>(`:API_URL/customer/`);
  }

  findOne(id: string) {
    return this.http.get<ICustomer>(`:API_URL/customer/${id}`);
  }

  findUsersByCustomer(id: string) {
    return this.http.get<IUser[]>(`:API_URL/users/customer/${id}`);
  }

  updateAutomaticBudget(id: string, data: UpdateAutomaticBudgetDto) {
    return this.http.put<ICustomer>(
      `:API_URL/${this.endpoint}/automatic-budget/${id}`,
      data
    );
  }

  updateDealApproval(id: string, val: boolean) {
    return this.http.put<ICustomer>(
      `:API_URL/${this.endpoint}/deal-aproval/${id}`,
      { value: val }
    );
  }

  public loginSuccessfullyFlow(): void {
    this.findOne(StorageService.CustomerId).subscribe((res) => {
      const customerData = {
        company_size: res.company_size,
        company_type: res.company_type,
        channel: res.description,
        sector: res?.sector,
        business_type: res.business_type,
        sector_id: res?.sector_id ? res?.sector_id : null,
      };
      StorageService.SetItem(CUSTOMER_DATA, JSON.stringify(customerData));
    });
  }
}
