/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { UserService } from '@web-frontend/shared/services';
import { Subscription } from 'rxjs';
import { authRoutes } from '../../../auth-routing.module';

@Component({
  selector: 'roma-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  token: string;
  isCreate = true;
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  form: any;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private i18n: TranslateService
  ) {
    this.isCreate = this.route.snapshot.routeConfig.path.includes('create');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  buildForm(): void {
    this.form = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      passwordConfirm: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe((respQuery) => {
      if (respQuery.token) {
        this.token = respQuery.token;
        this.buildForm();
      }
    });
    this.subscriptions.push(this.subscription);
  }

  public onSubmit(): void {
    if (this.form.value.password === this.form.value.passwordConfirm) {
      this.subscription = this.userService
        .updatePassword({
          password: this.form.value.password,
          token: this.token,
        })
        .subscribe(
          (res: any) => {
            this.alertService.success(this.i18n.instant('login.resetDone'));
            this.router.navigate(['login']);
          },
          (error: any) => {
            this.alertService.error(this.i18n.instant('login.resetError'));
          }
        );
      this.subscriptions.push(this.subscription);
    } else {
      this.alertService.error(this.i18n.instant('login.error.notEquivalent'));
    }
  }

  get loginUrl(): string {
    return authRoutes.login;
  }
}
