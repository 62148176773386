<roma-modal-template
  [title]="'general.sendByEmailLong' | translate"
  [contentMinHeight]="false"
  [showDefaultButtons]="true"
  [textSuccess]="'general.send'"
  [isLoading]="isLoading"
  (cancel)="ok()"
  (success)="send()"
>
  <div class="base-div">
    <form [formGroup]="form">
      <div class="modal-send-body">
        <div class="modal-send-body-data">
          <div class="modal-send-body-data-inputs">
            <div class="modal-send-body-data-inputs-item">
              <div class="modal-send-body-data-inputs-item-name chip">
                {{ 'general.for' | translate }}
              </div>
              <div class="modal-send-body-data-inputs-item-input">
                <mat-chip-list #chipList aria-label="Destinatarios">
                  <mat-chip
                    *ngFor="let to of destinations"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(to)"
                  >
                    {{ to }}
                    <button matChipRemove *ngIf="removable">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    *ngIf="destinations.length < maxEmails"
                    matInput
                    [placeholder]="'general.exampleEmail' | translate"
                    type="email"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)"
                  />
                </mat-chip-list>
                <div
                  *ngIf="!loadingBudget && !this.destinations.length"
                  class="invalid-feedback-email"
                >
                  {{ 'billing.emailRequired' | translate }}
                </div>
              </div>
            </div>
            <div class="modal-send-body-data-inputs-item">
              <div class="modal-send-body-data-inputs-item-name">
                {{ 'general.subject' | translate }}
              </div>
              <div class="modal-send-body-data-inputs-item-input">
                <input
                  matInput
                  formControlName="subject"
                  name="Asunto"
                  [placeholder]="'billing.placeholderSubject' | translate"
                  [ngClass]="{
                    'is-invalid': fieldSubject?.displayError || false
                  }"
                />
                <roma-field-error-display
                  style="font-size: 12px !important"
                  #fieldSubject
                  fieldName="subject"
                  [errorMsg]="'billing.subjectRequired' | translate"
                  [form]="form"
                >
                </roma-field-error-display>
              </div>
            </div>
          </div>
          <div class="modal-send-body-data-textarea">
            <textarea
              rows="6"
              class="inputArea"
              cols="40"
              [maxLength]="500"
              matInput
              formControlName="message"
              [placeholder]="'billing.placeholderMessage' | translate"
            >
            </textarea>
          </div>
        </div>
        <div class="modal-send-body-info">
          <img
            src="assets/icons/gl_information-fill.svg"
            alt="Information icon"
          />
          <div class="modal-send-body-info-text">
            {{ 'deal_part_of_service.sendMessage' | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>
</roma-modal-template>
