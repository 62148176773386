import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  Company,
  ConvertToKeyValue,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  ICustomProperty,
  IEquipmentNote,
  MapperFinal,
  ValidateAllFormFields,
} from '@tacliatech/types';

import { MapperPlaceResult } from '@web-frontend/shared/class/location';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { RemoveEmpty } from '@web-frontend/shared/utils';

import {
  AssetService,
  FinalService,
  ProjectService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';

import { FilesUploadService } from '@web-frontend/shared/services/file-upload';

import { FilterItems } from '../filter';
import { ModalInfoComponent } from '../modal-info';

import { DataProjectModalParams } from './create-project.types';
import { CreateProjectService } from './create-project.service';
import { InfoService } from '../info/info.service';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';
import { CompanyModuleService } from '@web-frontend/shared/services/company';

import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import PlaceResult = google.maps.places.PlaceResult;

import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';

import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateProjectComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    notes: [[]],
    files: [[]],
    idOwner: [],
    idClient: [],
    idUsers: [[]],
    idCreatedBy: [StorageService.UserId],
    customProperties: [[]],
  });

  clients: FilterItems = [];
  clientsCharged = false;
  clientsLoading = false;
  owners: FilterItems = [];
  users: FilterItems = [];
  usersCharged = false;
  usersLoading = false;
  users2Loading = false;

  featureRef = Feature;
  featureRefUser = FeatureUser;
  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;

  private paramsCached: DataProjectModalParams;
  private preRequestImages = new Set<File>();
  private sub$ = new Subscription();

  private refreshModalOnClose = false;
  typePropertyRef = DynamicPropertyRef;
  private deleteImagesSubject = new Subject<boolean>();
  deleteImages$ = this.deleteImagesSubject.asObservable();
  isLoading = false;
  customProperties: ICustomProperty[] = [];
  initChildForm = false;
  parentFormSubmit = false;
  invalidEditCustomFields = false;

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private filesUploadService: FilesUploadService,
    private alertService: AlertService,
    private i18n: TranslateService,
    private projectService: ProjectService,
    private modalService: MatDialog,
    private assetService: AssetService,
    private finalService: FinalService,
    private dialogRef: MatDialogRef<CreateProjectComponent>,
    private createProjectService: CreateProjectService,
    private amplitudeService: AmplitudeService,
    private infoService: InfoService,
    private companyModuleService: CompanyModuleService,
    private userService: UserService,

    @Inject(MAT_DIALOG_DATA) public modalParams: DataProjectModalParams,
    private toastService: ToastService,
    private brazeService: BrazeService,
    private analyticsService: AnalyticsService,
    private dialog: MatDialog
  ) {}

  initialForm: any = {};
  get haveChanges() {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  initialChargeEvent() {
    this.initialForm = { ...this.form.value };
  }

  ngOnInit(): void {
    this.patchParams();

    this.form.controls.idUsers.valueChanges.subscribe((value) => {
      this.analyticsService.trackEvent({
        sources: ['braze'],
        eventName: BrazeEventType.project_user_assigned,
        eventProperties: {
          assignedUserIds: value,
        },
      });
    });

    this.form.controls.idOwner.valueChanges.subscribe((value) => {
      this.analyticsService.trackEvent({
        sources: ['braze'],
        eventName: BrazeEventType.project_owner_assigned,
        eventProperties: {
          assignedUserIds: value,
        },
      });
    });
    this.initialForm = { ...this.form.value };
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  get params() {
    return this.paramsCached;
  }

  onClickSelect(item: string) {
    switch (item) {
      case 'clients':
        if (!this.clientsCharged) {
          this.clientsLoading = true;
          this.clientsCharged = true;
          this.resolveClients();
        }
        break;
      case 'users':
        if (!this.usersCharged) {
          this.usersLoading = true;
          this.usersCharged = true;
          this.resolveOwners();
        }
        break;
      case 'users2':
        if (!this.usersCharged) {
          this.users2Loading = true;
          this.usersCharged = true;
          this.resolveOwners();
        }
        break;
    }
  }

  close(del: any) {
    if (del === undefined) {
      this.deleteImagesSubject.next(true);
    }

    this.dialogRef.close(null);
  }

  changeCustomProperty() {
    this.refreshModalOnClose = true;
    this.reloadCustomProperties();
  }

  private reloadCustomProperties() {
    this.sub$.add(
      this.projectService
        .search({
          'ids[]': this.params.data?._id,
        })
        .subscribe((res) => {
          if (res.docs) {
            const [project] = res.docs;
            const { customProperties } = project;
            this.form.patchValue({
              customProperties,
            });
          }
        })
    );
  }

  get projectEditRef() {
    return this?.params?.data;
  }

  async submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();

    if (isValid) {
      const isAdd = this.params.type === 'ADD';
      if (isAdd) {
        await this.submitCreate();
      } else {
        await this.submitUpdate();
      }
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  private async submitCreate() {
    await this.prepareImages();

    const value = RemoveEmpty(this.form.value);
    const eventData = {
      event: 'project_create',
    };
    this.isLoading = true;

    this.sub$.add(
      this.projectService.create(value).subscribe((res) => {
        this.parentFormSubmit = false;
        this.isLoading = false;
        this.toastService.show({
          text: this.i18n.instant('projects.create.success'),
          type: 'success',
          msDuration: 4000,
        });
        this.amplitudeService.sendEvent(eventData);
        this.brazeService.sendEvent(eventData);
        this.dialogRef.close(res);
      })
    );
  }

  private async submitUpdate() {
    await this.prepareImages();

    const value = RemoveEmpty(this.form.value);
    this.isLoading = true;
    this.sub$.add(
      this.projectService
        .updateOne(this.params.data?._id, {
          ...value,
        })
        .subscribe((res) => {
          this.toastService.show({
            text: this.i18n.instant('projects.update.success'),
            type: 'success',
            msDuration: 4000,
          });
          this.isLoading = false;
          this.dialogRef.close(res);
        })
    );
  }

  private async prepareImages() {
    if (this.preRequestImages.size) {
      const files = [...this.preRequestImages.values()];

      const res = await this.filesUploadService
        .uploadFiles('project', files)
        .toPromise();

      const fileNames = res.map((element) => element.filename);

      const lastNames = this.form.get('files').value;

      this.form.patchValue({ files: [...lastNames, ...fileNames] });
    }
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private resolveOwners() {
    this.sub$.add(
      // this.assetService.findUsersByAssetsShared()
      this.userService.findAllUsersByCompany_SELECT().subscribe((res) => {
        this.usersLoading = false;
        this.users2Loading = false;
        this.owners = ConvertToKeyValue(res, 'name', '_id');
        this.users = ConvertToKeyValue(res, 'name', '_id');
        this.draw();
      })
    );
  }

  private resolveClients() {
    this.clientsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.sub$.add(
            this.finalService
              // .search({ applyPaginate: false })
              .findAllFinalsByCompany_SELECT()
              .pipe(
                // map((res) => res?.docs),
                map((res) => MapperFinal(res))
              )
              .subscribe((res) => {
                this.clientsLoading = false;
                this.clients = res.map((item) => {
                  return {
                    key: `${item['name']} ${item['lastName'] || ''}`,
                    value: item['_id'],
                  };
                });
                this.draw();
              })
          );
        })
    );
  }

  changePhones(phones: string[]) {
    this.form.patchValue({
      phones,
    });

    this.draw();
  }

  addingNotes(evt: IEquipmentNote[]) {
    this.form.patchValue({
      notes: [...evt],
    });

    this.draw();
  }

  autocompleteSelected(result: PlaceResult) {
    const { formattedAddress } = MapperPlaceResult(result);

    this.form.patchValue({
      address: formattedAddress,
    });

    this.draw();
  }

  getImgSource(indexImage: number) {
    try {
      if (
        this.params.data?.files?.length &&
        this.params.data?.files[indexImage]
      ) {
        return [this.params.data?.files[indexImage]];
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  addImages(evt: File) {
    this.preRequestImages.add(evt);
  }

  private patchParams() {
    this.paramsCached = this.modalParams;

    if (this.params.type === 'EDIT') {
      this.form.patchValue({
        ...this.params.data,
      });
    }
  }

  async deleteImages(evt: { type: string; img: string }) {
    const imagesMapped = this.paramsCached.data?.files?.filter(
      (file) => file !== evt.img
    );

    this.paramsCached = {
      ...this.paramsCached,
      data: {
        ...this.paramsCached.data,
        files: imagesMapped,
      },
    };

    this.form.patchValue({
      files: imagesMapped,
    });

    this.draw();

    try {
      const res = await this.projectService
        .updateOne(this.params.data._id, { files: imagesMapped })
        .toPromise();

      this.createProjectService.emitRefresh(res);

      this.toastService.show({
        text: this.i18n.instant('equipments.update.successDeleteImages'),
        type: 'success',
        msDuration: 4000,
      });
      await this.filesUploadService.deleteFile('project', evt.img).toPromise();
    } catch (err) {}
  }

  openImages({ type, img }: { type: string; img: string }) {
    const split = img.split('.');
    const ext = split[split.length - 1];

    if (ext !== 'pdf') {
      const modalRef = this.modalService.open(ModalInfoComponent);

      (modalRef.componentInstance as ModalInfoComponent).images = [
        { type, img },
      ];

      (modalRef.componentInstance as ModalInfoComponent).title =
        'projects.previewView';

      (modalRef.componentInstance as ModalInfoComponent).type = 'image';
    } else {
      const filterPipe = new ImageFirebasePipe();
      const imagePath = filterPipe.transform(img, type);

      window.open(imagePath, '_blank');
    }
  }

  info(type2: string) {
    this.infoService.open(type2);
  }
  updateImages(images: any) {
    const result = this.filesUploadService.convertImages(images);
    this.form.patchValue({
      files: result,
    });

    if (this.params.data) {
      this.params.data.files = result;
    }

    this.draw();
  }

  showAlert(message: string) {
    this.toastService.show({
      text: this.i18n.instant(`errors.${message}`),
      type: 'error',
      msDuration: 4000,
    });
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setcustomProperties();
  }

  setcustomProperties() {
    let i = 0;
    for (i = 0; i < this.customProperties.length; i++) {
      if (this.customProperties[i].isRequired) {
        this.form.addControl(
          this.customProperties[i]._id,
          new FormControl('', Validators.required)
        );
      }
    }
    this.initChildForm = true;
    this.parentFormSubmit = false;
  }

  fetchPropertyValue(propertyId) {
    this.form.controls[propertyId].setValue(propertyId);
  }

  propertiesRequired(value) {
    this.invalidEditCustomFields = value;
  }
}
