import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import {
  AdditionalInfoBudget,
  BudgetCommonData,
  BudgetPreferences,
  BudgetsSetting,
  Feature,
  FeatureUser,
  HeaderBudget,
  IBudgetCommonData,
  IBudgetsSetting,
  IHeaderBudget,
  ItemBudget,
  MessageBudget,
  PaymentInfoBudget,
  STATUS_PROFORM_CREATED,
  Tax,
  TotalBudget,
  TypeBudget,
} from '@tacliatech/types';
import {
  DEFAULT_STATUS_BY_BUDGET_TYPE,
  STATUSES_BY_BUDGET_TYPE,
} from '@web-frontend/shared/utils';

import { TranslateService } from '@ngx-translate/core';

import {
  AuthService,
  FinalService,
  StorageService,
} from '@web-frontend/shared/services';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { TaxService } from '@web-frontend/shared/services/tax';

import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import { BudgetPreviewerComponent } from '@web-frontend/shared/components/budget-previewer/budget-previewer.component';
import { ModalSendComponent } from '@web-frontend/shared/components/modal-send';
import { environment } from '@web-frontend/environments';
import { User } from '@web-frontend/shared/class';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { GenerateDocumentService } from '@web-frontend/shared/services/generate-document/generate-document.service';
import { CreateDealService } from '@web-frontend/shared/components/create-deal';

import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

export class SenderEmail {
  id: string;
  email: string;
  constructor(id: string, email: string) {
    this.id = id;
    this.email = email;
  }
}

@Injectable({ providedIn: 'root' })
export class BudgetUtilisService {
  @Output()
  refreshItems = new EventEmitter<unknown>();

  @Output()
  refreshAdditionalInfo = new EventEmitter<unknown>();

  @Output()
  refreshBudget = new EventEmitter<unknown>();

  @Output()
  refreshTotals = new EventEmitter<unknown>();

  @Output()
  refreshBudgetSettings = new EventEmitter<unknown>();

  @Output()
  updateTotal = new EventEmitter<TotalBudget>();

  @Input()
  settings: IBudgetsSetting;
}

@Component({
  selector: 'roma-budgets-edit',
  templateUrl: './budgets-edit.component.html',
  styleUrls: ['./budgets-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetsEditComponent implements OnInit, OnDestroy {
  @Input()
  messagesSpinner = 'Descargando archivo Pdf ...';

  featureRef = Feature;
  featureRefUser = FeatureUser;
  budgetsSetting: IBudgetsSetting;

  constructor(
    private cdRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private i18n: TranslateService,
    private budgetService: BudgetService,
    private budgetUtilisService: BudgetUtilisService,
    private changeDetectorRef: ChangeDetectorRef,
    private taxService: TaxService,
    private finalService: FinalService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private createDealService: CreateDealService,
    private datePipe: DatePipe,
    private amplitudeService: AmplitudeService,
    private generateDocument: GenerateDocumentService,

    private brazeService: BrazeService,
    private analyticsService: AnalyticsService,
    @Optional() private dialogRef: MatDialogRef<BudgetPreviewerComponent>,

    @Inject(MAT_DIALOG_DATA) data,
    @Inject(Location) private location: Location
  ) {
    this.isAdmin$.subscribe((data) => (this.isAdmin = data));
  }

  private sub$ = new Subscription();
  private companyID = StorageService.CompanyId;
  isAdmin$ = this.authService.isAdmin$;

  activeUser$ = this.authService.user$;
  isLoading = false;
  isAdmin = false;
  isLoadingSettings = false;
  isLoadingPaginate = false;
  isLoadingFile = false;
  mode: 'EDIT' | 'ADD';
  idBudget: string;
  budget: BudgetCommonData = new BudgetCommonData();
  settings: BudgetPreferences;
  AllSettings: IBudgetsSetting;
  defaultSettings = new BudgetsSetting();
  defaultPreferences = this.defaultSettings.budgetPreferences;
  showTotal = false;

  typeRef = TypeBudget;
  type: TypeBudget;
  list: any;
  title: string;
  items: ItemBudget[] = [];
  header: IHeaderBudget;
  messages: MessageBudget = new MessageBudget();
  typePayments: PaymentInfoBudget[] = [];
  addicionalInfo: AdditionalInfoBudget = new AdditionalInfoBudget();
  taxes: Tax[] = [];

  idDeal: string = null;
  customerId: string = null;
  validheader = false;
  validItems = false;
  validFooter = false;
  hasDiscount = false;

  downloading = false;
  downloadingSubscribe = false;
  downloadingPreview = false;
  downloadingPreviewSubscribe = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.getSettings();
      this.requestFinals();
      this.watchtotal();
      this.watchRefreshBudgetSettings();
      this.watchItems();
      this.resolveTaxes();
      this.watchTranslations();
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  get featureUserScreen() {
    switch (this.type) {
      case 'BDG':
        return this.mode === 'EDIT'
          ? FeatureUser.Quote.update
          : FeatureUser.Quote.create;
      case 'BILL':
        return this.mode === 'EDIT'
          ? FeatureUser.Bill.update
          : FeatureUser.Bill.create;
      case 'PROFM':
        return this.mode === 'EDIT'
          ? FeatureUser.Proform.update
          : FeatureUser.Proform.create;
    }
    return null;
  }

  watchTranslations() {
    this.translate.onLangChange.subscribe((lang) => {});
  }

  watchRefreshBudgetSettings() {
    this.sub$.add(
      this.budgetUtilisService.refreshBudgetSettings.subscribe(
        (data: BudgetsSetting) => {
          //  this.settings = data.budgetPreferences;
          //  this.defaultSettings.logo = data?.logo;

          if (data?.logo) {
            this.defaultSettings = data;
            this.AllSettings = data;
            this.settings = data.budgetPreferences;
          }

          this.draw();
        }
      )
    );
  }

  ngAfterViewInit() {
    this.showTotal = false;
  }
  watchItems() {
    this.sub$.add(
      this.budgetUtilisService.refreshBudget.subscribe(
        (data: IBudgetCommonData) => {
          this.budget = data;
        }
      )
    );

    this.budgetUtilisService.refreshItems.subscribe((items: ItemBudget[]) => {
      this.budget.items = items;
    });
  }

  finalsEmail: SenderEmail[] = [];

  private requestFinals() {
    this.finalService
      .search({ applyPaginate: false, limit: 1 })
      .subscribe((res) => {
        this.finalsEmail = res?.docs.map((final) => {
          return new SenderEmail(final._id, final?.email);
        });
      });
  }

  resolveTaxes() {
    this.taxes = this.taxService.findAll();
  }

  watchtotal() {
    this.budgetUtilisService.updateTotal.subscribe((data: TotalBudget) => {
      this.budget.total = data.total;
      this.budget.subtotal = data.subtotal;
      this.budget.iva_total = data.ivatotal;
    });
  }

  caltulateTotals(items: any) {
    this.budgetUtilisService.refreshTotals.emit(items);
  }

  getSettings() {
    this.isLoadingSettings = true;

    const id = StorageService.CompanyId;

    this.sub$.add(
      this.budgetService
        .findSettingsByCompany(id)
        .pipe(
          finalize(() => {
            this.isLoadingSettings = false;
          })
        )
        .subscribe(
          (resp: IBudgetsSetting) => {
            this.resolveRouteParams();
            if (resp) {
              this.AllSettings = resp;
              this.settings = resp.budgetPreferences;
              this.defaultSettings = resp;
            } else {
              this.defaultSettings.idOwner = id;
              this.settings = this.defaultPreferences;
              this.createDefaultPreferences(this.defaultSettings);
            }
            this.budgetUtilisService.refreshBudgetSettings.emit(this.settings);

            this.isLoadingSettings = false;
            this.draw();
          },
          (error) => {
            this.isLoadingSettings = false;
          }
        )
    );
  }
  createDefaultPreferences(defaultSettings: BudgetsSetting) {
    try {
      this.authService.user$.subscribe((res: User) => {
        // Fill company data default
        this.defaultSettings.data_account.company_name = res?.company?.name;
        this.defaultSettings.data_account.email = res?.company?.email;

        //Create default settings
        this.budgetService
          .createOneSetting(defaultSettings)
          .subscribe((data) => {
            this.defaultSettings = data;
            this.AllSettings = this.defaultSettings;
            this.budgetUtilisService.refreshBudgetSettings.emit(this.settings);
          });
      });
    } catch (error) {
      throw new Error('ERROR CREATING DEFAULT SETTING');
    }
  }
  private resolveRouteParams() {
    const paramsMap = this.route.snapshot.queryParamMap;

    if (paramsMap.keys.length) {
      this.type = paramsMap.get('type') as TypeBudget;
      const justCreated: boolean = paramsMap.get('justCreated') == 'true';

      if (paramsMap.has('idDeal')) {
        this.idDeal = paramsMap.get('idDeal');

        this.budget.header.idDeal = this.idDeal;
      }

      if (paramsMap.has('customer')) {
        this.customerId = paramsMap.get('customer');
        this.budget.header.contact = this.customerId;
        this.budget.header.contact_id = this.customerId;
      }

      if (paramsMap.has('budget')) {
        this.idBudget = paramsMap.get('budget');
        this.mode = 'EDIT';
      } else {
        this.mode = 'ADD';
        this.header = new HeaderBudget();
        this.items = [];

        this.budget.items = [];
        this.budget.company = StorageService.CompanyId;
      }

      let eventData;
      if (this.type == TypeBudget.PROFORM) {
        eventData = {
          event: 'proform_view',
        };
      }
      if (this.type == TypeBudget.BILL) {
        eventData = {
          event: 'bill_view',
        };
      }
      if (this.type === TypeBudget.BUDGET) {
        eventData = {
          event: 'budget_view',
        };
      }
      this.amplitudeService.sendEvent(eventData);

      this.title = this.getTitle(this.type);
      this.loadData(this.type, paramsMap.get('budget'), justCreated);
    }

    this.draw();
  }

  exit() {
    try {
      window.history.go(-1);
    } catch (err) {}
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  // LOAD INITIAL Object
  // -----------------------------------------------------

  loadData(type: TypeBudget, budgetId?: string, justCreated?: boolean) {
    if (type == TypeBudget.BUDGET) {
      if (budgetId) {
        this.fillBudget(budgetId);
      } else {
        this.findMax();
      }
    }

    if (type == TypeBudget.PROFORM) {
      if (budgetId) {
        this.fillProform(budgetId);
      } else {
        this.findMax();
      }
    }

    if (type === TypeBudget.BILL) {
      if (budgetId) {
        this.fillBill(budgetId);
      } else {
        this.findMax();
      }
    }
    if (justCreated) {
      this.mode = 'ADD';
    }
  }

  fillBill(id: string) {
    this.isLoading = true;
    this.draw();

    this.budgetService
      .findOneBill(this.idBudget)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((data) => {
        this.budget = data;
        this.items = [...this.items, ...this.setItems(data)];

        this.header = data.header;
        this.messages = data.messages;
        this.typePayments = data.typePayments;
        this.addicionalInfo = data.addicionalInfo;
        this.budgetUtilisService.refreshBudget.emit(data as IBudgetCommonData);
        this.draw();
        this.animateTotal();
      });
  }

  private fillBudget(budgetId?: string) {
    this.isLoading = true;

    this.budgetService.findOneBudget(budgetId).subscribe((data) => {
      this.budget = data;

      this.items = [...this.items, ...this.setItems(data)];
      this.header = data.header;
      this.messages = data.messages;
      this.typePayments = data.typePayments;
      this.addicionalInfo = data.addicionalInfo;
      this.budgetUtilisService.refreshBudget.emit(data as IBudgetCommonData);
      this.isLoading = false;
      this.draw();
      this.animateTotal();
    });
  }

  animateTotal() {
    setTimeout(() => {
      this.showTotal = true;
      this.draw();
    }, 1000);
  }

  findMax() {
    this.budgetService.findMax(this.type).subscribe((data) => {
      this.budget.header.prefix =
        this.AllSettings?.invoiceSettings?.prefixNumber ?? '000';

      if (!this.AllSettings?.invoiceSettings) {
        this.budget.header.numberDoc = data.maxNumber?.toString();
      }

      if (data?.maxNumber == null) {
        this.budget.header.numberDoc = this.AllSettings?.invoiceSettings?.starterNumber?.toString();
      } else {
        if (
          this.AllSettings?.invoiceSettings?.starterNumber >= data.maxNumber
        ) {
          this.budget.header.numberDoc = (
            this.AllSettings?.invoiceSettings?.starterNumber + 1
          )?.toString();
        } else {
          this.budget.header.numberDoc = data.maxNumber?.toString();
        }
      }

      this.budgetUtilisService.refreshBudget.emit(
        this.budget as IBudgetCommonData
      );
      this.animateTotal();
    });
  }

  private fillProform(budgetId?: string) {
    this.isLoading = true;
    this.draw();

    this.budgetService
      .findOneProform(budgetId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.draw();
        })
      )
      .subscribe((data) => {
        this.budget = data;
        this.items = [...this.items, ...this.setItems(data)];
        this.header = data.header;
        this.messages = data.messages;
        this.typePayments = data.typePayments;
        this.addicionalInfo = data.addicionalInfo;
        this.budgetUtilisService.refreshBudget.emit(data as IBudgetCommonData);
        this.draw();
        this.animateTotal();
      });
  }

  canConvertToBill() {
    if (
      (this.type === TypeBudget.BUDGET && this.budget.status !== 4) ||
      (this.type === TypeBudget.PROFORM && this.budget.status !== 4)
    ) {
      return true;
    } else {
      return false;
    }
  }

  canConvertToProform() {
    if (this.type === TypeBudget.BUDGET && this.budget.status !== 4) {
      return true;
    } else {
      return false;
    }
  }

  refresh(value) {
    this.draw();
  }

  /*   updateStatusBudget(budget: BudgetCommonData) {
    console.log('updateStatusBudget BudgetCommonData', budget);
    this.budget = budget;
    //this.loadData(this.type, this.idBudget);
    this.draw();
  } */

  OnAddClick(event: any) {
    this.items = [...this.items, new ItemBudget()];
    this.setItemsIds();
  }

  OnDeleteClick(item: ItemBudget) {
    this.items.splice(item.idAux, 1);
    this.setItemsIds();
  }

  OnAddDiscount($event) {
    this.budget.proFeatures.hasDiscount = true;
    this.hasDiscount = true;
  }

  setItemsIds() {
    this.items.forEach((item, index) => {
      item.idAux = index;
    });
  }

  setItems(data: any) {
    const itms: ItemBudget[] = [];

    data.items.forEach((element) => {
      itms.push(new ItemBudget(element));
    });

    return [...itms];
  }

  getTitle(type: TypeBudget): string {
    switch (type) {
      case TypeBudget.BUDGET:
        return 'budgets.title.budget';
      case TypeBudget.PROFORM:
        return 'budgets.title.proform';
      case TypeBudget.BILL:
        return 'budgets.title.bill';
      case TypeBudget.WAL:
        return 'budgets.title.wallbills';
      default:
        return 'budgets.title.budget';
    }

    return '';
  }

  save() {
    if (this.validheader) {
      if (this.mode === 'EDIT') {
        this.updateBudget();
      } else {
        this.budget.draft = false;
        this.createBudget();
      }
    } else {
      this.alertService.errorOptions(
        this.i18n.instant('budgets.settings.create.form.headerCreateError'),
        { duration: 4500 }
      );
    }
  }

  saveDraft() {
    if (this.mode === 'EDIT') {
      this.alertService.error(this.i18n.instant('budget.saveWarning1'));
    } else if (
      this.budget &&
      this.budget.header.contact &&
      this.budget.header.contact_id &&
      this.budget.header.numberDoc &&
      this.budget.header.date &&
      this.budget.header.dueDate
    ) {
      this.budget.draft = true;
      this.budget.status = DEFAULT_STATUS_BY_BUDGET_TYPE[this.type].id;
      this.createBudget();
    } else {
      this.alertService.error(this.i18n.instant('budget.saveWarning2'));
    }
  }

  onChangeHeaderValid(valid: boolean) {
    this.validheader = valid;
    this.draw();
  }

  updateBudget() {
    let eventData;

    if (this.type == TypeBudget.BUDGET) {
      eventData = {
        event: AmplitudeEvents.budget_edit,
      };
      if (this.budget._id) {
        this.sub$.add(
          this.budgetService
            .updateOne(this.budget._id, this.budget)
            .subscribe((data) => {
              this.amplitudeService.sendEvent(eventData);
              /*     this.alertService.success(
                this.i18n.instant("budgets.settings.edit.successUpdated")
              ); */
              this.draw();
            })
        );
      }
    }

    if (this.type == TypeBudget.PROFORM) {
      if (this.budget._id) {
        this.sub$.add(
          this.budgetService
            .updateOneProform(this.budget._id, this.budget)
            .subscribe((data) => {
              this.budget = data;
              this.analyticsService.trackEvent({
                sources: ['amplitude'],
                eventName: AmplitudeEvents.proform_edit,
              });
              /*   this.alertService.success(
                this.i18n.instant("budgets.settings.edit.successUpdated")
              );   */
              this.draw();
            })
        );
      }
    }

    if (this.type == TypeBudget.BILL) {
      eventData = {
        event: 'bill_edit',
      };
      if (this.budget._id) {
        this.sub$.add(
          this.budgetService
            .updateOneBill(this.budget._id, this.budget)
            .subscribe((data) => {
              this.budget = data;
              this.amplitudeService.sendEvent(eventData);
              /*    this.alertService.success(
                this.i18n.instant('budgets.settings.edit.successUpdated')
              ); */
              this.draw();
            })
        );
      }
    }

    /*    this.budgetService
      .updateOne(this.budget._id, this.budget)
      .subscribe((data) => {
        this.alertService.success(
          this.i18n.instant('budgets.settings.edit.successUpdated')
        );
      }); */
  }

  changedModel($event, type: string) {
    if (type === 'header') {
      //update sender email list with new finals ,
      this.requestFinals();

      this.budget.header = $event.header;
      if (this.budget.header.date == '') {
        this.budget.header.date = null;
      }
      if (this.budget.header.dueDate == '') {
        this.budget.header.dueDate = null;
      }
    }
    if (type === 'items') {
      this.budget.items = $event.header;
    }
    if (type === 'footer') {
      this.budget.addicionalInfo = $event.addicionalInfo;
      this.budget.messages = $event.messages;
      this.budget.typePayments = $event.typePayments;
    }
  }

  createBudget() {
    this.budget.company = StorageService.CompanyId;
    let eventData;

    if (this.type == TypeBudget.BUDGET) {
      eventData = {
        event: 'budget_create',
      };
      this.sub$.add(
        this.budgetService.createOne(this.budget).subscribe((data) => {
          this.showMessageCreated();
          this.goToList();
          this.amplitudeService.sendEvent(eventData);
          this.brazeService.sendEvent(eventData);
        })
      );
    }

    if (this.type == TypeBudget.PROFORM) {
      eventData = {
        event: 'proform_create',
      };
      this.sub$.add(
        this.budgetService.createOneProform(this.budget).subscribe((data) => {
          this.showMessageCreated();
          this.goToList();
          this.amplitudeService.sendEvent(eventData);
          this.brazeService.sendEvent(eventData);
        })
      );
    }

    if (this.type == TypeBudget.BILL) {
      eventData = {
        event: 'bill_create',
      };
      this.sub$.add(
        this.budgetService.createOneBill(this.budget).subscribe((data) => {
          this.showMessageCreated();
          this.goToList();
          this.amplitudeService.sendEvent(eventData);
          this.brazeService.sendEvent(eventData);
        })
      );
    }

    /*     this.budgetService.createOne(this.budget).subscribe((data) => {
      this.showMessageCreated();
      this.goToList();
    }); */
  }

  goToList() {
    if (this.idDeal) {
      //this.router.navigate([`/admin/deals/${this.idDeal}`]);
      this.createDealService
        .open({
          data: {
            mode: 'EDIT',
            idDeal: this.idDeal,
          },
        })
        .pipe(map((res) => !res))
        .subscribe();
    } else {
      this.router.navigate(['/admin/budgets'], {
        queryParams: { type: this.type },
      });
    }
  }

  showMessageCreated() {
    this.alertService.success(
      this.i18n.instant('budgets.settings.create.successCreated')
    );
  }

  showPreview() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.width = '600px';
    dialogConfig.height = '84vh';
    dialogConfig.minWidth = '600px';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      budget: this.budget,
      settings: this.AllSettings,
      type: this.type,
      mode: this.mode,
    };

    this.showTotal = false;
    const dialogRef = this.dialog.open(BudgetPreviewerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      this.showTotal = true;
      this.draw();
    });
  }

  deleteBudget() {
    const budget = this.budget;

    this.budgetService.deleteOne(budget?._id, this.type).subscribe((data) => {
      this.alertService.success(
        this.i18n.instant('budgets.settings.edit.successDeleted')
      );

      this.location.back();
    });
  }

  convertBudget(convertToType: string) {
    const budget = this.budget;
    const budgetCopy = Object.assign({}, budget);

    budgetCopy.status = DEFAULT_STATUS_BY_BUDGET_TYPE[convertToType].id;
    delete budgetCopy['_id'];

    if (convertToType === TypeBudget.PROFORM) {
      budget.status = STATUS_PROFORM_CREATED.id;
      this.sub$.add(
        this.budgetService
          .createOneProform({ messages: {}, addicionalInfo: {}, ...budgetCopy })
          .subscribe((resp) => {
            this.alertService.success(
              this.i18n.instant('budgets.settings.edit.successConvert')
            );
            this.updateBudget();
            this.draw();
          })
      );
    }

    if (convertToType === TypeBudget.BILL) {
      budget.status =
        STATUSES_BY_BUDGET_TYPE[TypeBudget.BILL]['STATUS_BUDGET_INVOICED'].id;

      this.sub$.add(
        this.budgetService
          .createOneBill({ messages: {}, addicionalInfo: {}, ...budgetCopy })
          .subscribe((resp) => {
            this.updateBudget();
            this.draw();
          })
      );
    }
  }

  sendDocument() {
    if (this.budget?._id) {
      const config: MatDialogConfig = new MatDialogConfig();
      config.data = {
        type: this.type,
        budget: this.budget,
        settings: this.AllSettings,
        email: this.resolveEmails(),
      };

      this.showTotal = false;
      const dialogRef = this.dialog.open(ModalSendComponent, config);

      dialogRef.afterClosed().subscribe((data) => {
        this.showTotal = true;
        this.draw();
      });

      /*    return dialogRef.afterClosed().pipe(
        tap((res) => {
          this.showTotal = true;
          this.draw();
        })
      ); */
    } else {
      this.alertService.error('Debe guardar el documento antes de enviarlo.');
    }
  }
  resolveEmails() {
    const emails = this.finalsEmail.find(
      (value) => value.id === this.budget.header?.contact
    )?.email;
    return emails;
  }

  downloadDocument() {
    // Download PDF document for sales (BILL, BDG, PROFM)
    if (!this.budget?._id) {
      this.alertService.error(this.i18n.instant('budget.downloadWarning'));
      return;
    }

    this.isLoadingFile = true;
    this.draw();

    if (this.budget.header.contact_id) {
      // If document have a client (final), execute a query to find final name
      this.finalService
        .findOne(this.budget.header.contact_id)
        .toPromise()
        .then((final) => {
          this.budgetService
            .downloadDocument({
              document: this.budget?.header?.numberDoc,
              type: this.type,
              id: this.budget?._id,
              lang: this.i18n.currentLang,
              user: {
                _id: StorageService.UserId,
                company: {
                  _id: StorageService.CompanyId,
                  name: this.AllSettings?.data_account?.company_name || '',
                },
              },
              // finalId: this.budget.header.contact_id,
              finalName: final.name,
            })
            .pipe(
              finalize(() => {
                this.isLoadingFile = false;
                this.draw();
              })
            )
            .subscribe(
              () => {},
              (error) => {
                this.alertService.error(
                  this.i18n.instant('budget.downloadError')
                );
              }
            );
        });
    } else {
      this.budgetService
        .downloadDocument({
          document: this.budget?.header?.numberDoc,
          type: this.type,
          id: this.budget?._id,
          lang: this.i18n.currentLang,
          user: {
            _id: StorageService.UserId,
            company: {
              _id: StorageService.CompanyId,
              name: this.AllSettings?.data_account?.company_name || '',
            },
          },
        })
        .pipe(
          finalize(() => {
            this.isLoadingFile = false;
            this.draw();
          })
        )
        .subscribe(
          () => {},
          (error) => {
            this.alertService.error(this.i18n.instant('budget.downloadError'));
          }
        );
    }
  }

  createUrl(name: any) {
    return `${environment.api.url}/file-upload/budgets/${name}.pdf`;
  }

  saveFile(name) {
    //const name = this.createFileName(this.budget);
    const url = this.createUrl(name);
    this.budgetService.downloadFile(url, name);
  }

  goToUrl(isAdmin: boolean, url: string, params: any) {
    if (isAdmin) {
      this.router.navigate([url], {
        queryParams: params,
      });
    } else {
      this.alertService.error(this.i18n.instant('general.adminRole'));
    }
  }
}
