import { ModuleType } from '../../../../../types-legacy';

export const modulesInfo = [
  {
    name: 'pro.salesModule.name',
    type: ModuleType.SALES,
    icon: 'sales',
    description: 'pro.salesModule.description',
    features: [
      {
        name: 'pro.salesModule.features.users',
        basic: 'pro.salesModule.features.basic.users',
        pro: 'pro.salesModule.features.unlimitedUsers',
      },
      {
        name: 'pro.salesModule.features.invoices',
        basic: 'pro.salesModule.features.basic.invoices',
        pro: 'pro.salesModule.features.pro.invoices',
      },
      {
        name: 'pro.salesModule.features.products',
        basic: 'pro.salesModule.features.unlimited',
        pro: 'pro.salesModule.features.unlimited',
      },
      {
        name: 'pro.salesModule.features.quotes',
        basic: 'pro.salesModule.features.unlimited',
        pro: 'pro.salesModule.features.unlimited',
      },
      {
        name: 'pro.salesModule.features.customTemplates',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.salesModule.features.waybills',
        basic: false,
        pro: true,
      },
    ],
  },
  {
    name: 'pro.timeTrackingModule.name',
    type: ModuleType.TIME_TRACKING,
    icon: 'schedule',
    description: 'pro.timeTrackingModule.description',
    features: [
      {
        name: 'pro.timeTrackingModule.features.users',
        basic: 'pro.timeTrackingModule.features.basic.users',
        pro: 'pro.timeTrackingModule.features.pro.users',
      },
      {
        name: 'pro.timeTrackingModule.features.clockIns',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.timeTrackingModule.features.absences',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.timeTrackingModule.features.overtime',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.timeTrackingModule.features.payroll',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.timeTrackingModule.features.projects',
        basic: false,
        pro: true,
      },
    ],
  },
  {
    name: 'pro.crmModule.name',
    type: ModuleType.CRM,
    icon: 'users-pink',
    description: 'pro.crmModule.description',
    features: [
      {
        name: 'pro.crmModule.features.users',
        basic: 'pro.crmModule.features.basic.users',
        pro: 'pro.crmModule.features.pro.users',
      },
      {
        name: 'pro.crmModule.features.clients',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.crmModule.features.sales',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.crmModule.features.projects',
        basic: true,
        pro: true,
      },
      {
        name: 'pro.crmModule.features.onlineBooking',
        basic: false,
        pro: true,
      },
      {
        name: 'pro.crmModule.features.customFields',
        basic: 'pro.crmModule.features.basic.fields',
        pro: 'pro.crmModule.features.pro.fields',
      },
    ],
  },
  {
    name: 'pro.fullPlan.name',
    type: ModuleType.FULL,
    icon: 'globe-dark-blue',
    description: 'pro.fullPlan.description',
    features: [
      {
        name: 'pro.fullPlan.features.users',
      },
      { name: 'pro.fullPlan.features.functionalities' },
    ],
  },
];
