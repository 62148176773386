import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { Subject, Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';

import {
  Company,
  ConvertToKeyValue,
  DynamicPropertyRef,
  FeatureUser,
  Feature,
  IAsset,
  ICustomProperty,
  IEquipment,
  IEquipmentNote,
  IUser,
  MapperFinal,
  ValidateAllFormFields,
} from '@tacliatech/types';

import { TranslateService } from '@ngx-translate/core';

import { format } from 'date-fns';

import { EquipmentService } from '@web-frontend/shared/services/equipment';

import {
  AssetService,
  FinalService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';

import { RemoveEmpty } from '@web-frontend/shared/utils';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { FilesUploadService } from '@web-frontend/shared/services/file-upload';

import { ModalInfoComponent } from '../modal-info';
import { DataEquipmentModalParams } from './create-equipment.types';
import { CreateEquipmentService } from './create-equipment.service';
import { FilterItems } from '../filter';
import { InfoService } from '../info/info.service';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';
import { CompanyModuleService } from '@web-frontend/shared/services/company';

import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';

@Component({
  selector: 'roma-create-equipment',
  templateUrl: './create-equipment.component.html',
  styleUrls: ['./create-equipment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEquipmentComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    ref: ['', Validators.required],
    title: [''],
    description: ['', [Validators.maxLength(155)]],
    brand: [''],
    model: [''],
    number: [''],
    units: [''],
    revisionDate: [''],
    revisionFrequency: [''],
    annualAmortization: [''],
    idAssets: [[]],
    idUsers: [[]],
    files: [[]],
    warrantyDate: [''],
    notes: [''],
    qr: [''],
    createdBy: [StorageService.UserId, Validators.required],
    final: [],
    customProperties: [[]],
  });

  initialForm: any = {};

  get haveChanges() {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  assets: IAsset[] = [];
  assetsCharged = false;
  assetsLoading = false;

  users: IUser[] = [];
  usersCharged = false;
  usersLoading = false;

  loadImage = true;
  finals: FilterItems = [];
  finalsCharged = false;
  finalsLoading = false;
  typePropertyRef = DynamicPropertyRef;

  featureRefUser = FeatureUser;
  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;

  private paramsCached: DataEquipmentModalParams;
  private preRequestImages = new Set<File>();
  private sub$ = new Subscription();
  private refresh = new Subject<any>();
  private deleteImagesSubject = new Subject<boolean>();
  deleteImages$ = this.deleteImagesSubject.asObservable();
  isLoading = false;
  customProperties: ICustomProperty[] = [];
  initChildForm = false;
  parentFormSubmit = false;
  invalidEditCustomFields = false;
  featureRef = Feature;

  constructor(
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private createEquipmentService: CreateEquipmentService,
    private dialogRef: MatDialogRef<CreateEquipmentComponent>,
    private assetService: AssetService,
    private changeDetectionRef: ChangeDetectorRef,
    private toastService: ToastService,
    private filesUploadService: FilesUploadService,
    private i18n: TranslateService,
    private modalService: MatDialog,
    private finalService: FinalService,
    private infoService: InfoService,
    private userService: UserService,
    private amplitudeService: AmplitudeService,

    private brazeService: BrazeService,
    private companyModuleService: CompanyModuleService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public modalParams: DataEquipmentModalParams
  ) {}

  ngOnInit(): void {
    this.patchParams();
    this.initialForm = { ...this.form.value };
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  initialChargeEvent() {
    this.initialForm = { ...this.form.value };
  }

  onClickSelect(item: string) {
    switch (item) {
      case 'finals':
        if (!this.finalsCharged) {
          this.finalsLoading = true;
          this.finalsCharged = true;
          this.resolveFinals();
        }
        break;
      case 'users':
        if (!this.usersCharged) {
          this.usersLoading = true;
          this.usersCharged = true;
          this.requestUsers();
        }
        break;
      case 'assets':
        if (!this.assetsCharged) {
          this.assetsLoading = true;
          this.assetsCharged = true;
          this.requestAssets();
        }
        break;
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  get params() {
    return this.paramsCached;
  }

  async submit() {
    this.parentFormSubmit = true;
    const isValid = this.checkValidators();

    if (isValid) {
      const isAdd = this.params.type === 'ADD';

      if (isAdd) {
        await this.submitCreate();
      } else {
        await this.submitUpdate();
      }
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  close() {
    this.dialogRef.close();
  }

  addImages(evt: File) {
    this.preRequestImages.add(evt);
    this.updateImage();
    this.draw();
  }

  getRefresh() {
    return this.refresh.asObservable();
  }

  refreshEquipment() {
    this.paramsCached = this.modalParams;
    if (this.modalParams.type !== 'ADD') {
      this.equipmentService
        .findOne(this.modalParams.data._id)
        .subscribe((res: IEquipment) => {
          this.paramsCached = {
            ...this.paramsCached,
            data: {
              ...res,
            },
          };
          this.loadImage = true;
          this.draw();
        });
    }
  }

  async deleteImages(evt: { type: string; img: string }) {
    const imagesMapped = this.paramsCached.data?.files?.filter(
      (file) => file !== evt.img
    );

    this.paramsCached = {
      ...this.paramsCached,
      data: {
        ...this.paramsCached.data,
        files: imagesMapped,
      },
    };

    this.form.patchValue({
      files: imagesMapped,
    });

    this.draw();

    try {
      const res = await this.equipmentService
        .updateOne(this.params.data._id, { files: imagesMapped })
        .toPromise();

      this.createEquipmentService.emitRefresh(res);

      this.toastService.show({
        text: this.i18n.instant('equipments.update.successDeleteImages'),
        type: 'success',
        msDuration: 4000,
      });

      await this.filesUploadService
        .deleteFile('equipment', evt.img)
        .toPromise();
    } catch (err) {}
  }

  addingNotes(evt: IEquipmentNote[]) {
    this.form.patchValue({
      notes: [...evt],
    });
  }

  getImgSource(indexImage: number) {
    try {
      if (
        this.paramsCached.data?.files?.length &&
        this.paramsCached.data?.files[indexImage]
      ) {
        return [this.paramsCached.data?.files[indexImage]];
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  openImages({ type, img }: { type: string; img: string }) {
    const split = img.split('.');
    const ext = split[split.length - 1];

    if (ext !== 'pdf') {
      const modalRef = this.modalService.open(ModalInfoComponent);

      (modalRef.componentInstance as ModalInfoComponent).images = [
        { type, img },
      ];

      (modalRef.componentInstance as ModalInfoComponent).title = 'Vista Previa';
      (modalRef.componentInstance as ModalInfoComponent).type = 'image';
    } else {
      const filterPipe = new ImageFirebasePipe();
      const imagePath = filterPipe.transform(img, type);

      window.open(imagePath, '_blank');
    }
  }

  private patchParams() {
    this.refreshEquipment();

    if (this.params.type === 'EDIT') {
      this.form.patchValue({
        ...this.params.data,
        revisionDate: this.params.data?.revisionDate
          ? format(new Date(this.params.data?.revisionDate), 'YYYY-MM-DD')
          : '',
        warrantyDate: this.params.data?.warrantyDate
          ? format(new Date(this.params.data?.warrantyDate), 'YYYY-MM-DD')
          : '',
      });
    }
  }

  private async submitCreate() {
    await this.prepareImages();
    const eventData = {
      event: 'equipment_create',
    };
    const value = RemoveEmpty(this.form.value);
    //console.log('submitCreate', value);
    this.isLoading = true;
    this.sub$.add(
      this.equipmentService.create(value).subscribe(
        (res) => {
          this.toastService.show({
            text: this.i18n.instant('equipments.create.success'),
            type: 'success',
            msDuration: 4000,
          });
          this.amplitudeService.sendEvent(eventData);
          this.brazeService.sendEvent(eventData);
          this.isLoading = false;
          this.parentFormSubmit = false;
          this.dialogRef.close(res);
        },
        (error) => {
          this.isLoading = false;
          this.parentFormSubmit = false;
          this.draw();
        }
      )
    );
  }

  private async submitUpdate() {
    await this.prepareImages();

    const value = RemoveEmpty(this.form.value);
    this.isLoading = true;
    this.sub$.add(
      this.equipmentService
        .updateOne(this.params.data?._id, {
          revisionDate: null,
          warrantyDate: null,
          ...value,
        })
        .subscribe(
          (res) => {
            this.toastService.show({
              text: this.i18n.instant('equipments.update.success'),
              type: 'success',
              msDuration: 4000,
            });
            this.isLoading = false;
            this.dialogRef.close(res);
          },
          (error) => {
            this.isLoading = false;
            this.draw();
          }
        )
    );
  }

  private async updateImage() {
    await this.prepareImages();
    this.draw();
    const value = RemoveEmpty(this.form.value);

    this.sub$.add(
      this.equipmentService
        .updateOne(this.params.data?._id, {
          revisionDate: null,
          warrantyDate: null,
          ...value,
        })
        .subscribe((res: any) => {
          this.refresh.next(res.files);
          this.refreshEquipment();
          this.draw();
        })
    );
  }

  private requestAssets() {
    this.assetsLoading = true;
    this.draw();

    this.sub$.add(
      // this.assetService.getAssetsCustomer(StorageService.CustomerId)
      this.assetService
        .findAllAssetsByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.assetsLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.assets = res;
          this.draw();
        })
    );
  }

  private requestUsers() {
    this.usersLoading = true;
    this.draw();

    this.sub$.add(
      // this.userService.findAllByCompany(StorageService.CompanyId)
      this.userService
        .findAllUsersByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.usersLoading = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.users = res;
          this.draw();
        })
    );
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);

    return this.form.valid;
  }

  private async prepareImages() {
    if (this.preRequestImages.size) {
      const files = [...this.preRequestImages.values()];

      const res = await this.filesUploadService
        .uploadFiles('equipment', files)
        .toPromise();

      const fileNames = res.map((element) => element.filename);

      const lastNames = this.form.get('files').value;

      this.preRequestImages.clear();
      this.form.patchValue({ files: [...lastNames, ...fileNames] });
      this.draw();
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  private resolveFinals() {
    this.finalsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.sub$.add(
            // this.finalService.search({ applyPaginate: false })
            this.finalService
              .findAllFinalsByCompany_SELECT()
              .pipe(
                // map((res) => res?.docs),
                map((res) => MapperFinal(res))
              )
              .subscribe((res) => {
                this.finals = res.map((item) => {
                  return {
                    key: `${item['name']} ${item['lastName'] || ''}`,
                    value: item['_id'],
                  };
                });
                this.finalsLoading = false;
                this.draw();
              })
          );
        })
    );
  }

  info(type2: string) {
    this.infoService.open(type2);
  }

  updateImages(images: any) {
    const result = this.filesUploadService.convertImages(images);
    this.form.patchValue({
      files: result,
    });

    if (this.params.data) {
      this.params.data.files = result;
    }

    this.draw();
  }

  showAlert(message: string) {
    this.toastService.show({
      text: this.i18n.instant(`errors.${message}`),
      type: 'error',
      msDuration: 4000,
    });
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setcustomProperties();
  }

  setcustomProperties() {
    let i = 0;
    for (i = 0; i < this.customProperties.length; i++) {
      if (this.customProperties[i].isRequired) {
        this.form.addControl(
          this.customProperties[i]._id,
          new FormControl('', Validators.required)
        );
      }
    }
    this.initChildForm = true;
    this.parentFormSubmit = false;
  }

  fetchPropertyValue(propertyId) {
    this.form.controls[propertyId].setValue(propertyId);
  }

  propertiesRequired(value) {
    this.invalidEditCustomFields = value;
  }
}
