export enum ClockHourView {
  Week = 'week',
  Month = 'month',
}

export enum CurrentDateFormat {
  Short = 'short',
  Long = 'long',
}

export type TeamMember = {
  user: User;
  metrics: Metrics;
};

export type Metrics = {
  workedHours: number | string | null;
  contractHours: number | string | null;
  remainingHours: number | string | null;
  extraHours: number | string | null;
  pendingRegisters: number | string | null;
};

export type User = {
  id: string;
  name: string;
  avatar: string;
};
