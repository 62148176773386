<div class="new-register">
  <div class="img-register"></div>
  <!-- register form -->
  <div class="modal-register">
    <roma-recaptcha-script></roma-recaptcha-script>
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      class="form-register"
      *ngIf="activeStep === 'ZERO'"
    >
      <div class="justify-content-center text-center mb-3">
        <img class="logo" src="/assets/img/logo-taclia.svg" alt="taclia" />
      </div>
      <p class="start-now">{{ 'register.create_account' | translate }}</p>
      <!-- EMAIL -->
      <div class="mb-3 email-input">
        <label class="form-text mb-8">{{ 'register.email' | translate }}</label>
        <input
          type="email"
          autocomplete="email"
          inputmode="email"
          formControlName="email"
          name="email"
          class="form-control general-form-control md"
          aria-label="Correo electrónico"
          placeholder="{{ 'register.email-example' | translate }}"
        />
        <div
          class="general-invalid-feedback"
          *ngIf="
            f.email.errors &&
            f.email.touched &&
            !f.email.errors?.invalidAsync &&
            !f.email.errors?.invalidDomain &&
            !f.email.errors?.email
          "
        >
          {{ 'register.error.errorEmail' | translate }}
        </div>
        <div
          class="general-invalid-feedback"
          *ngIf="
            f.email.errors &&
            f.email.touched &&
            !f.email.errors?.invalidAsync &&
            f.email.errors?.invalidDomain &&
            !f.email.errors?.email
          "
        >
          {{ 'register.error.valid_email' | translate }}
        </div>
        <div
          class="general-invalid-feedback"
          *ngIf="
            f.email.errors &&
            f.email.touched &&
            f.email.errors?.email &&
            !f.email.errors?.invalidAsync
          "
        >
          {{ 'register.error.valid_email' | translate }}
        </div>
        <div
          class="general-invalid-feedback"
          *ngIf="
            f.email.errors && f.email.touched && f.email.errors?.invalidAsync
          "
        >
          {{ 'register.error.repeat_email' | translate }}
        </div>
      </div>
      <!-- PHONE -->
      <div class="mb-3 email-input" *ngIf="registerAskForPhone">
        <label class="form-text mb-8">{{ 'register.phone' | translate }}</label>
        <roma-rm-phone-select-input
          [ngClass]="{
            error: phoneErrors
          }"
          [inputClass]="'placeholderBlue'"
          [phoneValue]="initialPhoneValue"
          [autoPrefix]="true"
          (onPrefixChange)="onPrefixChange($event)"
          (phoneChanges)="phoneChange($event)"
        ></roma-rm-phone-select-input>
        <div class="general-invalid-feedback" *ngIf="phoneErrors">
          {{ 'register.error.phoneNeeded' | translate }}
        </div>
      </div>
      <!-- PASSWORD -->
      <div class="mb-3">
        <label class="form-text mb-8">{{
          'register.password' | translate
        }}</label>
        <div class="password-input">
          <input
            [type]="showPassword ? 'text' : 'password'"
            formControlName="password"
            name="password"
            type="password"
            maxlength="24"
            class="form-control general-form-control md"
            aria-label="Contraseña"
            placeholder="{{ 'register.passwordPlaceHolder' | translate }}"
            autocomplete="new-password"
          />
          <img
            (click)="showPassword = true"
            src="/assets/img/icons/gl_eye-off-new.svg"
            alt="Mostrar Contraseña"
            *ngIf="!showPassword"
            style="margin-top: 2px"
          />
          <img
            (click)="showPassword = false"
            *ngIf="showPassword"
            src="/assets/img/icons/gl_eye-on.svg"
            alt="Mostrar Contraseña"
            style="margin-top: 2px"
          />
          <div
            class="general-invalid-feedback"
            *ngIf="
              f.password.errors &&
              f.password.touched &&
              !f.password.errors?.minlength
            "
          >
            {{ 'register.error.errorMinPass' | translate }}
          </div>
          <div
            class="general-invalid-feedback"
            *ngIf="f.password.errors?.minlength && f.password.touched"
          >
            {{ 'register.error.errorMinPass' | translate }}
          </div>
        </div>
      </div>
      <!-- COUNTRY -->
      <div class="mb-3 country">
        <label class="form-text mb-8">{{
          'register.country' | translate
        }}</label>
        <div [ngClass]="{ 'border-select': showCountry }">
          <div class="country-input">
            <input
              id="inputCountry"
              class="form-control general-form-control md arrCountry"
              aria-label="Country"
              (input)="searchCountry($event)"
              [ngClass]="{
                'error-input': errorCountry && !showCountry
              }"
              value="{{ selectedPrefix }}"
              placeholder="{{ 'general.selected' | translate }}"
              [readonly]="isMobile"
              (click)="openModalCountry()"
            />
            <input type="hidden" formControlName="country" />

            <img
              id="inputCountry"
              class="prefix__arrow arrCountry"
              src="assets/icons/gl_down_arrow.svg"
              (click)="showCountry = !showCountry"
            />
          </div>

          <div
            class="general-invalid-feedback"
            *ngIf="errorCountry && !showCountry"
          >
            {{ 'register.error.optionSelect' | translate }}
          </div>
          <!-- ARR COUNTRY -->
          <div
            class="country__codes__list"
            *ngIf="showCountry"
            id="countryModule"
          >
            <div
              class="country__codes__list__prefix single-checkl"
              *ngFor="let prefix of countryCodes"
              (click)="setCountry(prefix)"
              [ngClass]="{
                active: form.value.country === prefix.code,
                noPointer: prefix.name === ('general.noMatches' | translate)
              }"
            >
              <img
                *ngIf="form.value.country === prefix.code"
                class="active-check"
                src="assets/icons/gl_check.svg"
              />
              <span style="margin-left: 16px">{{ prefix.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- CHECKS -->
      <div class="mt-2" style="margin-bottom: 8px">
        <label class="text-terms">
          {{ 'register.terms1' | translate }}
          <a
            class="pointer"
            (click)="browserOpen('https://www.taclia.com/aviso-legal')"
            >{{ 'register.terms2' | translate }}</a
          >
          {{ 'register.terms3' | translate }}
          <a
            class="pointer"
            (click)="
              browserOpen('https://www.taclia.com/politica-de-privacidad')
            "
            >{{ 'register.terms4' | translate }}</a
          >
        </label>
      </div>

      <!-- BUTTON -->
      <div class="footer-button-register">
        <button
          class="button-register button-register-start-free"
          type="submit"
          [disabled]="flightRequest"
        >
          {{ 'register.start_free_trial' | translate }}

          <div class="submit-status" *ngIf="flightRequest">
            <mat-spinner diameter="20"></mat-spinner>
          </div>
        </button>
        <p class="no-card-needed">{{ 'register.textRegister' | translate }}</p>
        <p class="text-terms" style="text-align: center">
          {{ 'register.questionAccount' | translate }}
          <a (click)="goLogin()" style="cursor: pointer">{{
            'register.goLogin' | translate
          }}</a>
        </p>
      </div>
      <!-- COUNTRY MOBILE -->
      <ng-container *ngIf="showCountry">
        <div class="list-dropdown-select arrCountry">
          <div class="mobile-display">
            <div class="mobile-list-wrapper"></div>
            <div class="list-box">
              <h4>{{ 'register.country' | translate }}</h4>
              <div class="search-input secondary">
                <img
                  src="assets/icons/gl_search.svg"
                  style="left: 5px !important; top: 17px !important"
                />
                <input
                  class="filter-input arrCountry"
                  type="text"
                  id="inputCountry"
                  (input)="searchCountry($event)"
                  [placeholder]="'general.search' | translate"
                />
              </div>
              <div class="list-wrapper">
                <div
                  class="list-item"
                  *ngFor="let prefix of countryCodes"
                  (click)="setCountry(prefix)"
                  [ngClass]="{
                    active: form.value.country === prefix.code,
                    noPointer: prefix.name === ('general.noMatches' | translate)
                  }"
                  style="padding-left: 28px"
                >
                  <img
                    *ngIf="form.value.country === prefix.code"
                    class="active-check"
                    style="visibility: visible"
                    src="assets/icons/gl_check.svg"
                  />
                  {{ prefix.name }}
                </div>
              </div>
              <div class="text-center close-btn-wrapper">
                <img
                  (click)="showCountry = false"
                  class="close-btn"
                  src="/assets/icons/btn_close.svg"
                  alt="close"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
    <div [ngClass]="{ steps: !isMobile }" *ngIf="activeStep !== 'ZERO'">
      <!-- step one -->
      <div *ngIf="activeStep === 'ONE'">
        <div class="header-mobile-content mb-4">
          <img
            class="logo"
            *ngIf="isMobile"
            src="/assets/img/logo-taclia.svg"
            alt="taclia"
          />
          <div
            class="footer-progress footer-progress-step-two"
            *ngIf="isMobile"
          >
            <p style="color: #3c48ec">
              <span style="font-weight: 600"
                >{{ 'register.step' | translate }} 1
              </span>
              <span style="color: #8c9dd2" class="next"
                >{{ 'register.of' | translate }} 2</span
              >
            </p>
          </div>
          <div
            (click)="cancelOnboarding()"
            class="cancel__button"
            [ngClass]="{ is_absolute: !isMobile }"
            [ngStyle]="{
              display: scrollActive && !isMobile ? 'none' : 'block'
            }"
          >
            <img src="/assets/icons/gl_close.svg" class="img-back" />
          </div>
        </div>

        <form
          [formGroup]="companyCategory"
          (ngSubmit)="onStart()"
          class="form-wraper"
        >
          <div [ngStyle]="{ display: scrollActive ? 'none' : 'block' }">
            <h1 class="modal-title">
              {{ 'register.adapting_to_you' | translate }}
            </h1>
            <p class="modal-subtitle">
              {{ 'register.select_sector' | translate }}
            </p>
          </div>

          <div
            style="min-height: 280px"
            [ngStyle]="{ 'max-height': screenHeight }"
          >
            <div
              class="form-cards"
              (scroll)="checkScroll($event)"
              [ngStyle]="{ 'max-height': screenHeight }"
              id="sectorCards"
            >
              <div class="form register-header-step-two">
                <div class="formSelectRegister">
                  <rm-select-register
                    style="width: 100%"
                    [isClearable]="true"
                    [placeholderText]="'general.select_category' | translate"
                    [items]="categories"
                    [isLoading]="isLoadingCategories"
                    [disabled]="isLoadingCategories"
                    [activeItems]="selectedCategory"
                    [isAbsolute]="true"
                    [hasError]="
                      onSubmit && form.controls['category']?.value === ''
                    "
                    inputHeight="44px"
                    [listStyle]="{ width: '98%', height: '200px' }"
                    [listStyleItem]="{
                      'white-space': 'normal',
                      height: 'auto'
                    }"
                    [changeDropDownIcon]="false"
                    (click)="openSelectCategory()"
                    (changeItems)="changeCategory($event)"
                  >
                  </rm-select-register>
                </div>

                <div class="form-separator"></div>
                <div class="search-input">
                  <img
                    class="left"
                    src="../../../../../assets/icons/gl_search.svg"
                  />
                  <input
                    (click)="onSearchClick($event)"
                    (keypress)="onSearchKeyPress($event)"
                    type="text"
                    class="form-input"
                    formControlName="search"
                    [placeholder]="'general.find_business' | translate"
                  />
                  <!-- add x button -->
                  <ng-container *ngIf="companyCategory.value.search">
                    <img
                      class="clear-search"
                      (click)="clearSearch()"
                      src="../../../../../assets/icons/gl_close-input.svg"
                    />
                  </ng-container>
                </div>
              </div>
              <div
                id="sectorContainer_{{ sector._id }}"
                class="item-sector"
                *ngFor="let sector of sectors"
              >
                <input
                  type="radio"
                  value="{{ sector._id }}"
                  autocomplete="off"
                  formControlName="sector"
                  autocomplete="off"
                  id="{{ sector._id }}"
                />
                <label for="{{ sector._id }}">
                  <div>
                    <img
                      src="../../../../../assets/icons/register/{{
                        sector.iconRef
                      }}.svg"
                      class="module-icon"
                    />
                  </div>
                  <span
                    *ngIf="sector.nameRef !== OTHER_SECTOR; else otherSector"
                    style="display: flex; font-weight: 600"
                  >
                    {{ 'register.' + sector.nameRef | translate }}
                  </span>
                  <ng-template #otherSector>
                    <div style="text-align: left">
                      {{ 'register.other_sector' | translate }}
                      <div class="label-comment">
                        {{ 'register.if_not_find__your_business' | translate }}
                      </div>
                    </div>
                  </ng-template>
                </label>
              </div>
            </div>
          </div>

          <div>
            <div
              class="footer-button button-next button-next-step-two"
              *ngIf="isMobile"
            >
              <div class="custom-tooltip top">
                <div
                  class="tiptext"
                  *ngIf="actualSector === ''"
                  style="width: 240px"
                >
                  {{ 'register.button_tool_tip_step_two' | translate }}
                </div>

                <button
                  class="button-register"
                  type="submit"
                  (click)="validateNextStep()"
                  [disabled]="flightRequest || this.companyCategory.invalid"
                >
                  <ng-container *ngIf="!flightRequest">
                    {{ 'general.next' | translate }}
                  </ng-container>

                  <ng-container *ngIf="flightRequest">
                    <div class="submit-status">
                      <mat-spinner diameter="20"></mat-spinner>
                    </div>
                  </ng-container>
                </button>
              </div>
            </div>

            <div class="register-footer no-mobile" *ngIf="!isMobile">
              <div class="footer-progress footer-progress-step-two">
                <p style="color: #3c48ec">
                  <span style="font-weight: 600"
                    >{{ 'register.step' | translate }} 1</span
                  >
                  <span style="color: #8c9dd2" class="next"
                    >{{ 'register.of' | translate }} 2</span
                  >
                </p>
              </div>

              <div class="button-next button-next-step-two">
                <div class="custom-tooltip top">
                  <div
                    class="tiptext"
                    [hidden]="this.companyCategory.status === 'VALID'"
                    style="width: 230px; bottom: 78px; text-align: left"
                  >
                    {{ 'register.button_tool_tip_step_two' | translate }}
                  </div>
                  <div class="footer-button">
                    <div
                      class="back__button mobile"
                      *ngIf="isMobile"
                      (click)="returnStep('ONE')"
                    >
                      <img
                        src="/assets/icons/gl_chevron-left.svg"
                        class="img-back"
                      />
                      {{ 'register.return' | translate }}
                    </div>
                    <button
                      class="button-register"
                      type="submit"
                      (click)="validateNextStep()"
                      [disabled]="flightRequest || this.companyCategory.invalid"
                    >
                      <ng-container *ngIf="!flightRequest">
                        {{ 'general.next' | translate }}
                      </ng-container>

                      <ng-container *ngIf="flightRequest">
                        <div class="submit-status">
                          <mat-spinner diameter="20"></mat-spinner>
                        </div>
                      </ng-container>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- step Two -->
      <div *ngIf="activeStep === 'TWO'" [ngClass]="isMobile && 'header-mobile'">
        <div class="header-mobile-content mb-4">
          <img
            class="logo mobile"
            *ngIf="isMobile"
            src="/assets/img/logo-taclia.svg"
            alt="taclia"
          />
          <div
            class="footer-progress footer-progress-step-two mobile"
            *ngIf="isMobile"
          >
            <p style="color: #3c48ec">
              <span style="font-weight: 600"
                >{{ 'register.step' | translate }} 2
              </span>
              <span style="color: #8c9dd2" class="next"
                >{{ 'register.of' | translate }} 2</span
              >
            </p>
          </div>
          <div
            class="back__button no-mobile"
            (click)="returnStep('ONE')"
            *ngIf="!isMobile"
          >
            <img src="/assets/icons/gl_chevron-left.svg" class="img-back" />
            {{ 'register.return' | translate }}
          </div>
          <div
            class="cancel__button"
            [ngClass]="{ is_absolute: !isMobile }"
            (click)="cancelOnboarding()"
          >
            <img src="/assets/icons/gl_close.svg" class="img-back" />
          </div>
        </div>

        <form
          [formGroup]="companyFunctions"
          (ngSubmit)="onStart()"
          [ngClass]="isMobile ? 'form-mobile' : 'form-desktop'"
        >
          <div>
            <h1 class="modal-title">
              {{ 'register.start_tools' | translate }}
            </h1>
            <p class="modal-subtitle">
              {{ 'register.start_tools_info' | translate }}
            </p>
          </div>
          <div class="form-cards columns">
            <div
              class="item-sector"
              *ngFor="let sector of itemsStepTWo"
              (click)="selectSector = sector"
            >
              <input
                type="radio"
                [value]="sector"
                autocomplete="off"
                formControlName="tool"
                autocomplete="off"
                [id]="sector"
              />
              <label [for]="sector">
                <div>
                  <img
                    [src]="'assets/icons/register/tool_' + sector + '.svg'"
                    class="module-icon"
                  />
                </div>
                <span
                  [innerHTML]="'register.tools.' + sector | translate"
                ></span>
              </label>
            </div>
          </div>

          <div class="register-footer">
            <div class="footer-progress footer-progress-step-two">
              <span
                >{{ 'register.step' | translate }} 2
                <span class="next"
                  >{{ 'register.of' | translate }} 2</span
                ></span
              >
            </div>

            <div class="footer-button button-next button-next-step-two">
              <span
                (click)="returnStep('ONE')"
                class="back-button-two mobile"
                *ngIf="isMobile"
              >
                <img
                  src="/assets/icons/gl_chevron-left.svg"
                  style="width: 24px; height: 24px"
                  class="img-back"
                />
                <span>{{ 'register.return' | translate }}</span>
              </span>
              <div class="custom-tooltip top">
                <div
                  class="tiptext"
                  [hidden]="this.companyFunctions.status === 'VALID'"
                  style="width: 240px"
                >
                  {{ 'register.select_tool' | translate }}
                </div>

                <button
                  class="button-register"
                  type="submit"
                  [disabled]="flightRequest || this.companyFunctions.invalid"
                >
                  <ng-container *ngIf="!flightRequest">
                    {{ 'login.start' | translate }}
                  </ng-container>

                  <ng-container *ngIf="flightRequest">
                    <div class="submit-status">
                      <mat-spinner diameter="20"></mat-spinner>
                    </div>
                  </ng-container>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- send data loading -->
      <div *ngIf="activeStep === 'FINAL'">
        <div class="register-header">
          <div class="header-icon">
            <div class="step-apply-div-icon">
              <img
                src="../../../../../assets/icons/register/{{
                  selectedSector.iconRef
                }}.svg"
                class="step-apply-icon"
              />
            </div>
          </div>
          <p class="sub-title-steps step-three">
            {{ 'register.adapting_to_your_business' | translate }}
          </p>
          <div class="register-progress-bar">
            <div class="register-progress-bar-value"></div>
          </div>
        </div>
      </div>
      <div *ngIf="activeStep === 'UTM'">
        <div class="register-header">
          <div class="header-icon">
            <div class="step-apply-div-icon">
              <img
                src="assets/icons/register/sec_otro-sector.svg"
                class="step-apply-icon"
              />
            </div>
          </div>
          <p class="sub-title-steps step-three">
            {{ 'register.adapting_to_your_business' | translate }}
          </p>
          <div class="register-progress-bar">
            <div class="register-progress-bar-value"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
