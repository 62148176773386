/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { concatMap, filter, finalize, map, take, tap } from 'rxjs/operators';

import { MatTableDataSource } from '@angular/material/table';

import {
  BudgetPreferences,
  BudgetsSetting,
  Company,
  ConvertToKeyValue,
  Deal,
  DealsColumnsCustomer,
  DealsWrapperCustomPropertySort,
  DealsWrapperPropertySort,
  DealType,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  HiringRegisterDto,
  IBudgetsSetting,
  ICustomProperty,
  IDeal,
  IFinal,
  IInternalVendor,
  IOwnTeam,
  IStatus,
  IUser,
  KanbanCard,
  numbers_format,
  PaginateResponse,
  PaginateSort,
  Status,
  STATUS_REQUESTED_BUDGET,
  TypeRol,
} from '@tacliatech/types';

import { DatePipe, formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { Workbook } from 'exceljs';

import {
  AdminService,
  AssetSearchService,
  AssetService,
  DealSearchService,
  DealService,
  DynamicPropertyService,
  FinalService,
  HiringService,
  ProjectService,
  StatusService,
  StorageService,
} from '@web-frontend/shared/services';

import { FilterItems } from '@web-frontend/shared/components/filter';

import { TableSortEvent } from '@web-frontend/shared/components/table';

import { AlertService } from '@web-frontend/shared/helpers/alert';
import {
  CompareObjects,
  getCustomValue,
  RemoveEmpty,
} from '@web-frontend/shared/utils';

import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CreateDealService } from '@web-frontend/shared/components/create-deal';
import { DealCalendarComponent } from '@web-frontend/shared/components/deal-calendar';
import { DealCancelComponent } from '@web-frontend/shared/components/deal-cancel/deal-cancel.component';
import { DealStatusColors } from '@web-frontend/shared/components/deals/deal-status-manager/deal-status-manager-.const';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { TableComponent } from '@web-frontend/shared/components/table/table.component';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import { AuthService } from '@web-frontend/shared/services/auth';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import {
  CompanyModuleService,
  CompanyService,
} from '@web-frontend/shared/services/company';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { ScrollService } from '@web-frontend/shared/services/scroll';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { ToastData } from '@web-frontend/shared/services/toast/utils/toast-config';
import { UserService } from '@web-frontend/shared/services/users';
import {
  SOURCE_QUICK_OPTIONS,
  SOURCE_WEB,
  SOURCE_WEB_OPTIONS,
} from './deals.const';
import { INIT_DEAL_SEARCH_PARAMS } from './deals.types';
import { ListMobileDealsComponent } from './roma-list-mobile-deals/list-mobile-deals.component';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import {
  dateAndHourToTimestamp,
  timestampToDate,
  timestampToHour,
} from '@web-frontend/shared/utils/dateAndTime';

@Component({
  selector: 'roma-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsComponent implements OnInit, OnDestroy {
  tableMarginTop = '0px';
  distanceToTop = 0;

  @Input()
  customer: IFinal;

  @Input()
  showCalendar = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
  }

  @HostBinding('class.roma-page')
  hostClass = true;

  @ViewChild(TableComponent) table: TableComponent;
  @ViewChild(ListMobileDealsComponent) mobileList: ListMobileDealsComponent;

  @ViewChild(DealCalendarComponent)
  dealCalendarComponent: DealCalendarComponent;

  viewTypeActive: 'ROW' | 'PIPELINE' | 'CALENDAR' | 'TRACKING' = 'ROW';
  roles = TypeRol;
  user: IUser;

  currencys = this.budgetService.findAllCurrencys();
  DEFAULT_CURRENCY_SYMBOL = '€';

  defaultSettings = new BudgetsSetting();
  defaultPreferences = this.defaultSettings.budgetPreferences;
  private selectedColor: { darkColor: string; lightColor: string } =
    DealStatusColors[0];

  statusToDealPipeline: IStatus[] = [];
  status: FilterItems = [];
  authors: FilterItems = [];
  assets: FilterItems = [];
  projects: FilterItems = [];
  finals: FilterItems = [];

  isLoading = false;
  isLoadingPaginate = false;

  entriesForDelete!: Deal.Output[];
  deals = new MatTableDataSource<Deal.Output>([]);
  resultSearch: PaginateResponse<Deal.Output[]>;
  dealsMap = new Map<string, Deal.Output>();

  customProperties: ICustomProperty[] = [];
  internalVendors: IInternalVendor[] = [];
  ownTeam: IUser[] = [];
  userVendors: IUser[] = [];
  columns = [];
  statusObject = Status;

  markersDeals: Array<any> = [];
  markersUsers: Array<any> = [];
  usersSelected: Array<any> = [];
  totalPages: any[] = [];
  trackingDate: any;
  linesMap: any;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  allowShowButtonDownTakeMore = false;
  sourceSearch = SOURCE_WEB;
  sourceOptions!: RmFilter.Filter;
  quickOptions = null;

  private sub$ = new Subscription();
  private flightRequest: Subscription;
  private storeSearchParams = INIT_DEAL_SEARCH_PARAMS();
  private busSearchParamsChanges = new Subject();

  isUpdatingMultiple = false;
  isMovingMultiple = false;
  isCancelMultiple = false;
  isFinishedMultiple = false;
  isUnFinishedMultiple = false;

  searchParamsHasChange$ = this.busSearchParamsChanges
    .asObservable()
    .pipe(map((res) => !CompareObjects(INIT_DEAL_SEARCH_PARAMS(), res)));

  resize$ = this.sandBoxService.screenBusChannel$;

  settings: BudgetPreferences;
  openMobileViewType = false;

  company_date_format: string = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  showToolTip = false;
  deleteMessage = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';

  videoIframeURL: SafeResourceUrl = '';

  moreTableFields = [
    {
      key: 'status',
      title: this.i18n.instant('table.colum.status'),
    },
    {
      key: 'reporter',
      title: this.i18n.instant('table.colum.manager'),
    },
    {
      key: 'assetObj',
      title: this.i18n.instant('table.colum.asset'),
      module: Company.IdModule.Assets,
    },
    {
      key: 'project',
      title: this.i18n.instant('table.colum.project'),
      module: Company.IdModule.Projects,
    },
    {
      key: 'productCategory',
      title: this.i18n.instant('hiring.type.category'),
    },
  ];

  private hasSeeServiceKanbanByFirst!: boolean;

  scrollDown$ = this.scrollService.scrollDown$;
  academyLink = '';
  showVideoIframe = false;

  constructor(
    private authService: AuthService,
    private statusService: StatusService,
    private dealSearchService: DealSearchService,
    private dynamicPropertyService: DynamicPropertyService,
    private changeDetectorRef: ChangeDetectorRef,
    private alertService: AlertService,
    private i18n: TranslateService,
    private router: Router,
    private userService: UserService,
    private assetService: AssetService,
    private assetSearchService: AssetSearchService,
    private finalService: FinalService,
    private adminService: AdminService,
    private datePipe: DatePipe,
    private projectService: ProjectService,
    private fileWrite: FileWriteService,
    private video: VideoService,
    private amplitudeService: AmplitudeService,
    private sandBoxService: SandboxService,
    private domSanitzer: DomSanitizer,
    private budgetService: BudgetService,
    private matIconRegistry: MatIconRegistry,
    private ngZone: NgZone,
    private companyModuleService: CompanyModuleService,
    private permissionService: PermissionService,
    public dialog: MatDialog,
    public dealsServices: DealService,
    public hiringService: HiringService,
    public tutorialService: TutorialService,
    private toastService: ToastService,
    private createDealService: CreateDealService,
    private companyService: CompanyService,
    private scrollService: ScrollService,
    private readonly brazeService: BrazeService,
    private readonly downloadBtnService: DownloadBtnService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.matIconRegistry.addSvgIcon(
      'table_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/table-horizontal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pipeline_icon',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/trello.svg')
    );
  }

  public refresh() {
    return this.draw();
  }

  get searchParams() {
    return Object.assign(this.storeSearchParams, {
      'finals[]': this.customer?._id
        ? [this.customer?._id]
        : this.storeSearchParams['finals[]'],
    });
  }

  set searchParams(value) {
    this.busSearchParamsChanges.next(value);
    this.storeSearchParams = value;
  }

  ngOnInit(): void {
    this.getModuleResources();
    this.resolveColumnsView();
    this.getSettings();
    this.watchUserActive();
    this.watchRefreshList();
    this.requestDynamicProperties();
    this.requestStatus();
    this.resolveFirstDealService();

    let value: 'ROW' | 'PIPELINE' | 'CALENDAR' | 'TRACKING' = 'PIPELINE';

    const lastViewType = localStorage.getItem('deals.lastViewType');
    if (lastViewType) {
      value = lastViewType as 'ROW' | 'PIPELINE' | 'CALENDAR' | 'TRACKING';
    }

    if (!this.showCalendar) {
      value = 'ROW';
    }

    this.changeViewType(value);
  }

  ngOnDestroy() {
    this.adminService.overflow = false;
    this.sub$.unsubscribe();
    this.flightRequest?.unsubscribe();
  }

  getSettings() {
    this.companyModuleService.idActiveModules$
      .pipe(filter((res) => res.includes(Company.IdModule.Sales)))
      .subscribe(() => {
        const id = StorageService.CompanyId;

        this.budgetService
          .findSettingsByCompany(id)
          .subscribe((resp: IBudgetsSetting) => {
            if (resp) {
              this.settings = resp.budgetPreferences;
            }
            this.draw();
          });
      })
      .unsubscribe();
  }

  getLang() {
    return this.i18n.currentLang;
  }

  private watchUserActive() {
    this.sub$.add(
      this.authService.user$.subscribe((res) => {
        this.user = res;
      })
    );
  }

  private resolveColumnsView() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$.subscribe((res) => {
        if (res.length) {
          this.columns = Company.FilterColumnReports({
            columnsReports: DealsColumnsCustomer,
            nameColumnsReport: [
              'addressAsset',
              'final',
              'project',
              'bills',
              'budget',
            ],
            idModules: [
              Company.IdModule.Assets,
              Company.IdModule.Finals,
              Company.IdModule.Projects,
              Company.IdModule.Sales,
              Company.IdModule.Sales,
            ],
            idActivesModules: res,
          });

          this.draw();
        }
      })
    );
  }

  private requestDealsKanban() {
    this.isLoading = true;
    this.draw();

    const query: any = RemoveEmpty(this.searchParams);

    // prevent send query.from without query.to
    if (query.from && !query.to) {
      delete query.from;
    }

    const request$ = this.dealSearchService.search(query).pipe(
      finalize(() => {
        this.isLoading = false;
        this.calcTableDistance();
        this.draw();
      })
    );

    if (this.flightRequest) {
      this.flightRequest.unsubscribe();
    }

    return request$.pipe(
      tap((res) => {
        this.setDeals(res);
        this.setCards(res);
        if (res.hasNextPage) {
          this.allowShowButtonDownTakeMore = true;
        }
      })
    );
  }

  private requestDeals(loadingGlobal = false, loadingPagination = false) {
    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    if (loadingPagination) {
      this.isLoadingPaginate = true;
      this.draw();
    }

    const query: any = RemoveEmpty(this.searchParams);

    // prevent send query.from without query.to
    if (query.from && !query.to) {
      delete query.from;
    }

    const request$ = this.dealSearchService.search(query).pipe(
      finalize(() => {
        if (loadingGlobal) {
          this.isLoading = false;
        }

        if (loadingPagination) {
          this.isLoadingPaginate = false;
        }

        this.calcTableDistance();
        this.draw();
      })
    );

    if (this.flightRequest) {
      this.flightRequest.unsubscribe();
    }

    this.flightRequest = request$.subscribe((res) => {
      this.setDeals(res);
      this.setCards(res);
      if (res.hasNextPage) {
        this.allowShowButtonDownTakeMore = true;
      }
    });
  }

  private requestAuthors() {
    this.sub$.add(
      this.assetService.findUsersByAssetsShared().subscribe((res) => {
        this.authors = ConvertToKeyValue(res, 'name', '_id');
        this.draw();
      })
    );
  }

  private requestProjects() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
        .subscribe(() => {
          this.sub$.add(
            this.projectService
              .search({ applyPaginate: false })
              .pipe(map((res) => res.docs))
              .subscribe((res) => {
                this.projects = ConvertToKeyValue(res, 'name', '_id');
                this.draw();
              })
          );
        })
    );
  }

  private requestFinals() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.sub$.add(
            this.finalService
              .search({ applyPaginate: false })
              .pipe(map((res) => res.docs))
              .subscribe((res) => {
                this.finals = ConvertToKeyValue(res, 'name', '_id');
                this.draw();
              })
          );
        })
    );
  }

  private requestAssets() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Assets)))
        .subscribe(() => {
          this.sub$.add(
            this.assetSearchService
              .search({
                applyPaginate: false,
                customer: StorageService.CustomerId,
              })
              .subscribe((res) => {
                this.assets = ConvertToKeyValue(res?.docs, 'name', '_id');
                this.draw();
              })
          );
        })
    );
  }

  private requestDynamicProperties() {
    this.sub$.add(
      this.dynamicPropertyService
        .findDynamicProperties(
          StorageService.CompanyId,
          DynamicPropertyRef.Deal
        )
        .subscribe((res) => {
          this.customProperties = res;
          this.sourceOptions = SOURCE_WEB_OPTIONS({
            assetService: this.assetService,
            companyModuleService: this.companyModuleService,
            finalService: this.finalService,
            projectService: this.projectService,
            statusService: this.statusService,
            assetSearchService: this.assetSearchService,
            customProperties: res,
            i18n: this.i18n,
          });

          // Filters to "View tasks" button
          this.quickOptions = new RmFilter.Filter(SOURCE_QUICK_OPTIONS);

          this.draw();
        })
    );
  }

  changeAuthor(evt: FilterItems) {
    const authors = evt.map((author) => author.value);
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'authors[]': authors,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeManager(evt: FilterItems) {
    const managers = evt.map((manager) => manager.value);
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'managers[]': managers,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeAssigned(evt: FilterItems) {
    const taskers = evt.map((tasker) => tasker.value);
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'taskers[]': taskers,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeTypes(evt: FilterItems) {
    const dealTypes = evt.map((type) => type.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'dealTypes[]': dealTypes,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeAssets(evt: FilterItems) {
    const assets = evt.map((type) => type.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'assets[]': assets,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeCancelled(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeCancelled: evt,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeToday(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeToday: evt,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeFinished(evt: boolean) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeFinished: `${evt}`,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  applyFilters(evt: {
    assets: Array<{ value: string }>;
    finals: Array<{ value: string }>;
    projects: Array<{ value: string }>;
    managers: Array<{ value: string }>;
    status: Array<{ value: string }>;
    dealTypes: Array<{ value: string }>;
    startDate: string;
    endDate: string;
    takeCancelled: boolean;
    takeFinished: boolean;
    takeNotified: boolean;
    takeToday: boolean;
    customProperties: { [key: string]: any };
  }) {
    const status = evt?.status?.length
      ? evt.status.map((type) => type.value)
      : [];
    const finals = evt?.finals?.length
      ? evt.finals.map((type) => type.value)
      : [];
    const managers = evt?.managers?.length
      ? evt.managers.map((type) => type.value)
      : [];
    const projects = evt?.projects?.length
      ? evt.projects.map((type) => type.value)
      : [];
    const dealTypes = evt?.dealTypes?.length
      ? evt.dealTypes.map((type) => type.value)
      : [];
    const assets = evt?.assets?.length
      ? evt.assets.map((type) => type.value)
      : [];
    const startDate = evt.startDate;
    const endDate = evt.endDate;
    const takeCancelled = evt.takeCancelled;
    const takeNotified = evt.takeNotified;
    const takeToday = evt.takeToday;
    const takeFinished = evt.takeFinished;

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'status[]': status,
      'finals[]': finals,
      'managers[]': managers,
      'projects[]': projects,
      'dealTypes[]': dealTypes,
      'assets[]': assets,
      ['from']: startDate,
      ['to']: endDate,
      takeCancelled: takeCancelled,
      takeNotified: takeNotified,
      takeToday: takeToday,
      takeFinished: `${takeFinished}`,
      customProperties: JSON.stringify(evt.customProperties),
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeStatus(evt: FilterItems) {
    const status = evt.map((type) => type.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'status[]': status,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeFinal(evt: FilterItems) {
    const finals = evt.map((type) => type.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'finals[]': finals,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeProject(evt: FilterItems) {
    const finals = evt.map((type) => type.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'projects[]': finals,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeInput(keyword: string) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      keyword: keyword ? keyword : null,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeCustomer(customers: string[]) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'customers[]': customers,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeVendor(vendors: string[]) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'vendors[]': vendors,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeDate(type: 'START' | 'END', value: Date) {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      [type === 'START' ? 'from' : 'to']: value,
    };

    this.clear();
    this.requestDeals(true, false);
  }

  changeCustomProperties(customProperties: { [key: string]: any }) {
    this.searchParams = {
      ...this.searchParams,
      customProperties: JSON.stringify(customProperties),
    };

    this.clear();
    this.requestDeals(true, false);
  }

  takeMore() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };
    this.requestDeals(false, true);
  }

  hasQuerySearch() {
    return (
      this.searchParams.author ||
      this.searchParams.from ||
      this.searchParams.to ||
      this.searchParams.status ||
      this.searchParams.keyword
    );
  }

  changeViewType(evt: 'ROW' | 'PIPELINE' | 'CALENDAR' | 'TRACKING') {
    const value = evt;

    localStorage.setItem('deals.lastViewType', value);

    this.deleteMessage = false;

    this.viewTypeActive = value;

    if (!this.viewTypeActive) {
      this.viewTypeActive == 'ROW';
    }

    this.adminService.overflow = false;
    this.openMobileViewType = false;
    this.searchParams.limit = this.viewTypeActive === 'PIPELINE' ? 1000 : 50;

    if (this.viewTypeActive === 'PIPELINE') {
      // Load deals when Pipeline view is open
      if (!this.hasSeeServiceKanbanByFirst) {
        const updateCompanyService$ = this.companyService
          .findOneAndUpdate(StorageService.CompanyId, {
            customerId: StorageService.CustomerId,
            hasSeeServiceKanbanByFirst: true,
          })
          .pipe(
            tap(() => {
              this.hasSeeServiceKanbanByFirst = true;
              this.draw();
            })
          );

        updateCompanyService$
          .pipe(concatMap(() => this.requestDealsKanban()))
          .subscribe((res) => {});
      } else {
        this.requestDeals(true, false);
      }

      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board,
      });
    } else if (this.viewTypeActive === 'CALENDAR') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_calendar_view,
      });
    } else if (this.viewTypeActive === 'ROW') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_list,
      });
      this.requestDeals(true, false);
    }

    if (this.viewTypeActive !== 'TRACKING') {
      this.resetTrackingObjectComponent();
    }

    this.draw();
  }

  private watchRefreshList() {
    this.sub$.add(
      this.createDealService.refreshList$
        .pipe(filter((res) => res))
        .subscribe(() => {
          this.clear();

          this.searchParams = {
            ...this.searchParams,
            page: 1,
          };

          this.requestDeals(true, false);
        })
    );
  }

  async toDetail(value: Deal.Output | { _id?: string; [key: string]: any }) {
    if (this.viewTypeActive === 'PIPELINE') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_edit,
      });
    } else if (this.viewTypeActive === 'ROW') {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_list_edit,
      });
    }
    this.createDealService
      .open({
        data: {
          mode: 'EDIT',
          idDeal: value._id,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
    // this.ngZone.run(() => {
    //   this.router.navigateByUrl(`/admin/deals/${value._id}`);
    // });
  }

  async toNewTab(value) {
    this.createDealService
      .open({
        data: {
          mode: 'EDIT',
          idDeal: value._id,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
  }

  private requestOwnTeam() {
    this.userService
      .findAllByCompany(StorageService.CompanyId)
      .subscribe((res) => {
        this.ownTeam = res;
      });
  }

  async finishDeal(deal: Deal.Schema) {
    this.isLoading = true;
    this.dealsServices.finishedMany([deal._id]).subscribe(() => {
      const name = deal.name ? deal.name : deal.title;
      this.toastService.show({
        text: this.i18n.instant('deals.finishDeal') + `<b>"${name}"</b>`,
        type: 'success',
        msDuration: 4000,
      });
      if (this.viewTypeActive === 'PIPELINE') {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_board_finalize,
        });
      } else if (this.viewTypeActive === 'ROW') {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_list_options_finalize,
        });
      }
      this.requestDeals(true, false);
    });
  }

  async cancelDealNoModal(payload: string[]) {
    this.dealsServices.cancelMany(payload).subscribe(async () => {
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: BrazeEventType.deal_canceled,
        eventProperties: {
          canceledIds: payload,
        },
      });

      if (!Array.isArray(payload)) {
        await this.removeDeals([payload]);
      } else {
        await this.removeDeals(payload);
      }
    });

    // this.sub$.add(
    //   this.dealsServices
    //     .delete(deal._id, {action: '', reason: ''})
    //     .subscribe(() => {
    //       this.removeDeal(deal);
    //       this.amplitudeService.sendEvent({
    //         event: 'service_tablero_select_cancel'
    //       })
    //     })
    // );
  }

  async restoreFinishDeal(deal: Deal.Schema) {
    this.isLoading = true;
    this.dealsServices.restoreFinished([deal._id]).subscribe(() => {
      const name = typeof deal.title !== 'undefined' ? deal.title : deal.name;
      this.toastService.show({
        text: this.i18n.instant('deals.restorehDeal') + `<b>"${name}"</b>`,
        type: 'success',
        msDuration: 4000,
      });
      this.requestDeals(true, false);
    });
  }

  async changeStateDeal(deal) {
    this.isLoading = true;
    this.dealsServices.updateStatus(deal.item._id, deal.stat).subscribe(() => {
      const name =
        typeof deal.item.title !== 'undefined'
          ? deal.item.title
          : deal.item.name;
      this.toastService.show({
        text: this.i18n.instant('deals.changeStateDeal') + `<b>"${name}"</b>`,
        type: 'success',
        msDuration: 4000,
      });
      this.requestDeals(true, false);
      if (this.viewTypeActive === 'PIPELINE') {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_board_options_move,
        });
      } else if (this.viewTypeActive === 'ROW') {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_list_options_move,
        });
      }
    });
  }

  async removeDeals(ids: string[]) {
    ids.forEach((id) => {
      this.dealsMap.delete(id);
    });
    this.source = Array.from(this.dealsMap.values());
    this.toastService.show({
      text:
        ids.length > 1
          ? this.i18n.instant('deals.deleteMany').replace('{qty}', ids.length)
          : this.i18n.instant('deals.delete'),
      type: 'error',
      msDuration: 4000,
    });
    this.requestDeals(true);
  }

  async removeDeal(value: Deal.Output) {
    this.dealsMap.delete(value._id);
    this.source = Array.from(this.dealsMap.values());
    this.toastService.show({
      text: this.i18n.instant('deals.delete'),
      type: 'error',
      msDuration: 4000,
    });
    this.requestDeals(true);
  }

  async approveRejectDeal(value: Deal.Output) {
    this.requestDeals(true, false);
    if (value?.status?.id == STATUS_REQUESTED_BUDGET.id) {
      this.alertService.success(this.i18n.instant('deals.deal_approved'));
    } else {
      this.alertService.success(this.i18n.instant('deals.deal_rejected'));
    }
  }

  sortChange(evt: TableSortEvent) {
    console.log('SORT EVENT ACTIVE', evt.active);
    evt.active = evt.active === 'final' ? 'final.name' : evt.active;
    evt.active = evt.active === 'vendor' ? 'taskerTeam.name' : evt.active;
    evt.active = evt.active === 'project' ? 'project.name' : evt.active;
    evt.active = evt.active === 'reporter' ? 'reporterObj.name' : evt.active;
    evt.active = evt.active === 'productCategory' ? 'product.name' : evt.active;
    evt.active = evt.active === 'assetObj' ? 'assetObj.name' : evt.active;
    let resCustomProperty = DealsWrapperCustomPropertySort(
      this.customProperties,
      evt.active
    );

    if (!resCustomProperty) {
      resCustomProperty = DealsWrapperPropertySort(evt.active);
    }

    this.clearSortOptions();

    if (evt.direction) {
      this.searchParams = {
        ...this.searchParams,
        [`sortOptions[${
          resCustomProperty ? resCustomProperty : evt.active
        }]`]: evt.direction,
      };
    } else {
      this.searchParams = {
        ...this.searchParams,
        page: 1,
        'sortOptions[dateCreated]': PaginateSort.DESC,
      };
    }

    this.clear();
    this.requestDeals(true, false);
  }

  addClick() {
    this.analyticsService.trackEvent({
      sources: ['amplitude', 'braze'],
      eventName: BrazeEventType.deal_start,
    });

    this.createDealService
      .open({
        data: {
          customer: this.customer,
        },
      })
      .subscribe();
  }

  goToCalendar() {
    this.router.navigateByUrl(`/admin/calendar`);
  }

  nextPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };
    this.requestDeals(true, false);
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_list_pageChangeNext,
    });
  }

  goToPage(page) {
    this.searchParams = {
      ...this.searchParams,
      page: page,
    };

    this.requestDeals(true, false);
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_list_pageChange,
    });
  }

  previousPage() {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page - 1,
    };
    this.requestDeals(true, false);
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_list_pageChangePrevious,
    });
  }

  private setDeals(response: PaginateResponse<Deal.Output[]>) {
    this.table?.clearChecks();
    this.clear();

    if (!response.docs.length) {
      this.showVideoIframe = true;
    }

    this.resultSearch = response;
    let i;
    this.totalPages = [];
    for (i = 1; i <= this.resultSearch.countPages; i++) {
      this.totalPages.push({
        page: i,
      });
    }

    for (const deal of response.docs) {
      //console.log(deal);
      this.dealsMap.set(deal._id, deal);
    }

    this.source = Array.from(this.dealsMap.values());
    this.draw();
  }

  private set source(deals: Deal.Output[]) {
    this.deals = new MatTableDataSource(deals);
    this.draw();
  }

  private clear() {
    this.dealsMap.clear();

    this.source = [];
  }

  private clearSortOptions() {
    const searchParams = {
      ...this.searchParams,
    };

    for (const prop in searchParams) {
      if (searchParams[prop]) {
        if (prop.match(/sortOptions/)) {
          delete searchParams[prop];
        }
      }
    }

    this.searchParams = searchParams;
  }

  private async getVendorName(
    item: Deal.Output
    // @ts-ignore
  ): Promise<{ companyName: string; taskerName: string }> {
    if (item?.type === DealType.Team) {
      const user = this.ownTeam.find((a) => a._id === item?.taskers[0]);

      return { taskerName: user?.name, companyName: null };
    } else if (item?.type === DealType.Vendor) {
      const internalVendor = this.internalVendors.find(
        (vendor) =>
          vendor?._id === item?.taskers[0] ||
          vendor?.linkedUser === item?.taskers[0]
      );

      if (internalVendor?.linkedUser) {
        const user = await this.userService
          .findById(internalVendor?.linkedUser)
          .toPromise();

        if (user) {
          return {
            companyName: user?.company?.name,
            taskerName: user?.name,
          };
        }
      }

      return {
        taskerName: internalVendor?.name,
        companyName: null,
      };
    } else if (item?.type === DealType.OpenMarket) {
      return {
        companyName: '',
        taskerName: '',
      };
    }
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  getColumnsAdmin(lang) {
    if (lang === 'es') {
      return [
        'Ref',
        'Nombre',
        'Fecha Inicio',
        'Fecha Creación',
        'Estado',
        'Horarios',
        'Cliente',
        'Asignado a',
        'Dirección',
        'Proyecto',
        'Presupuesto',
        'Factura',
      ];
    } else {
      return [
        'Ref',
        'Name',
        'Date Start',
        'Date Created',
        'Status',
        'Times',
        'Customer',
        'Asigned to',
        'Address',
        'Project',
        'Budget',
        'Bill',
      ];
    }
  }

  getColumnsUser(lang) {
    if (lang === 'es') {
      return [
        'Ref',
        'Nombre',
        'Fecha Inicio',
        'Fecha Creación',
        'Estado',
        'Horarios',
        'Cliente',
        'Asignado a',
        'Dirección',
        'Proyecto',
      ];
    } else {
      return [
        'Ref',
        'Name',
        'Date Start',
        'Date Created',
        'Status',
        'Times',
        'Customer',
        'Asigned to',
        'Address',
        'Project',
      ];
    }
  }

  async downloadExcel() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_excelDownload,
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Deals');
    let header = [
      this.i18n.instant('general.ref'),
      this.i18n.instant('general.name'),
      this.i18n.instant('general.dateStart'),
      this.i18n.instant('general.dateCreated'),
      this.i18n.instant('general.status'),
      this.i18n.instant('general.times'),
      this.i18n.instant('general.customer'),
      this.i18n.instant('general.assignedTo'),
      this.i18n.instant('general.address'),
      this.i18n.instant('general.project'),
      this.i18n.instant('general.budget'),
      this.i18n.instant('general.bill'),
    ];

    if (this.user.role.includes(TypeRol.USER_ROLE)) {
      header = [
        this.i18n.instant('general.ref'),
        this.i18n.instant('general.name'),
        this.i18n.instant('general.dateStart'),
        this.i18n.instant('general.dateCreated'),
        this.i18n.instant('general.status'),
        this.i18n.instant('general.times'),
        this.i18n.instant('general.customer'),
        this.i18n.instant('general.assignedTo'),
        this.i18n.instant('general.address'),
        this.i18n.instant('general.project'),
      ];
    }

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(take(1))
        .subscribe((res) => {
          if (res.length) {
            header = Company.FilterColumnReports({
              columnsReports: header,
              nameColumnsReport: [
                this.i18n.instant('general.customer'),
                this.i18n.instant('general.project'),
                this.i18n.instant('general.budget'),
                this.i18n.instant('general.bill'),
                this.i18n.instant('general.address'),
              ],
              idModules: [
                Company.IdModule.Finals,
                Company.IdModule.Projects,
                Company.IdModule.Sales,
                Company.IdModule.Sales,
                Company.IdModule.Assets,
              ],
              idActivesModules: res,
            });

            const customShow = JSON.parse(
              StorageService.CustomProperty('DEAL_MODULE')
            ) as ICustomProperty[];

            if (customShow?.length > 0) {
              for (const param of customShow) {
                header.push(param.name);
              }
            }

            const headerRow = worksheet.addRow(header);
            headerRow.font = {
              color: { argb: '003c48ec' },
              bold: true,
              size: 12,
            };
            headerRow.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };

            const query: any = RemoveEmpty(this.searchParams);
            //evitamos busqueda si solo esta el from, necesitamos también el to
            if (query.from && !query.to) {
              delete query.from;
            }
            this.sub$.add(
              this.dealSearchService
                .search({
                  ...query,
                  limit: 999999999,
                })
                .subscribe((res2) => {
                  for (const x1 of res2.docs) {
                    const temp = [];
                    temp.push(x1._id);
                    temp.push(x1.name);

                    const isEmptyHour = (date: Date) => {
                      if (!date) {
                        return true;
                      }

                      return (
                        typeof date === 'object' &&
                        !date.getHours() &&
                        !date.getMinutes() &&
                        !date.getSeconds()
                      );
                    };

                    const initDate = x1?.dates?.initTimestamp
                      ? `${timestampToDate(
                          x1?.dates?.initTimestamp
                        )} ${timestampToHour(x1?.dates?.initTimestamp, true)}`
                      : '';
                    temp.push(initDate);
                    temp.push(
                      this.datePipe.transform(
                        x1.dateCreated,
                        this.company_date_format
                      )
                    );
                    temp.push(x1.status?.name);
                    temp.push(x1.times ? x1.times.join(', ') : 'No Disponible');

                    if (res.includes(Company.IdModule.Finals)) {
                      temp.push(
                        x1.final
                          ? x1.final.name +
                              (x1.final.lastName ? ' ' + x1.final.lastName : '')
                          : 'No Disponible'
                      );
                    }
                    temp.push(this.getVendorByType(x1));
                    if (res.includes(Company.IdModule.Assets)) {
                      temp.push(
                        x1.assetObj ? x1.assetObj.name : 'No Disponible'
                      );
                    }
                    if (res.includes(Company.IdModule.Projects)) {
                      temp.push(x1.project ? x1.project.name : 'No Disponible');
                    }

                    if (res.includes(Company.IdModule.Sales)) {
                      if (this.user.role.includes(this.roles.ADMIN_ROLE)) {
                        temp.push(
                          x1?.budget
                            ? '#' +
                                x1?.budget?.header.numberDoc +
                                ' / ' +
                                this.getFormatedCustomerNumber(
                                  x1?.budget?.total
                                ) +
                                this.getCurrencySimbol()
                            : 'No Disponible'
                        );
                        temp.push(
                          x1?.bill
                            ? '#' +
                                x1?.bill?.header.numberDoc +
                                ' / ' +
                                this.getFormatedCustomerNumber(
                                  x1?.bill?.total
                                ) +
                                this.getCurrencySimbol()
                            : 'No Disponible'
                        );
                      }
                    }

                    if (customShow?.length > 0) {
                      for (const param of customShow) {
                        temp.push(getCustomValue(x1, param.name));
                      }
                    }

                    const row = worksheet.addRow(temp);
                    row.alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                  }

                  worksheet.columns.forEach(function (column) {
                    column.width = 27;
                  });

                  //add data and file name and download
                  workbook.xlsx.writeBuffer().then(async (data) => {
                    const blob = new Blob([data], {
                      type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    this.fileWrite
                      .writeFile({
                        path: `deals-${this.datePipe.transform(
                          new Date(),
                          'dd-MM-yyyy'
                        )}.xlsx`,
                        blob,
                      })
                      .then(() => {
                        // this.alertService.success(
                        //   this.i18n.instant('general.savedFile')
                        // );
                        this.downloadBtnService.downloadedSuccessfully();
                      });
                  });
                })
            );
          }
        })
    );
  }

  // @ts-ignore
  getVendorByType(item: Deal.Output) {
    let resp: IInternalVendor | IOwnTeam;
    if (item?.type === DealType.Team) {
      const taskersElements = item?.taskerTeam ? item?.taskerTeam : [];
      const names = taskersElements.map((el) => el.name);
      return names.length ? names[0] : '';
    }
  }

  openVideo(type: string) {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_play_tutorial,
    });
    this.video.open(type);
  }

  setMarkersDeals(markers) {
    //console.log('markers deal = ',markers)
    this.markersDeals = [...markers];
  }

  setMarkersUsers(markers) {
    this.markersUsers = [...markers];
  }

  setUsersSelected(users) {
    this.usersSelected = [...users];
  }

  setDateTracking(mydate) {
    this.trackingDate = mydate;
  }

  setPolyLines(lines) {
    this.linesMap = lines;
  }

  private resetTrackingObjectComponent() {
    this.usersSelected = [];
    this.linesMap = [];
    this.markersDeals = [];
    this.markersUsers = [];
    this.draw();
  }

  toggleViewType(open: boolean) {
    this.openMobileViewType = open;
    this.draw();
  }

  getFormatedCustomerNumber(num: number) {
    if (!this.settings) {
      this.settings = this.defaultPreferences;
    }
    const number_format = numbers_format.find(
      (item) => item.id === this.settings.number_format
    ).decimal_separator;
    const decimals = this.settings?.decimals.toString();
    let locale = '';

    if (number_format === ',') {
      locale = 'es-AR';
    } else {
      locale = 'en-US';
    }

    const digitsInfo = '1.' + decimals + '-' + decimals;

    return formatNumber(num, locale, digitsInfo);
  }

  getCurrencySimbol() {
    const format = this.currencys.find(
      (format) => format.id === this.settings?.currency
    );

    return format ? format.value : this.DEFAULT_CURRENCY_SYMBOL;
  }

  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  deleteDeals(evt: Deal.Output[]): void {
    const ids = evt.map((entry) => entry._id);
    this.sub$.add(
      this.dealsServices
        .logicalDelete({
          ids: ids,
        })
        .pipe(
          finalize(() => {
            this.deleteMessage = false;
            this.entriesForDelete = [];
          })
        )
        .subscribe(() => {
          this.toastService.show({
            text: 'general.deleteManyCorrect',
            type: 'error',
            msDuration: 4000,
            icon: 'assets/icons/kanban/delete.svg',
          });
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_list_delete_delete,
          });
          this.requestDeals(true, false);
        })
    );
  }

  deleteMany(evt: Deal.Output[]) {
    this.entriesForDelete = evt;
    if (evt.length > 0) {
      this.deleteMessage = true;
    } else {
      this.deleteMessage = false;
    }
    if (evt.length > 0 && evt.length === this.deals.data.length) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_list_bulk,
      });
    } else if (evt.length > 0) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_list_select,
      });
    }
  }

  async downloadExcelSelection() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_excelDownload,
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Deals');
    let header = [
      this.i18n.instant('general.ref'),
      this.i18n.instant('general.name'),
      this.i18n.instant('general.dateStart'),
      this.i18n.instant('general.dateCreated'),
      this.i18n.instant('general.status'),
      this.i18n.instant('general.times'),
      this.i18n.instant('general.customer'),
      this.i18n.instant('general.assignedTo'),
      this.i18n.instant('general.address'),
      this.i18n.instant('general.project'),
      this.i18n.instant('general.budget'),
      this.i18n.instant('general.bill'),
    ];

    if (this.user.role.includes(TypeRol.USER_ROLE)) {
      header = [
        this.i18n.instant('general.ref'),
        this.i18n.instant('general.name'),
        this.i18n.instant('general.dateStart'),
        this.i18n.instant('general.dateCreated'),
        this.i18n.instant('general.status'),
        this.i18n.instant('general.times'),
        this.i18n.instant('general.customer'),
        this.i18n.instant('general.assignedTo'),
        this.i18n.instant('general.address'),
        this.i18n.instant('general.project'),
      ];
    }

    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(take(1))
        .subscribe((res) => {
          if (res.length) {
            header = Company.FilterColumnReports({
              columnsReports: header,
              nameColumnsReport: [
                this.i18n.instant('general.customer'),
                this.i18n.instant('general.project'),
                this.i18n.instant('general.budget'),
                this.i18n.instant('general.bill'),
                this.i18n.instant('general.address'),
              ],
              idModules: [
                Company.IdModule.Finals,
                Company.IdModule.Projects,
                Company.IdModule.Sales,
                Company.IdModule.Sales,
                Company.IdModule.Assets,
              ],
              idActivesModules: res,
            });

            const customShow = JSON.parse(
              StorageService.CustomProperty('DEAL_MODULE')
            ) as ICustomProperty[];

            if (customShow?.length > 0) {
              for (const param of customShow) {
                header.push(param.name);
              }
            }

            const headerRow = worksheet.addRow(header);
            headerRow.font = {
              color: { argb: '003c48ec' },
              bold: true,
              size: 12,
            };
            headerRow.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };

            const query: any = RemoveEmpty(this.searchParams);
            //evitamos busqueda si solo esta el from, necesitamos también el to
            if (query.from && !query.to) {
              delete query.from;
            }

            for (const x1 of this.entriesForDelete) {
              const temp = [];
              temp.push(x1._id);
              temp.push(x1.name);

              const initDate = x1?.dates?.initTimestamp
                ? `${timestampToDate(
                    x1?.dates?.initTimestamp
                  )} ${timestampToHour(x1?.dates?.initTimestamp, true)}`
                : '';
              temp.push(initDate);
              temp.push(
                this.datePipe.transform(
                  x1.dateCreated,
                  this.company_date_format
                )
              );
              temp.push(x1.status?.name);
              temp.push(x1.times ? x1.times.join(', ') : 'No Disponible');

              if (res.includes(Company.IdModule.Finals)) {
                temp.push(
                  x1.final
                    ? x1.final.name +
                        (x1.final.lastName ? ' ' + x1.final.lastName : '')
                    : 'No Disponible'
                );
              }
              temp.push(this.getVendorByType(x1));
              if (res.includes(Company.IdModule.Assets)) {
                temp.push(x1.assetObj ? x1.assetObj.name : 'No Disponible');
              }
              if (res.includes(Company.IdModule.Projects)) {
                temp.push(x1.project ? x1.project.name : 'No Disponible');
              }

              if (res.includes(Company.IdModule.Sales)) {
                if (this.user.role.includes(this.roles.ADMIN_ROLE)) {
                  temp.push(
                    x1?.budget
                      ? '#' +
                          x1?.budget?.header.numberDoc +
                          ' / ' +
                          this.getFormatedCustomerNumber(x1?.budget?.total) +
                          this.getCurrencySimbol()
                      : 'No Disponible'
                  );
                  temp.push(
                    x1?.bill
                      ? '#' +
                          x1?.bill?.header.numberDoc +
                          ' / ' +
                          this.getFormatedCustomerNumber(x1?.bill?.total) +
                          this.getCurrencySimbol()
                      : 'No Disponible'
                  );
                }
              }

              if (customShow?.length > 0) {
                for (const param of customShow) {
                  temp.push(getCustomValue(x1, param.name));
                }
              }

              const row = worksheet.addRow(temp);
              row.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }

            worksheet.columns.forEach(function (column) {
              column.width = 27;
            });

            //add data and file name and download
            workbook.xlsx.writeBuffer().then(async (data) => {
              const blob = new Blob([data], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              this.fileWrite
                .writeFile({
                  path: `deals-${this.datePipe.transform(
                    new Date(),
                    'dd-MM-yyyy'
                  )}.xlsx`,
                  blob,
                })
                .then(() => {
                  // this.alertService.success(
                  //   this.i18n.instant('general.savedFile')
                  // );
                  this.downloadBtnService.downloadedSuccessfully();
                });
            });
          }
        })
    );
  }

  openDeleteManyDialog(dealDelete: Deal.Output): void {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_list_delete_start,
    });

    const toDelete = dealDelete ? [dealDelete] : this.entriesForDelete;

    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        isPlural: toDelete.length > 1,
        body: this.i18n.instant('deleteModal.body_final'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.deleteDeals(toDelete);
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_list_delete_delete,
        });
      }
    });
  }

  showDialog(): void {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_tooltip_start,
    });
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      const dialog = this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'deals.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }

  sendEventAcademy() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_tooltip_academy,
    });
  }

  calcTableDistance() {
    setTimeout(() => {
      const containerTable = document.getElementById('containerTable');
      this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
    }, 1000);
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  unselectItems() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.table ? this.table.clearChecks() : this.mobileList.clearChecks();
  }

  changeFilterKeyword(evt: RmFilter.Changes) {
    if (evt.type === 'CHANGE' || evt.type === 'INPUT_CHANGE') {
      const { queryParams } = evt;
      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'INPUT_CHANGE'
      ) {
        delete this.searchParams.keyword;
      }

      this.searchParams = {
        ...this.searchParams,
        ...queryParams,
      };

      this.requestDeals(true, false);
    }
  }

  clearFilter(evt: RmFilter.Changes) {
    if (evt.type === 'CHANGE' || evt.type === 'INPUT_CHANGE') {
      const { queryParams } = evt;

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'CHANGE'
      ) {
        this.searchParams = { ...INIT_DEAL_SEARCH_PARAMS() };
      }

      this.requestDeals(true, false);
    }
  }

  changeFilters(evt: RmFilter.Changes) {
    if (evt.type === 'CHANGE' || evt.type === 'INPUT_CHANGE') {
      const { queryParams } = evt;

      if (queryParams?.from) {
        queryParams.from = dateAndHourToTimestamp(
          String(queryParams.from),
          '00:00:00'
        );
        queryParams.to = dateAndHourToTimestamp(
          String(queryParams.to),
          '23:59:59'
        );
      }

      this.searchParams = {
        ...this.searchParams,
        ...queryParams,
      };

      if (queryParams?.customProperties) {
        this.searchParams = {
          ...this.searchParams,
          customProperties: JSON.stringify(queryParams.customProperties),
        };
      }

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'CHANGE'
      ) {
        this.searchParams = { ...INIT_DEAL_SEARCH_PARAMS() };
      }

      this.requestDeals(true, false);
    }
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_filter_apply,
    });
  }

  changeQuickFilters(evt: RmFilter.Changes) {
    const { queryParams } = evt;
    const { takeAll, ...params } = queryParams;

    this.searchParams = {
      ...this.searchParams,
      takeFinished: 'false',
      takeCancelled: false,
      takeToday: 'false',
      'taskers[]': [],
      ...params,
    };

    if (queryParams?.takeAll) {
      this.searchParams = {
        ...this.searchParams,
        'taskers[]': [this.user._id],
      };
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_filter_mine,
      });
    }
    if (queryParams?.takeToday) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_filter_today,
      });
    }
    if (queryParams?.takeFinished) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_filter_finished,
      });
    }
    if (queryParams?.takeCancelled) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_filter_cancelled,
      });
    }

    //Console to debug queryParams on filter failure
    //console.log(queryParams, this.searchParams);

    this.requestDeals(true, false);
  }

  getModuleResources() {
    this.sub$.add(
      this.tutorialService
        .get('deals')
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.academyLink = res?.academy;
          this.videoIframeURL = res.source;
        })
    );
  }

  /************************** batch actions  ********************/

  restore(idsList?) {
    if (idsList) {
      this.isLoading = true;
      this.entriesForDelete.push(idsList);
    }
    const ids = this.entriesForDelete.map((el) => el?._id);
    this.sub$.add(
      this.dealsServices.restoreMany({ ids: ids }).subscribe(() => {
        this.toastService.show({
          text: this.i18n
            .instant('general.restoreMany')
            .replace('{qtty}', ids.length),
          type: 'success',
          msDuration: 4000,
        });

        this.entriesForDelete = [];
        this.deleteMessage = false;
        this.requestDeals(true, false);
        this.draw();
      })
    );
  }

  /**************************************** KANBAN FUNCTIONS ************************************************ */

  typeOfStatus = 'deal';
  cardStatus: IStatus[] = [];
  kanbanCards: KanbanCard[] = [];
  statusNames: { _id?: string; name: string; color?: string }[] = null;

  private requestStatus() {
    const id = StorageService.CompanyId;
    this.sub$.add(
      this.statusService
        .findByUser({ id: id, type: this.typeOfStatus })
        .pipe(map((res) => [...res]))
        .subscribe((res) => {
          //console.log('status service: ', res);
          this.cardStatus = res;
          this.statusNames = this.cardStatus.map((x) => ({
            _id: x._id,
            name: x.name,
            color: x?.color ? x.color : DealStatusColors[0].lightColor,
          }));
          this.statusToDealPipeline = res;
          this.status = ConvertToKeyValue(res);
          this.draw();
        })
    );
  }

  private setCards(response: PaginateResponse<Deal.Output[]>) {
    this.kanbanCards = response.docs?.map((document) => {
      const time = document?.dates?.initTimestamp;

      return {
        _id: document._id,
        selected: false,
        title: document?.name ? this.i18n.instant(document.name) : '',
        finished: document.isFinished ? true : false,
        deleted: document.cancelled,
        cancelled: document.cancelled,
        date: time ? String(time) : null,
        hour: time ? String(time) : null,
        column: document.status?.name,
        responsable: document.reporterObj?.name,
        assignedTo: document.taskerTeam.map((it) => ({
          name: it.name,
          img: it.img,
        })),
        comments: [],
        onCreate: false,
        applyRedStatusInExpireDate: false,
        final: document.final?.name ?? document.final?.fiscalName ?? '',
        isAllDay: document.isAllDay,
      };
    });
  }

  eventCreateFirstKanban() {
    this.sub$.add(
      this.statusService
        .findByUser({
          id: StorageService.CompanyId,
          type: this.typeOfStatus,
        })
        .pipe(map((res) => [...res]))
        .subscribe((res) => {
          const status = res?.map((x) => x.name);
          if (
            status.includes(this.i18n.instant('kanban.column1')) ||
            status.includes(this.i18n.instant('kanban.column2'))
          ) {
            return;
          }

          this.sub$.add(
            this.statusService
              .create({
                name: this.i18n.instant('kanban.column1'),
                type: this.typeOfStatus,
              })
              .subscribe((res) => {
                this.sub$.add(
                  this.statusService
                    .create({
                      name: this.i18n.instant('kanban.column2'),
                      type: this.typeOfStatus,
                    })
                    .subscribe((res) => this.requestStatus())
                );
              })
          );
        })
    );
  }

  eventOnCardClick($event) {
    this.toDetail($event);
  }

  eventCardDblClick($event) {}

  eventChangeColor($event: { darkColor: string; lightColor: string }) {
    this.selectedColor = $event;
  }

  eventAddColumn($event: string) {
    this.sub$.add(
      this.statusService
        .create({
          name: $event,
          type: 'deal',
          color: this.selectedColor?.lightColor,
        })
        .subscribe((res) => {
          this.requestStatus();
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_board_newStatus_save,
          });
        })
    );
  }

  eventEditColumn($event: { _id: string; name: string; color: string }) {
    this.checkColumnChanges($event);
    this.sub$.add(
      this.statusService
        .updateOne({ id: $event._id, name: $event.name, color: $event.color })
        .subscribe((res) => this.requestStatus())
    );
  }

  checkColumnChanges($event: { _id: string; name: string; color: string }) {
    const column = this.cardStatus.find((x) => x._id === $event._id);
    if (column) {
      if (column.name !== $event.name) {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_board_header_edit_save,
        });
      }
      if (column.color !== $event.color) {
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_board_header_rename_color,
        });
      }
    }
  }

  eventDeleteColumn($event) {
    if ($event.cards?.length > 0) {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_header_delete_start,
      });
      const parms = { name: '' };
      const modalRef = this.dialog.open(DealCancelComponent);
      (modalRef.componentInstance as DealCancelComponent).data = parms as IDeal;
      (modalRef.componentInstance as DealCancelComponent).deleteOnSubmit = false;
      (modalRef.componentInstance as DealCancelComponent).deleteMultiple = true;

      modalRef.afterClosed().subscribe((res) => {
        if (res) {
          this.requestDeals(true, false);
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_board_header_delete_delete,
          });
        }
      });

      modalRef.afterClosed().subscribe((result) => {
        if (result) {
          this.sub$.add(
            this.statusService.removeOne({ id: $event.column._id }).subscribe(
              (res) => this.requestStatus(),
              (error) => {}
            )
          );
          this.sub$.add(
            this.dealsServices
              .updateMany(
                $event.cards.map((x) => x._id),
                {
                  cancelled: true,
                  cancellationReason: {
                    company_id: StorageService.CompanyId,
                    reason: result.cancel_reason,
                  },
                }
              )
              .subscribe(() => {
                this.requestDeals(true, false);
              })
          );
        }
      });
    } else {
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_board_header_delete_delete,
      });
      this.sub$.add(
        this.statusService.removeOne({ id: $event.column._id }).subscribe(
          (res) => this.requestStatus(),
          (error) => {}
        )
      );
    }
  }

  eventCardColumnChange($event: {
    previousColumn?: { _id?: string; name: string };
    column?: { _id?: string; name: string };
    card: KanbanCard;
  }) {
    this.sub$.add(
      this.dealsServices
        .updateOne($event.card._id, {
          status: $event.column ? $event.column : null,
        })
        .subscribe(() => {})
    );
  }

  eventRestoreCard($event) {
    this.sub$.add(
      this.dealsServices.restoreMany({ ids: [$event._id] }).subscribe((res) => {
        this.requestDeals(true, false);
      })
    );
  }

  finishToastParams: ToastData = {
    text: 'deals.finishDealMessage',
    type: 'success',
    msDuration: 4000,
  };

  unfinishToastParams: ToastData = {
    text: 'deals.unfinishDealMessage',
    type: 'success',
    link: 'deals.openDealLink',
    msDuration: 4000,
  };

  restoreToastParams: ToastData = {
    text: 'deals.restoreDealMessage',
    type: 'success',
    link: 'deals.openDealLink',
    msDuration: 4000,
  };

  eventEditCard($event: KanbanCard) {
    this.toDetail($event);
  }

  deleteToastParams: ToastData = {
    text: 'deals.deleteDealMessage',
    type: 'error',
    msDuration: 4000,
    icon: 'assets/icons/kanban/delete.svg',
  };
  eventDeleteCard($event) {
    this.sub$.add(
      this.dealsServices
        .updateMany([$event._id], { cancelled: true })
        .subscribe(() => {
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_board_delete_delete,
          });
        })
    );
  }

  eventCancelCard($event) {
    if (Array.isArray($event)) {
      this.isCancelMultiple = true;
      this.sub$.add(
        this.dealsServices
          .cancelMany($event.map((x) => x._id))
          .subscribe(() => {
            this.isCancelMultiple = false;
            this.requestDeals(true, false);
          })
      );
    } else {
      const parms = { _id: $event._id, name: '' };
      const modalRef = this.dialog.open(DealCancelComponent);
      (modalRef.componentInstance as DealCancelComponent).data = parms as IDeal;
      modalRef.afterClosed().subscribe((res) => {
        if (res) {
          this.requestDeals(true, false);
        }
      });
    }
  }

  eventMoveAllCards($event) {
    const status = this.cardStatus.find(
      (status) => status._id === $event?.column?._id
    );

    if (status) {
      this.sub$.add(
        this.dealsServices
          .updateMany(
            $event.cards.map((x) => x._id),
            {
              status: $event.column?._id ? status : null,
            }
          )
          .subscribe(() => {
            this.requestDeals(true, false);
            this.amplitudeService.sendEvent({
              event: AmplitudeEvents.deal_board_header_moveAll,
            });
          })
      );
    }
  }

  eventAddCard($event: { column; card: KanbanCard }) {
    let status = null;
    if ($event.column?._id) {
      status = this.statusToDealPipeline.find(
        (el) => el._id === $event.column?._id
      );
    }

    const newDeal: HiringRegisterDto = {
      dealDetailInfo: {
        project_id: null,
        title: $event.card.title,
        product_id: null,
        description: '',
        imgs: null,
        files: null,
        quantity: null,
        urgency: false,
        type: DealType.Team,
        evidences: [],
        notes: [],
        isDraw: null,
        vertical_id: null,
        idEquipments: null,
        idFinal: this.customer?._id || null,
        idProject: null,
        idGroup: null,
        idInternalVendor: null,
        name: $event.card.title,
        jobDescription: null,
        stage: null,
        status: status,
        dates: { initTimestamp: null, endTimestamp: null },
      },
      dealTemporalInfo: {
        times: [],
        timeStatus: null,
        offsetTimeStatus: null,
        visitation: null,
        visitationDate: null,
      },
      dealLocationInfo: {
        city: null,
        address: '',
        address2: '',
        latitude: '',
        longitude: '',
      },
      dealContactInfo: {
        contactName: '',
        contactPhone: '',
        createdBy: StorageService.UserId,
      },
      dealProfileInfo: {
        vendor: '',
        taskers: [],
        reporter: null,
        members: [],
        owner: StorageService.UserId,
        customer: StorageService.CustomerId,
        asset: null,
        billingProfile: null,
      },
      customProperties: [],
    };
    this.sub$.add(
      this.hiringService.createOne(newDeal).subscribe(() => {
        this.requestDeals(true, false);
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.deal_board_header_create,
        });
      })
    );
  }

  eventMoveSelectedCards($event) {
    this.sub$.add(
      this.dealsServices
        .updateMany(
          $event.cards.map((x) => x._id),
          { status: $event.column?._id ? $event.column : null }
        )
        .subscribe(() => {
          this.requestDeals(true, false);
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_board_select_move,
          });
        })
    );
  }

  eventFinishSelectedCards($event) {
    this.sub$.add(
      this.dealsServices
        .finishedMany($event.map((x) => x._id))
        .subscribe(() => {
          this.requestDeals(true);
          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_board_select_finalize,
          });
        })
    );
  }
  eventUnfinishSelectedCards($event) {
    this.sub$.add(
      this.dealsServices
        .restoreFinished($event.map((x) => x._id))
        .subscribe(() => {
          this.requestDeals(true);
        })
    );
  }
  evenRestoreSelectedCards($event) {
    const ids = $event.map((el) => el?._id);

    this.sub$.add(
      this.dealsServices.restoreMany({ ids: ids }).subscribe(() => {
        this.requestDeals(true, false);
      })
    );
  }

  eventFinishCard($event) {
    //TODO
  }
  eventDeleteSelectedCards($event) {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_board_select_delete_delete,
    });
    this.deleteDeals($event);
  }

  eventUnfinishCard($event) {
    //TODO
  }

  private resolveFirstDealService() {
    this.authService.user$.pipe(take(1)).subscribe((res) => {
      this.hasSeeServiceKanbanByFirst = Boolean(
        res?.company?.hasSeeServiceKanbanByFirst
      );
      this.draw();
    });
  }

  /**************************************** MULTIPLE LIST FUNCTIONS ************************************************ */

  markMultipleAsFinished() {
    if (!this.entriesForDelete?.length) return;

    this.isFinishedMultiple = true;

    this.dealsServices
      .finishedMany(this.entriesForDelete.map((i) => i._id))
      .pipe(
        finalize(() => {
          this.isFinishedMultiple = false;
          this.draw();
          this.unselectItems();
        })
      )
      .subscribe(() => {
        this.toastService.show({
          text: this.i18n
            .instant('deals.finishedMany')
            .replace('{qty}', this.entriesForDelete?.length),
          type: 'success',
          msDuration: 4000,
        });

        this.entriesForDelete = [];
        this.draw();

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_list_select_finalize,
        });

        this.requestDeals(true);
      });
  }

  moveMultipleToAnotherStatus_TABLE(status) {
    if (!this.entriesForDelete?.length) return;

    this.isMovingMultiple = true;

    const nextStatus = this.statusToDealPipeline.find(
      (stat) => stat.name === status.key
    );
    this.sub$.add(
      this.dealsServices
        .updateStatusMany(
          this.entriesForDelete.map((i) => i._id),
          nextStatus
        )
        .pipe(
          finalize(() => {
            this.unselectItems();
            this.isMovingMultiple = false;
          })
        )
        .subscribe(() => {
          this.requestDeals(true);
          let toastText = '';

          if (this.entriesForDelete?.length > 1) {
            toastText = this.i18n
              .instant('kanban.toast.moveMany')
              .replace('{qtty}', this.entriesForDelete?.length)
              .replace('{column}', status.key)
              .replace(
                '{arg1}',
                this.i18n.instant('kanban.toast.pluralTask').toLowerCase()
              )
              .replace('{column}', status.key);
          } else {
            toastText = this.i18n
              .instant('kanban.toast.moveOne')
              .replace('{column}', status.key)
              .replace(
                '{arg1}',
                this.i18n.instant('kanban.toast.singularTask').toLowerCase()
              )
              .replace('{column}', status.name);
          }

          this.amplitudeService.sendEvent({
            event: AmplitudeEvents.deal_list_select_move,
          });

          this.toastService.show({
            text: toastText,
            type: 'success',
            msDuration: 4000,
          });
        })
    );
  }

  markMultipleAsCancel() {
    if (!this.entriesForDelete?.length) return;

    this.isCancelMultiple = true;

    const ids = this.entriesForDelete.map((i) => i._id);

    this.dealsServices
      .cancelMany(ids)
      .pipe(
        finalize(() => {
          this.isCancelMultiple = false;
          this.draw();
          this.unselectItems();
        })
      )
      .subscribe(() => {
        this.toastService.show({
          text: this.i18n
            .instant('deals.cancelMany')
            .replace('{qty}', this.entriesForDelete?.length),
          type: 'success',
          msDuration: 4000,
        });

        this.entriesForDelete = [];
        this.draw();

        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_list_select_cancel,
        });
        this.brazeService.sendEvent({
          event: BrazeEventType.deal_canceled,
          eventProperties: {
            canceledIds: ids,
          },
        });

        this.requestDeals(true);
      });
  }

  markMultipleAsUnfinished() {
    if (!this.entriesForDelete?.length) return;

    this.isUnFinishedMultiple = true;

    this.dealsServices
      .restoreFinished(this.entriesForDelete.map((i) => i._id))
      .pipe(
        finalize(() => {
          this.isUnFinishedMultiple = false;
          this.draw();
          this.unselectItems();
        })
      )
      .subscribe(() => {
        this.toastService.show({
          text: this.i18n.instant('deals.unfinishedMany'),
          type: 'success',
          msDuration: 4000,
        });

        this.entriesForDelete = [];
        this.draw();

        this.requestDeals(true);
      });
  }

  /*************** Toast Functions ****************** */

  eventUnfinishToastLinkClick($event) {
    //TODO
  }
  eventRestoreToastLinkClick($event) {
    //TODO
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }

  download(type: DownloadType) {
    switch (type) {
      case DownloadType.EXCEL:
        if (this.entriesForDelete?.length) {
          this.downloadExcelSelection();
        } else {
          this.downloadExcel();
        }
        break;
      case DownloadType.PDF:
        break;
      default:
        break;
    }
  }
}
