<div class="full-height">
  <div class="login-form">
    <img class="logo" src="/assets/img/logo-taclia.svg" alt="taclia" />
    <h2>{{ 'login.recover' | translate }}</h2>
    <p class="sub-title">{{ 'login.recovertitle1' | translate }}</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-30">
        <label class="form-label">{{ 'login.email' | translate }}</label>
        <input
          type="email"
          class="form-control"
          [placeholder]="'login.email_example' | translate"
          [attr.aria-label]="'login.email' | translate"
          formControlName="email"
        />
      </div>
      <div *ngIf="error" class="alert login-error">
        <p>
          {{ 'login.recoverError' | translate }}
          <!-- No hemos encontrado el usuario en nuestra base de datos. -->
          <img
            src="/assets/img/icons/gl_close.svg"
            alt="cerrar"
            (click)="error = false"
          />
        </p>
      </div>
      <div *ngIf="success" class="alert success-message">
        <p>
          {{ 'login.recoverDone' | translate }}
          <img
            src="/assets/img/icons/gl_close_success.svg"
            alt="cerrar"
            (click)="success = false"
          />
        </p>
      </div>
      <button class="btn rounded-btn btn-primary w-100" type="submit">
        {{ 'login.recoverButtonTitle' | translate }}
        <img src="/assets/img/icons/arrow-right.svg" alt="Iniciar sesión" />
      </button>
      <p class="text-center bottom-text">
        <a [routerLink]="loginUrl">{{'login.return'| translate}}</a>
      </p>
    </form>
  </div>
  <div class="login-col text-center d-none d-lg-block">
    <h3 class="text-white">
     {{'login.team' | translate}}
    </h3>
    <img
      width="84.6%"
      class="proveedores-img"
      [src]="getImageUrl()"
      alt="taclia Proveedores"
    />
  </div>
</div>
