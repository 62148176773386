import { Injectable } from '@angular/core';
import { Plan, PlanType } from '@tacliatech/types';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private http: HttpClient) {}

  public parseLegacyPlan(plan: Plan): PlanType[] {
    const planTypes = plan.planTypes.filter(({ id }) =>
      id.includes('full-pro')
    );
    const yearPlanType = planTypes.find((fil) => fil.period_unit == 'year');
    const monthPlanType = planTypes.find((fil) => fil.period_unit == 'month');

    if (yearPlanType?.monthly && monthPlanType?.monthly) {
      const indexYear = planTypes.indexOf(yearPlanType);
      const discount = this.calculateDiscount(
        yearPlanType.monthly,
        monthPlanType.monthly
      );
      if (discount > 0) planTypes[indexYear].discountPercentage = discount;
    }

    return planTypes;
  }

  public async getItemPrices(): Promise<Plan> {
    return await this.http
      .get<Plan>(':API_URL/subscription-manager/item-prices-full-pro')
      .pipe()
      .toPromise();
  }

  private calculateDiscount(yearlyRate: number, monthlyRate: number): number {
    return Math.trunc(100 - (yearlyRate / monthlyRate) * 100);
  }
}
