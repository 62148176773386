import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  UnsavedChangesActions,
  UnsavedChangesComponent,
} from './component/unsaved-changes.component';

export interface ComponentUnsavedChanges {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class UnsaveGuard implements CanDeactivate<ComponentUnsavedChanges> {
  unsavedChanges$ = new BehaviorSubject<boolean>(false);

  eventName$ = new BehaviorSubject<string>('');

  nextState: RouterStateSnapshot;

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}

  canDeactivate(
    component: ComponentUnsavedChanges,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    this.nextState = nextState;
    if (this.unsavedChanges$.getValue()) {
      const dialogRef = this.dialog.open(UnsavedChangesComponent);
      dialogRef.afterClosed().subscribe((res: UnsavedChangesActions) => {
        if (res === UnsavedChangesActions.onSaveAndContinue) {
          window.dispatchEvent(
            new CustomEvent(this.eventName$.getValue(), {
              detail: { type: 'saveCurrentForm' },
            })
          );
          this.goNext();
        } else if (res === UnsavedChangesActions.onContinueWithoutSave) {
          this.goNext();
        }
      });
    }
    return this.unsavedChanges$.getValue() === false;
  }

  goNext(): void {
    this.unsavedChanges$.next(false);
    if (this.nextState) {
      this.router.navigate([this.nextState.url?.split('?')[0]], {
        queryParams: this.nextState.root.queryParams,
      });
    }
  }
}
