import { Component, ElementRef, OnDestroy, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'roma-base-microfrontend',
  template: `<ng-content></ng-content>`,
  styles: [],
})
export abstract class MicrofrontendComponent<
  T = Record<string, unknown>,
  U = Record<string, unknown>
> implements OnInit, OnDestroy {
  @Input() appName: string;
  @Input() appUrl: string;

  protected eventName: string;

  constructor(private httpClient: HttpClient, private el: ElementRef) {}

  ngOnInit(): void {
    if (this.appName && this.appUrl) {
      this.eventName = `${this.appName}-parent`;
      window.addEventListener(this.appName, this.handleCustomEvent);
      this.loadScript(this.appUrl);
      this.onInit();
    }
  }

  ngOnDestroy(): void {
    if (this.appName) {
      window.removeEventListener(this.appName, this.handleCustomEvent);
      const body = <HTMLDivElement>document.body;
      const script = document.querySelector('script#' + this.appName);
      if (script) {
        body.removeChild(script);
      }
      this.onDestroy();
    }
  }

  publish = (data: U): void => {
    window.dispatchEvent(new CustomEvent(this.eventName, { detail: data }));
  };

  abstract onInit(): void;

  abstract onDestroy(): void;

  private loadScript(url: string): void {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.type = 'module';
    script.id = this.appName;
    body.appendChild(script);
  }

  abstract handleCustomEvent(event: CustomEvent<T>): void;
}
