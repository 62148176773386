import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { MicrofrontendComponent } from '../microfrontend.component';
import { HttpClient } from '@angular/common/http';
import {
  AnalyticsService,
  Source,
} from '../../shared/services/analytics/analytics.service';
import { AbsenceEmployee } from '../../../types-legacy/lib/packages/clock-absence/absenceEmployee';
import { CreatePersonService } from '../../shared/components/create-persons';

type EventPayload = { id: string };

type SendEventPayload = { id: string };

export enum EventType {
  employeeCalendarIsReady = 'employeeCalendarIsReady',
  updateUsersAbsences = 'updateUsersAbsences',
  updateFilterDate = 'updateFilterDate',
  createUser = 'createUser',
  createAbsence = 'createAbsence',
  openUserDetail = 'openUserDetail',
  openAbsenceModal = 'openAbsenceModal',
  sendAnalyticsEvent = 'sendAnalyticsEvent',
}

type EventData = {
  type: EventType;
  payload?: EventPayload;
};

type SendEventData = {
  type: EventType;
  payload?: SendEventPayload;
};

@Component({
  selector: 'roma-mf-employee-calendar',
  template: `<mf-employee-calendar></mf-employee-calendar>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfEmployeeCalendarComponent extends MicrofrontendComponent<
  EventData,
  SendEventData
> {
  @Input() appName = 'mf-employee-calendar';
  @Input() appUrl: string = localStorage.getItem('mf-employee-calendar');

  private _absencesEmployees: AbsenceEmployee[];

  private _filterDate: {
    startDate: string;
    endDate: string;
  };

  @Input() set absencesEmployees(value: AbsenceEmployee[]) {
    this._absencesEmployees = value;
    this.loadAbsences();
  }

  @Input() set filterDate(value: { startDate: string; endDate: string }) {
    this._filterDate = value;
    this.loadFilterDate();
  }

  @Input() onEventClick;
  @Input() onCreateUser;
  @Input() onUserDetails;
  @Input() onCreateAbsence;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly createPersonService: CreatePersonService,
    private _httpClient: HttpClient,
    private _el: ElementRef
  ) {
    super(_httpClient, _el);
    this.handleCustomEvent = this.handleCustomEvent.bind(this);
  }
  onInit(): void {}

  employeeCalendarIsReady(): void {}

  handleCreateUser(): void {
    this.onCreateUser && this.onCreateUser();
  }

  handleCreateAbsence(evt: CustomEvent): void {
    this.onCreateAbsence && this.onCreateAbsence(evt.detail.payload);
  }

  handleOpenUserDetail(evt: CustomEvent): void {
    this.onUserDetails && this.onUserDetails(evt.detail.payload);
  }

  handleOpenAbsenceModal(evt: CustomEvent): void {
    this.onEventClick && this.onEventClick(evt.detail.payload);
  }

  async handleAnalyticsEvent({
    event,
    sources,
    properties = {},
  }: {
    event: string;
    sources: Source[];
    properties?: { [key: string]: string };
  }): Promise<void> {
    this.analyticsService.trackEvent({
      sources: sources,
      eventName: event,
      eventProperties: properties,
    });
  }

  handleCustomEvent(event: CustomEvent): void {
    const eventMappedToCalendarEvent = {
      [EventType.employeeCalendarIsReady]: () => this.employeeCalendarIsReady(),
      [EventType.createUser]: () => this.handleCreateUser(),
      [EventType.createAbsence]: () => this.handleCreateAbsence(event),
      [EventType.openUserDetail]: () => this.handleOpenUserDetail(event),
      [EventType.openAbsenceModal]: () => this.handleOpenAbsenceModal(event),
      [EventType.sendAnalyticsEvent]: () =>
        this.handleAnalyticsEvent(event.detail),
    };
    eventMappedToCalendarEvent[event.detail.type]();
  }

  loadAbsences(): void {
    const event = new CustomEvent(EventType.updateUsersAbsences, {
      detail: {
        type: EventType.updateUsersAbsences,
        payload: this._absencesEmployees,
      },
    });
    window.dispatchEvent(event);
  }

  loadFilterDate(): void {
    const event = new CustomEvent(EventType.updateFilterDate, {
      detail: {
        type: EventType.updateFilterDate,
        payload: this._filterDate,
      },
    });
    window.dispatchEvent(event);
  }

  onDestroy(): void {}
}
