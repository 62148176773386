import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  APP_VERSION,
  Company,
  COMPANY_DATA,
  COMPANY_ID_KEY,
  CreateNewOrderArrayObject,
  CUSTOMER_DATA,
  CUSTOMER_ID_KEY,
  DATE_FORMAT,
  DATETIME_LOGOUT,
  IMPERSONATED,
  IStatus,
  ITokenPayload,
  IUser,
  NotificationGlobalEvent,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TypeRol,
  USER_DATA,
  USER_EXTRAS,
  USER_ID_KEY,
  USER_KEY,
  USER_MODE_KEY,
  VENDOR_ID_KEY,
  BDG_SETTINGS,
  BDG_PREFERENCES,
  COMPANY_PRICES,
  FIRST_DAY_CALENDAR,
  SECTOR_MODULE,
  USER_INFO,
} from '@tacliatech/types';

import { LoginError, LoginRequest } from '@web-frontend/shared/interfaces/auth';
import { StorageService } from '../storage/storage.service';
import { FcmService, UserService } from '..';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { ModalLogoutComponent } from '@web-frontend/shared/components/modal-logout';
import { BudgetService } from '../budgets/budgets.service';
import { SocketService } from '../socket';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from '../permissions';
import { PaymentsService } from '../../../core/admin/payments/services/payments.service';
import { CompanyService } from '../company';
import { AnalyticsService } from '../analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import * as Sentry from '@sentry/angular';
import { authRoutes } from '../../../core/auth/auth-routing.module';

const MONTH_TIMEOUT = 2628000000;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginSuccess = new Subject();
  private loginError = new Subject<LoginError>();
  private loginLoading = new Subject<boolean>();

  // @ts-ignore
  private userCache = new BehaviorSubject<IUser>(null);

  get user() {
    return this.userCache.getValue();
  }

  loginSuccess$ = this.loginSuccess.asObservable();
  loginError$ = this.loginError.asObservable();
  loginLoading$ = this.loginLoading.asObservable();

  user$ = this.userCache.asObservable().pipe(filter((res) => Boolean(res)));
  user1$ = this.userCache.asObservable();

  isVendor$ = this.user$.pipe(map((res) => res?.mode === 'vendor'));
  isCustomer$ = this.user$.pipe(map((res) => res?.mode === 'customer'));

  isAdmin$ = this.user$.pipe(
    // @ts-ignore
    map((res) => res?.role.includes(TypeRol.ADMIN_ROLE))
  );

  isUser$ = this.user$.pipe(
    // @ts-ignore
    map((res) => res?.role.includes(TypeRol.USER_ROLE))
  );

  userId$ = this.user$.pipe(map((res) => res?._id));

  isAdminOrUserRole$ = this.user$.pipe(
    map(
      (res) =>
        // @ts-ignore
        res?.role.includes(TypeRol.ADMIN_ROLE) ||
        // @ts-ignore
        res?.role.includes(TypeRol.USER_ROLE)
    )
  );

  private sub$ = new Subscription();
  tokenSubscription = new Subscription();
  private flightUserRequestObs$!: Observable<IUser>;

  public registerLogin$ = new BehaviorSubject<LoginRequest>({
    username: '',
    password: '',
  });

  updateBannerDemo(value) {
    this.userCache.value.bannerDemo = value;
  }

  constructor(
    private http: HttpClient,
    private userService: UserService,
    public auth: AngularFireAuth,
    private router: Router,
    private dialog: MatDialog,
    private budgetService: BudgetService,
    private socketService: SocketService,
    private permissionService: PermissionService,
    private paymentsService: PaymentsService,
    private companyService: CompanyService,
    private injector: Injector,
    private fcmService: FcmService,
    private analyticsService: AnalyticsService
  ) {}

  set updateUserCache(data: IUser) {
    this.userCache.next(data);
  }

  init() {
    this.handleUserRequest().subscribe((res) => {
      this.watchSocketModulesChange();
    });
  }

  handleUserRequest(): Observable<IUser> {
    const isUserCached = Boolean(this.userCache.getValue());

    let obsFlight$: Observable<IUser> = isUserCached
      ? new Observable((obs) => {
          obs.next(this.userCache.getValue());
          obs.complete();
        })
      : this.requestUser();

    obsFlight$ = obsFlight$.pipe(
      finalize(() => {
        // @ts-ignore
        this.flightUserRequestObs$ = null;
      })
    );

    if (this.flightUserRequestObs$) {
      return this.flightUserRequestObs$;
    }

    this.flightUserRequestObs$ = obsFlight$;

    return obsFlight$;
  }

  refreshCacheUserRequest() {
    this.updateUserCache = null;
    return this.handleUserRequest();
  }

  private requestUser() {
    return this.http.get<IUser>(`:API_URL/users/${StorageService.UserId}`).pipe(
      tap((res) => {
        this.updateUserCache = res;
        const userData = {
          id: res.id,
          name: res.name,
          email: res.email,
          phone: res.phone,
          company: res.company._id,
          role: res.role?.[0],
          created_at: res.created_at,
          country: res.country,
        };
        localStorage.setItem('userData', JSON.stringify(userData));
      })
    );
  }

  private showModalLogout() {
    const modalRef = this.dialog.open(ModalLogoutComponent);
    (modalRef.componentInstance as ModalLogoutComponent).type = 'logout';
  }

  private waitingExpiration(timeout) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null)
      .pipe(delay(timeout))
      .subscribe(() => {
        this.showModalLogout();
      });
  }

  public async impersonate(accessToken: string): Promise<void> {
    StorageService.SetItem(TOKEN_KEY, accessToken);
    const decoded = jwtDecode<{ payload: { sub: string } }>(accessToken);
    const user: IUser = await this.http
      .get<IUser>(`:API_URL/users/${decoded.payload.sub}`)
      .toPromise();
    const storage = {
      id: user._id,
      username: user.name,
      customer_id: user._id,
      vendor_id: null,
      company_id: user.company._id,
      mode: null,
      ok: true,
      msj: null,
      access_token: accessToken,
      refresh_token: null,
      extras: user.extras,
    };
    this.prepareStorage(storage);
    this.updateUserCache = { ...user };
    this.permissionService.init();
    this.budgetService.init();
    this.requestUser();
    StorageService.SetItem(IMPERSONATED, true);
  }

  login(auth: LoginRequest): Observable<IUser> {
    StorageService.SetItem(IMPERSONATED, false);
    this.loginLoading.next(true);

    return this.http.post<ITokenPayload>(`:API_URL/auth/login`, auth).pipe(
      tap(
        async (res) => {
          this.prepareStorage(res);
          this.companyService.getCompanyInfo().subscribe((res) => {
            const user: IUser = { ...this.user, company: res };
            this.updateUserCache = user;
          });
          this.permissionService.init();
          this.budgetService.init();
          this.loginSuccess.next();
        },
        (res) => {
          this.loginError.next(res?.error?.code as LoginError);
        }
      ),
      switchMap(() => this.requestUser()),
      finalize(() => {
        this.loginLoading.next(false);
      })
    );
  }

  public async loginSuccessfullyFlow(): Promise<void> {
    this.init();
    await this.permissionService.init();
    this.budgetService.init();
    this.companyService.getCompanyInfo().subscribe((res) => {
      const user: IUser = { ...this.user, company: res };
      this.updateUserCache = user;
    });
    this.loginSuccess.next();
  }

  logout() {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.profile_endSession_end,
    });

    StorageService.Remove(TOKEN_KEY);
    StorageService.Remove(USER_ID_KEY);
    StorageService.Remove(USER_KEY);
    StorageService.Remove(REFRESH_TOKEN_KEY);
    StorageService.Remove(COMPANY_ID_KEY);
    StorageService.Remove(CUSTOMER_ID_KEY);
    StorageService.Remove(VENDOR_ID_KEY);
    StorageService.Remove(USER_MODE_KEY);
    StorageService.Remove(USER_EXTRAS);
    StorageService.Remove(APP_VERSION);
    StorageService.Remove(COMPANY_DATA);
    StorageService.Remove(CUSTOMER_DATA);
    StorageService.Remove(USER_DATA);
    StorageService.Remove(DATE_FORMAT);
    StorageService.Remove(BDG_PREFERENCES);
    StorageService.Remove(BDG_SETTINGS);
    StorageService.Remove(COMPANY_ID_KEY);
    StorageService.Remove(DATETIME_LOGOUT);
    StorageService.Remove(IMPERSONATED);
    StorageService.Remove(COMPANY_PRICES);
    StorageService.Remove(FIRST_DAY_CALENDAR);
    StorageService.Remove(SECTOR_MODULE);
    StorageService.Remove(USER_INFO);

    this.injector.get<AnalyticsService>(AnalyticsService).logoutOrReset();
    this.resolveRoom(NotificationGlobalEvent.ClientLeave);
    this.tokenSubscription.unsubscribe();
    this.socketService.off();
    this.fcmService.close();
    this.dialog.closeAll();
    this.userCache.next(null);
  }

  checkUserMode(user: IUser) {
    if (StorageService.userMode && user.mode === null) {
      user.mode =
        StorageService.userMode === 'customer' ? 'customer' : 'vendor';
      // @ts-ignore
      this.updateMode(user._id, user.mode);
    }
  }

  getUser() {
    this.user$.subscribe((data) => {
      if (data) {
        this.checkUserMode(data);
      }
    });
  }

  updateMode(id: string, m: string) {
    // @ts-ignore
    this.sub$.add(this.userService.updateOne(id, { mode: m }).subscribe());
  }

  refresh() {
    return this.http
      .post<ITokenPayload>(`:API_URL/login/refresh`, {
        refreshToken: StorageService.GetItem(REFRESH_TOKEN_KEY),
      })
      .pipe(
        tap((res) => {
          this.prepareStorage(res);
        })
      );
  }
  getToken(data: any) {
    return this.http.post<any>(`:API_URL/auth/get-token`, data);
  }

  private prepareStorage(res: ITokenPayload) {
    this.waitingExpiration(MONTH_TIMEOUT);
    StorageService.SetItem(TOKEN_KEY, res.access_token);
    StorageService.SetItem(USER_ID_KEY, res.id);
    StorageService.SetItem(
      DATETIME_LOGOUT,
      res.id + '@' + new Date().valueOf()
    );
    StorageService.SetItem(USER_MODE_KEY, res.mode);
    StorageService.SetItem(USER_KEY, res.username);
    StorageService.SetItem(REFRESH_TOKEN_KEY, res.refresh_token);
    StorageService.SetItem(COMPANY_ID_KEY, res.company_id);
    StorageService.SetItem(CUSTOMER_ID_KEY, res.customer_id);
    StorageService.SetItem(VENDOR_ID_KEY, res.vendor_id);
    StorageService.SetItem(USER_MODE_KEY, res.mode);
    StorageService.SetItem(USER_EXTRAS, JSON.stringify(res?.extras));
  }

  applyAutoLogout() {
    let dateTimeLocalStorage;
    let userStorageID;
    const auxValue = StorageService.GetItem(DATETIME_LOGOUT);
    if (auxValue) {
      const dateNow = new Date();
      userStorageID = +auxValue.split('@')[0];
      dateTimeLocalStorage = +auxValue.split('@')[1];

      const result = dateNow.valueOf() - dateTimeLocalStorage.valueOf();

      if (result >= MONTH_TIMEOUT) {
        this.showModalLogout();
      } else {
        return true;
      }
    } else {
      this.logout();
      this.router.navigate([authRoutes.login]);
    }

    return false;
  }

  private autoLogout() {
    let dateTimeLocalStorage;
    const auxValue = StorageService.GetItem(DATETIME_LOGOUT);
    if (auxValue) {
      const dateNow = new Date();
      dateTimeLocalStorage = +auxValue.split('@')[1];
      const result = dateNow.valueOf() - dateTimeLocalStorage.valueOf();
      this.waitingExpiration(result);
    }
    return false;
  }

  private watchSocketModulesChange() {
    this.socketService.socketConnected$
      .pipe(filter((res) => Boolean(res)))
      .subscribe(() => {
        this.resolveRoom(NotificationGlobalEvent.ClientEnter);
        this.watchNotifications();
      });
  }

  resolveRoom(
    evt:
      | NotificationGlobalEvent.ClientEnter
      | NotificationGlobalEvent.ClientLeave
  ) {
    this.socketService.emit(evt, {
      room: StorageService.GetItem(COMPANY_ID_KEY),
    });
  }

  private async watchNotifications() {
    this.sub$.add(
      this.socketService
        .listen(NotificationGlobalEvent.ModulesChange)
        // @ts-ignore
        .subscribe((res: Company.Output) => {
          const user: IUser = { ...this.user, company: res };
          this.updateUserCache = user;
        })
    );
    this.sub$.add(
      this.socketService
        .listen(NotificationGlobalEvent.RefreshPermissions)
        .subscribe(() => {
          this.permissionService
            .findAll()
            .pipe(
              catchError((error) => {
                Sentry.captureException(error);
                return of(null);
              })
            )
            .subscribe();
          this.companyService
            .getCompanyInfo()
            .pipe(
              catchError((error) => {
                Sentry.captureException(error);
                return of(null);
              })
            )
            .subscribe(() => {
              this.injector
                .get<AnalyticsService>(AnalyticsService)
                .identifyUser(['amplitude']);
            });

          const checkTrial = async () => {
            const res = await this.permissionService.checkTrialSubscription(
              StorageService.CompanyId,
              'taclia',
              true
            );

            this.permissionService.setTrialStatus(res);
          };
          checkTrial();
        })
    );

    // Listen for stripe status account
    this.sub$.add(
      this.socketService
        .listen(NotificationGlobalEvent.RefreshStripeStatusAccount)
        .subscribe(() => {
          this.paymentsService.init();
        })
    );
  }

  updateCompanyInfo(newStatusOrder: IStatus[]) {
    return this.companyService
      .findOneAndUpdate(StorageService.CompanyId, {
        orderStatus: CreateNewOrderArrayObject(newStatusOrder),
        customerId: StorageService.CustomerId,
      })
      .pipe(
        tap((res) => {
          const user: IUser = { ...this.user, company: res };
          this.updateUserCache = user;
        })
      );
  }
}
