import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  COMPANY_DATA,
  COMPANY_ID_KEY,
  COMPANY_PRICES,
  Company,
  ICompany,
  IInternalVendor,
  IUser,
  Prices,
} from '@tacliatech/types';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private sub$ = new Subscription();

  private companiesCache = new BehaviorSubject<ICompany[]>([]);
  refreshCompanyCounter$ = new BehaviorSubject<Company.CountUsers>({
    companyUsers: 0,
  });
  companys$ = this.companiesCache.asObservable();

  constructor(private http: HttpClient) {}

  init(): void {
    this.sub$.add(
      this.findAll().subscribe((res) => {
        this.companiesCache.next(res);
      })
    );
  }

  public get companys(): ICompany[] {
    return this.companiesCache.value;
  }

  findAll(): Observable<ICompany[]> {
    return this.http.get<ICompany[]>(`:API_URL/company/`);
  }

  findOne(id: string): Observable<ICompany> {
    return this.http.get<ICompany>(`:API_URL/company/${id}`).pipe(
      tap((res) => {
        const personalization = res?.personalization || {};
        const modules = res?.modules?.map((module) => module.id) || [];
        const entitlements = res?.subscription?.entitlements || [];
        const plans = res?.subscription?.item_price_ids || [];
        const companyData = {
          name: res.name,
          customer: res.customer._id,
          created_at: res?.created_at,
          modules,
          entitlements,
          plans,
          locationCountry: res?.location?.country || 'ES',
          sector: res?.customer?.sector,
          stChoice: res.stChoice,
          company_utm_campaign: res.utm_data?.[0]?.utm_campaign ?? null,
          prices: res.subscription?.prices ?? null,
          personalization,
        };

        const existingData = JSON.parse(StorageService.companyData ?? '{}');
        StorageService.SetItem(COMPANY_DATA, {
          ...existingData,
          ...companyData,
        });

        StorageService.SetItem(
          COMPANY_PRICES,
          companyData?.prices?.map((price) => ({
            periodUnit: price.period_unit,
            price: price.price,
            itemPriceId: price.item_price_id,
          }))
        );
      })
    );
  }

  findOneAndUpdate(
    id: string,
    value: { [key: string]: any }
  ): Observable<Company.Output> {
    return this.http.put<Company.Output>(`:API_URL/company/${id}`, {
      ...value,
    });
  }

  findUsersByCompany(id: string): Observable<IUser[]> {
    return this.http.get<IUser[]>(`:API_URL/users/company/${id}`);
  }

  findCountUsersByCompany(id: string): Observable<Company.CountUsers> {
    return this.http.get<Company.CountUsers>(`:API_URL/company/${id}/users`);
  }

  findInternalVendors(idCompany: string): Observable<IInternalVendor[]> {
    return this.http.get<IInternalVendor[]>(
      `:API_URL/company/${idCompany}/internal-vendors`
    );
  }

  delete(id: string) {
    return this.http.delete(`:API_URL/company/${id}`);
  }

  getCompanyInfo(): Observable<ICompany> {
    return this.findOne(StorageService.GetItem(COMPANY_ID_KEY));
  }
}
