<roma-modal-template
  size="lg"
  [showHeader]="false"
  [disableSuccess]="false"
  [showDefaultButtons]="false"
  [showCancelBtn]="false"
  [showSuccessBtn]="false"
  [showCloseBtn]="false"
>
  <div id="plans">
    <div class="modal-header">
      <div class="selection">
        <div class="text-center close-btn-wrapper">
          <button (click)="onClose()">
            X <span>{{ 'forms.close' | translate }}</span>
          </button>
        </div>
        <h1>{{ 'pro.fullModal.choosePlan' | translate }}</h1>
        <div class="filter-wrapper">
          <button
            class="btn btn-align"
            [ngClass]="{ active: periodType === 'monthly' }"
            romaProFeature
            (click)="changePeriodType('monthly')"
          >
            <span class="align-text">{{
              'pro.fullModal.monthly' | translate
            }}</span>
          </button>
          <button
            class="btn btn-align"
            [ngClass]="{ active: periodType === 'yearly' }"
            (click)="changePeriodType('yearly')"
          >
            <span class="align-text">{{
              'pro.fullModal.annual' | translate
            }}</span>
            <span class="tag-discount">≈15%</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-content">
      <div class="grid-container" *ngIf="!isLoading">
        <roma-plan-selection
          *ngFor="let module of sortedModules"
          [moduleName]="modules[module].name"
          [moduleIcon]="modules[module].icon"
          [moduleType]="modules[module].type"
          [description]="modules[module].description"
          [startingPrice]="modules[module].startingPrice"
          [currency]="modules[module].currency"
          [isPeriodYearly]="periodType === 'yearly'"
          [isFullPlan]="modules[module].isFullPlan"
          [plans]="modules[module].plans"
          [features]="modules[module].features"
          (selectPlan)="subscribe($event)"
        ></roma-plan-selection>
      </div>
    </div>
  </div>
</roma-modal-template>
