import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SandboxService } from '@web-frontend/shared/services';
import { RmFilterModalDashboardService } from './rm-filter-modal-dashboard';
import { RmFilter } from './rm-filter.types';
import { RmFilterService } from './rm-filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roma-rm-filter-button',
  templateUrl: './rm-filter-button.component.html',
  styleUrls: ['./rm-filter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmFilterButtonComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger)
  trigger: MatMenuTrigger;

  @Input()
  source: RmFilter.Filter;

  @Input()
  filterHeight = '';

  @Input()
  filterWidth = '304px';

  @Input()
  title?: string;

  @Input()
  matIcon?: string;
  private customIcon: string;

  @Output()
  changes = new EventEmitter<RmFilter.Changes>();

  @Output()
  openFilter? = new EventEmitter();
  resize$ = this.sandBoxService.screenBusChannel$;

  private sub$ = new Subscription();
  activeFilters = 0;

  constructor(
    private sandBoxService: SandboxService,
    private rmFilterModalDashboard: RmFilterModalDashboardService,
    private rmFilterService: RmFilterService
  ) {}

  ngOnInit(): void {
    this.sub$.add(
      this.rmFilterService.cleanActiveFilters$.subscribe((data) => {
        if (data) {
          this.activeFilters = 0;
        }
      })
    );
    this.customIcon = this.matIcon;
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  sendOpenFilter() {
    this.openFilter.emit();
  }
  emitChanges(evt: RmFilter.Changes) {
    if (evt.queryParams) {
      this.activeFilters = Object.keys(evt.queryParams).length;

      this.subtractIfHaveFromAndTo(evt.queryParams);
    }

    this.changes.emit(evt);
  }

  open() {
    this.openFilter.emit(true);
    this.rmFilterModalDashboard
      .open({
        data: {
          source: this.source,
        },
      })
      .subscribe((res) => {
        if (res) {
          const selectedFilters = res.source.toQueryParams();
          this.activeFilters = Object.keys(selectedFilters).length;

          this.subtractIfHaveFromAndTo(selectedFilters);

          this.changes.next({
            type: 'CHANGE',
            queryParams: selectedFilters,
          });
        }
      });
  }

  subtractIfHaveFromAndTo(filters: any) {
    if (
      Object.keys(filters).includes('from') &&
      Object.keys(filters).includes('to')
    ) {
      this.activeFilters--;
    }
  }
}
