import { Injectable } from '@angular/core';
import { LogoCropService } from '../../components/budget-logo/logo-crop.service';
import { AnalyticsService } from '../analytics/analytics.service';
import AmplitudeEvents from '../../../../types/amplitude.enum';
import { ToastService } from '../toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { FileSelectService } from '../../helpers/file-select';

@Injectable({
  providedIn: 'root',
})
export class BudgetLogoService {
  constructor(
    private logoCropService: LogoCropService,
    private analyticsService: AnalyticsService,
    private toastService: ToastService,
    private i18n: TranslateService,
    private fileSelectService: FileSelectService
  ) {}

  async openWithoutImage(
    id: string,
    file?: File,
    changeEvent?: Event,
    src?: string
  ): Promise<boolean> {
    if (!file) {
      await this.fileSelectService.openSelector().then((res) => {
        file = (res as FormData).get('file[]') as File;
        src = file.name;
        changeEvent = new Event('change', { bubbles: true });
        Object.defineProperty(changeEvent, 'target', {
          value: { files: [file] },
        });
      });
    }

    try {
      await this.logoCropService.allowedDimensions(file);
    } catch (error) {
      if (typeof error === 'string') {
        this.toastService.show({
          text: error,
          type: 'error',
          msDuration: 4000,
          icon: 'assets/icons/kanban/delete.svg',
        });
      }

      return false;
    }

    const config = {
      data: { logo: src, id: id, changeImageEvent: changeEvent },
    };
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.settings_editLogo,
    });
    return new Promise<boolean>((resolve) => {
      this.logoCropService
        .open(config)
        .subscribe({ complete: () => resolve(true) });
    });
  }

  async openWithImage({
    src,
    id,
    rawLogo,
    settings,
  }: {
    src: string;
    id: string;
    rawLogo: string;
    settings: any;
  }): Promise<boolean> {
    const config = {
      data: {
        logo: src,
        id: id,
        rawLogo: rawLogo,
        settings: settings,
      },
    };
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.settings_editLogo,
    });
    return new Promise<boolean>((resolve) => {
      this.logoCropService
        .open(config)
        .subscribe({ complete: () => resolve(true) });
    });
  }
}
