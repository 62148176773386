import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalVideoComponent } from './modal-video.component';

export enum VideoTypes {
  serialNumbers = 'serial-numbers',
}

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private refreshListSource = new Subject<unknown>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(
    type: VideoTypes | string,
    config: MatDialogConfig = new MatDialogConfig()
  ) {
    config.data = {
      type: type,
      ...config.data,
    };

    const dialogRef = this.dialog.open(ModalVideoComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
        this.emitRefresh(res);
      })
    );
  }

  emitRefresh(data: unknown) {
    this.refreshListSource.next(data);
  }
}
