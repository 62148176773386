import { AnalyticsService } from './../../services/analytics/analytics.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { USER_ID_KEY } from '@web-frontend/shared/constants';
import { AuthService } from '@web-frontend/shared/services';
import { StorageService } from '@web-frontend/shared/services/storage/storage.service';
import { authRoutes } from '../../../core/auth/auth-routing.module';
import AmplitudeEvents from '../../../../types/amplitude.enum';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private analyticsService: AnalyticsService
  ) {}

  public canActivate() {
    const keepOnboarding = localStorage.getItem('register');
    if (keepOnboarding) {
      this.router.navigateByUrl(authRoutes.clientOnboarding);
      return false;
    }
    const key = StorageService.GetItem(USER_ID_KEY);
    if (!key) {
      this.authService.logout();
      this.router.navigateByUrl(authRoutes.login);
      return false;
    }
    const user = this.authService.user;

    if (user && user.deleted) {
      this.authService.logout();
      this.router.navigateByUrl(authRoutes.login);
      return false;
    }

    this.analyticsService.trackEvent({
      sources: ['amplitude', 'braze'],
      eventName: AmplitudeEvents.page_event,
      eventProperties: { page: this.router.url },
    });
    return true;
  }
}
